import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { userLogin } from './api';
import { AUTH, IUserData, IUserInfo, UserOrganisationType } from './type';
import { cloneDeep } from 'lodash';

const initialState: IUserData = {
  loading: false,
  userInfo: null,
  error: null,
  success: false,
  token: '',
};

const authSlice = createSlice({
  name: AUTH,
  initialState,
  reducers: {
    updateAccessToken(state, accessToken: PayloadAction<string>) {
      const stateClone = cloneDeep(state);
      stateClone.token = accessToken.payload;
      return stateClone;
    },
    updateUserDetail(state, userInfo: PayloadAction<IUserInfo>) {
      const stateClone = cloneDeep(state);
      stateClone.userInfo = {
        ...userInfo.payload,
      };
      return stateClone;
    },
    updateOrgList(state, organisation: PayloadAction<UserOrganisationType[]>) {
      const stateClone = cloneDeep(state);
      if (stateClone.userInfo?.organisation) {
        stateClone.userInfo.organisation = organisation.payload;
      }
      return stateClone;
    },
    clearUser(state) {
      state = initialState;
      return state;
    },
  },
  extraReducers: {
    [userLogin.pending as any]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled as any]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      state.userInfo = payload;
    },
    [userLogin.rejected as any]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { clearUser, updateAccessToken, updateUserDetail, updateOrgList } = authSlice.actions;
export default authSlice.reducer;
