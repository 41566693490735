import React, { FC } from 'react';
import { OptionType } from '../type';
import { Autocomplete, Box, TextField } from '@mui/material';
import { ErrorText, FieldLabel } from '../../component/Text';
import { Control, Controller, FieldErrors, FieldValues } from 'react-hook-form';
import { TEXT_COLOR_THEME } from '../../../theme/color';

type CustomeAutocompleteProps = {
  id: string;
  name: string;
  options: OptionType[];
  label: string;
  ismultiselect?: boolean;
  errorMessage?: string;
  errors?: FieldErrors<FieldValues>;
  control: Control<any, any>; //TODO: fix type
};
const CustomAutocomplete: FC<CustomeAutocompleteProps> = ({
  id,
  label,
  name,
  options,
  ismultiselect,
  errors,
  errorMessage,
  control,
}) => {
  const getValue = (value: string | string[]) => {
    if (ismultiselect) {
      return options.filter((item) => value.includes(item.value));
    }
    return options.find((item) => item.value === value);
  };
  return (
    <Box>
      <FieldLabel>{label}</FieldLabel>
      <Controller
        control={control}
        name={`${name}`}
        render={({ field }) => (
          <Autocomplete
            id={id}
            fullWidth
            multiple={ismultiselect}
            size={'small'}
            value={getValue(field.value)}
            onChange={(_: any, newValue: any) => {
              if (!ismultiselect) {
                field.onChange(newValue?.value);
                return;
              }
              field.onChange(newValue.map((item: OptionType) => item.value));
            }}
            options={options}
            renderInput={(params) => <TextField {...params} onBlur={() => field.onBlur()} />}
          />
        )}
      />
      {errorMessage ? (
        <ErrorText style={{ color: TEXT_COLOR_THEME.error }}>{errorMessage}</ErrorText>
      ) : errors ? (
        [name] && (
          <ErrorText style={{ color: TEXT_COLOR_THEME.error }}>
            {(errors[name]?.message as string) || ''}
          </ErrorText>
        )
      ) : null}
    </Box>
  );
};

export default CustomAutocomplete;
