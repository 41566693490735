import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { loginApiUrl } from '../../constant/ApiConstant';
import { LoginHelper } from '../../util/LoginHelper';
import { ILoginUserData, IUserInfo } from './type';

const transformLoginData = (data: any): IUserInfo => {
  return {
    userId: data.userid,
    username: data.username,
    auth0Id: '',
    email: '',
    groups: [],
    isActive: false,
    isStaff: data.is_staff,
    teamId: null,
    uui: '',
    isSuperuser: false,
    organisation: [],
  };
};

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const userLogin = createAsyncThunk(
  'auth/login',
  async (loginData: ILoginUserData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        loginApiUrl,
        {
          email: loginData.email,
          password: loginData.password,
        },
        config,
      );
      const transformData = transformLoginData(data);
      LoginHelper.setUserSession(transformData);
      return transformData;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);
