/* eslint-disable react-hooks/exhaustive-deps */
import { usePlayerTimeline } from '../hook';
import { Box, Button, Chip, Grid } from '@mui/material';
import { Header1 } from '../../../common/component/Text';
import { PrimaryButton } from '../../../common/component/Button';
import AddTimelineEntryModal from './AddTimelineEntryModal';
import VerticalStepper from '../../../common/component/VerticalStepper';
import TimelineDescriptionContent from './TimelineDescriptionContent';
import { useMemo } from 'react';
import LoadingWrapper from '../../../common/component/LoadingWrapper';
import DeleteModal from '../../../common/component/DeleteModal';
import TimelineFilterDrawer from './TimelineFilterDrawer';
import { format } from 'date-fns';
import { filterTimeLineData } from '../util';
import { BUTTON_COLOR_THEME, TEXT_COLOR_THEME } from '../../../theme/color';

type PlayerTimelineProps = {
  playerId: string;
};
const PlayerTimeline = ({ playerId }: PlayerTimelineProps) => {
  const {
    data,
    isLoading,
    handleDelete,
    deleteLoading,
    openModalForEntry,
    setOpenModalForEntry,
    refreshList,
    selectedTimelineForDelete,
    selectedTimelineForEdit,
    setSelectedTimelineForDelete,
    setSelectedTimelineForEdit,
    selectedFilter,
    setSelectedFilter,
    setTimeLineExpand,
    timeLineExpand,
  } = usePlayerTimeline(playerId);

  const filterData = useMemo(
    () => filterTimeLineData(data, selectedFilter),
    [data, selectedFilter],
  );
  const getPlayerTimelineSteps = useMemo(() => {
    return filterData.map((item, index) => ({
      id: `${index + 1}`,
      description: (
        <TimelineDescriptionContent
          handleDelete={() => setSelectedTimelineForDelete(item.id.toString())}
          handleEdit={() => setSelectedTimelineForEdit(item)}
          title={item.name}
          info={item}
          key={timeLineExpand.toString()}
          timeLineExpand={timeLineExpand}
        />
      ),
    }));
  }, [data, selectedFilter, timeLineExpand]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={8}>
            <Box display={'flex'} flexWrap={'wrap'} gap={'10px'} mt={'5px'} mb={'20px'}>
              {selectedFilter.formType && (
                <Chip
                  onDelete={() =>
                    setSelectedFilter((prev) => ({
                      ...prev,
                      formType: '',
                    }))
                  }
                  clickable
                  label={`Form Type: ${selectedFilter.formType} `}
                />
              )}
              {selectedFilter.createdAt.startDate && selectedFilter.createdAt.endDate && (
                <Chip
                  clickable
                  onDelete={() =>
                    setSelectedFilter((prev) => ({
                      ...prev,
                      createdAt: {
                        endDate: null,
                        startDate: null,
                      },
                    }))
                  }
                  label={`Created At: ${format(
                    new Date(selectedFilter.createdAt.startDate),
                    'do MMM yyyy',
                  )}-${format(new Date(selectedFilter.createdAt.endDate), 'do MMM yyyy')}`}
                />
              )}
              {selectedFilter.updatedAt.startDate && selectedFilter.updatedAt.endDate && (
                <Chip
                  clickable
                  onDelete={() =>
                    setSelectedFilter((prev) => ({
                      ...prev,
                      updatedAt: {
                        endDate: null,
                        startDate: null,
                      },
                    }))
                  }
                  label={`Updated At: ${format(
                    new Date(selectedFilter.updatedAt.startDate),
                    'do MMM yyyy',
                  )}-${format(new Date(selectedFilter.updatedAt.endDate), 'do MMM yyyy')}`}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} gap={'10px'}>
              <Box>
                <PrimaryButton
                  onClick={() => setOpenModalForEntry(true)}
                  title='Add an Entry'
                  style={{ width: 160, borderRadius: 5 }}
                />
              </Box>

              <Box>
                <Button
                  onClick={() =>
                    setSelectedFilter((prev) => ({
                      ...prev,
                      open: !prev.open,
                    }))
                  }
                  title='Filter'
                  variant='outlined'
                  style={{
                    width: 160,
                    minHeight: 45,
                    borderRadius: 5,
                    height: '100%',
                    borderColor: BUTTON_COLOR_THEME.primary,
                    color: TEXT_COLOR_THEME.primary,
                  }}
                >
                  Filter
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={() => setTimeLineExpand((prev) => !prev)}
                  title={timeLineExpand ? 'Collapse All' : 'Expand All'}
                  variant='text'
                  style={{
                    width: 160,
                    minHeight: 45,
                    borderRadius: 5,
                    height: '100%',
                    borderColor: BUTTON_COLOR_THEME.primary,
                    color: TEXT_COLOR_THEME.primary,
                  }}
                >
                  {timeLineExpand ? 'Collapse All' : 'Expand All'}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <LoadingWrapper isLoading={isLoading}>
        <Grid item xs={12}>
          {filterData.length > 0 ? (
            <VerticalStepper steps={getPlayerTimelineSteps} />
          ) : (
            <Header1 textAlign={'center'}>NOTHING TO SHOW</Header1>
          )}
        </Grid>
      </LoadingWrapper>

      {(openModalForEntry || selectedTimelineForEdit) && (
        <AddTimelineEntryModal
          refreshList={refreshList}
          handleClose={() => {
            setOpenModalForEntry(false);
            setSelectedTimelineForEdit(undefined);
          }}
          playerId={playerId}
          selectedTimelineForEdit={selectedTimelineForEdit}
        />
      )}
      {selectedFilter.open && (
        <TimelineFilterDrawer
          handleClose={() =>
            setSelectedFilter((prev) => ({
              ...prev,
              open: false,
            }))
          }
          setSelectedFilter={(filter) =>
            setSelectedFilter((prev) => ({
              ...filter,
              open: false,
            }))
          }
          selectedFilter={selectedFilter}
        />
      )}
      {selectedTimelineForDelete && (
        <DeleteModal
          modalTitle='Delete Timeline'
          description='Are you sure you want to delete?'
          handleClose={() => setSelectedTimelineForDelete(null)}
          handleSubmit={handleDelete}
          isLoading={deleteLoading}
        />
      )}
    </Grid>
  );
};

export default PlayerTimeline;
