import { Grid } from '@mui/material';
import DataGridWrapper from '../../../common/component/DataGridWrapper';
import { usePlayerAward } from '../hook';
import { awardListColumn } from './ListColumn';

type PlayerAwardsProps = {
  playerId: string;
};

const PlayerAwards = ({ playerId }: PlayerAwardsProps) => {
  const { data, isLoading } = usePlayerAward(playerId);
  return (
    <Grid container>
      <Grid item xs={12}>
        <DataGridWrapper columns={awardListColumn()} rows={data} loading={isLoading} />
      </Grid>
    </Grid>
  );
};

export default PlayerAwards;
