import React, { FC } from 'react';
import CustomModal from '../../../../common/component/CustomModal';
import { useUploadCsvModal } from './hook';
import { Grid } from '@mui/material';
import CustomSelect from '../../../../common/fields/reacthookfield/CustomSelect';
import { UploadCsvTransactionType } from '../../../../store/header/type';

type UploadCsvModalProps = {
  uploadCsvTransactionType: UploadCsvTransactionType;
};
const UploadCsvModal: FC<UploadCsvModalProps> = ({ uploadCsvTransactionType }) => {
  const {
    handleSubmit,
    onSubmit,
    submitLoading,
    handleClose,
    selectedCsvOption,
    fileListOption,
    register,
    errors,
  } = useUploadCsvModal(uploadCsvTransactionType);

  return (
    <CustomModal
      saveLoading={submitLoading}
      handleSubmit={handleSubmit(onSubmit)}
      modalSize={'sm'}
      handleClose={handleClose}
      modalTitle='Save CSV to Transaction'
    >
      <Grid container>
        <Grid item xs={12}>
          <CustomSelect
            id={'csvFile'}
            name={'csv'}
            register={register}
            options={fileListOption}
            size='small'
            key={fileListOption.length}
            value={selectedCsvOption}
            errors={errors}
            fullWidth
            variant='outlined'
            label={'Csv FIle'}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default UploadCsvModal;
