import Grid from '@mui/material/Grid';
import { FC } from 'react';
import CustomModal from '../../../common/component/CustomModal';
import { useProjectForm } from '../hook';
import { OptionType } from '../../../common/fields/type';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';
import { ProjectType } from '../type';
import CustomAutocomplete from '../../../common/fields/reacthookfield/CustomAutocomplete';
import CustomTextInput from '../../../common/fields/reacthookfield/CustomTextInput';

type Props = {
  handleClose: () => void;
  handleSubmit: () => void;
  modalTile: string;
  isLoading?: boolean;
  showDraftOption: OptionType[];
  control: Control<ProjectType, any>;
  register: UseFormRegister<ProjectType>;
  errors: FieldErrors<ProjectType>;
};
const ProjectForm: FC<Props> = ({
  handleClose,
  handleSubmit,
  modalTile,
  isLoading,
  showDraftOption,
  control,
  register,
  errors,
}) => {
  const { teamDataDropdown } = useProjectForm();
  return (
    <CustomModal
      saveLoading={isLoading}
      handleSubmit={handleSubmit}
      modalSize='sm'
      modalTitle={modalTile}
      handleClose={handleClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTextInput
            name={'projectName'}
            id={'projectName'}
            fullWidth
            errors={errors}
            variant='outlined'
            label={'Project Name'}
            register={register}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextInput
            name={'projectDescription'}
            id={'projectDescription'}
            fullWidth
            multiline
            errors={errors}
            register={register}
            minRows={5}
            variant='outlined'
            label={'Project Description'}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomAutocomplete
            name='draftType'
            id='draftType'
            errors={errors}
            control={control}
            options={showDraftOption}
            label={'Select Draft Order to Import'}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomAutocomplete
            name='team'
            id='team'
            errors={errors}
            options={teamDataDropdown}
            label={'Select Team'}
            control={control}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default ProjectForm;