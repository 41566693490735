import { Divider, Grid, Typography } from '@mui/material';
import { ImpactDictType, ImpactVisualType } from '../../type';
import DeficitImpactDataGrid from './DeficitImpactDataGrid';
import { transformDeficitImpactDataGrid } from '../../util';
import { Header3 } from '../../../../common/component/Text';

type DraftOverallImpactInfoProps = {
  impactVisualInfo: ImpactVisualType;
  impactDictInfo: ImpactDictType;
};
const DraftOverallImpactInfo = ({
  impactVisualInfo,
  impactDictInfo,
}: DraftOverallImpactInfoProps) => {
  if (
    impactVisualInfo?.summary === 'Points Deficit' ||
    impactDictInfo?.action === 'Points Deficit'
  ) {
    return (
      <Grid container>
        <Grid my={'20px'} item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Header3 textAlign={'center'}>Points Deficit</Header3>
        </Grid>
        <Grid mt={'10px'} item xs={12}>
          <Typography>{impactVisualInfo.deficitSummary}</Typography>
          <Typography>{impactVisualInfo.deficitWarning}</Typography>
        </Grid>
        {impactVisualInfo.deficitImpact && (
          <Grid item xs={12} mt={'20px'}>
            <DeficitImpactDataGrid
              data={transformDeficitImpactDataGrid(impactVisualInfo.deficitImpact)}
            />
          </Grid>
        )}
      </Grid>
    );
  }
  return null;
};

export default DraftOverallImpactInfo;
