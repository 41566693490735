import { Grid } from '@mui/material';
import { FC } from 'react';
import CustomModal from '../../common/component/CustomModal';
import { useCsvView } from './hook';
import CustomSelect from '../../common/fields/reacthookfield/CustomSelect';
import CsvDataGrid from './component/CsvDataGrid';
import { PrimaryButton } from '../../common/component/Button';
import DeleteModal from '../../common/component/DeleteModal';

type CsvViewProps = {
  handleClose: () => void;
};
const CsvView: FC<CsvViewProps> = ({ handleClose }) => {
  const {
    errors,
    csvListData,
    register,
    selectedCsvOption,
    fileListOption,
    deleteCsvLastRowLoading,
    setShowDeleteModal,
    showDeleteModal,
    handleDeleteLastRow,
  } = useCsvView();
  return (
    <CustomModal
      confirmButtonLabel='Confirm'
      handleSubmit={handleClose}
      modalSize={'lg'}
      handleClose={handleClose}
      modalTitle={'CSV contents'}
    >
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Grid item xs={4}>
              <CustomSelect
                id={'csvFile'}
                name={'csv'}
                register={register}
                options={fileListOption}
                size='small'
                key={fileListOption.length}
                value={selectedCsvOption}
                errors={errors}
                fullWidth
                variant='outlined'
                label={'Csv File'}
              />
            </Grid>
            <Grid item xs={2}>
              <PrimaryButton
                onClick={() => setShowDeleteModal(true)}
                disabled={selectedCsvOption === '' || csvListData.length === 0}
                title='Delete last row'
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CsvDataGrid csvList={csvListData} />
        </Grid>
        {showDeleteModal && (
          <DeleteModal
            description={`Are you sure you want to delete the last row`}
            modalTitle='Delete Last Row'
            handleClose={() => setShowDeleteModal(false)}
            handleSubmit={handleDeleteLastRow}
            isLoading={deleteCsvLastRowLoading}
          />
        )}
      </Grid>
    </CustomModal>
  );
};

export default CsvView;