import { Grid } from '@mui/material';
import SmartPlayerList from './component/SmartPlayer';
import { useSmartList } from './hook';
import CustomDropdown from '../../common/fields/CustomDropdown';
import AddUpdatePlayer from '../playerdatabase/component/AddUpdatePlayer';
import { PlayerSelectedTabEnum } from '../playerdatabase/type';
import AddTimelineNote from './component/AddTimelineNote';
import AddUpdateContract from '../playerdatabase/component/AddUpdateContract';

const SmartList = () => {
  const {
    selectedTeam,
    selectedPlayerForEdit,
    playerListInfo,
    setSelectedPlayerForEdit,
    setSelectedTeam,
    playerListOption,
    smartListData,
    isLoading,
    refreshData,
    teamOptions,
    selectedPlayerForAddNote,
    setSelectedPlayerForAddNote,
    addContract,
    setHandleAddContract,
  } = useSmartList();
  return (
    <Grid container mt={'20px'}>
      <Grid item xs={4} mb={'10px'}>
        <CustomDropdown
          label='Team'
          options={teamOptions}
          fullWidth
          variant='outlined'
          onOptionChange={setSelectedTeam}
          value={selectedTeam}
        />
      </Grid>
      <Grid item xs={12}>
        <SmartPlayerList
          loading={isLoading}
          columns={smartListData.columns}
          playerList={smartListData.playerList}
          summaryList={smartListData.summaryData}
          playerListOption={playerListOption}
          onEditClick={(playerId) => {
            setSelectedPlayerForEdit(
              playerListInfo.find((item) => item.id.toString() === playerId.toString()),
            );
          }}
          onAddNoteClick={(playerId) => {
            setSelectedPlayerForAddNote(
              playerListInfo.find((item) => item.id.toString() === playerId.toString()),
            );
          }}
          onYearAddContractClick={(contractInfo) => {
            setHandleAddContract(contractInfo);
          }}
        />
      </Grid>
      {selectedPlayerForEdit && (
        <AddUpdatePlayer
          refreshList={refreshData}
          handleClose={() => {
            setSelectedPlayerForEdit(undefined);
            refreshData();
          }}
          initialTab={PlayerSelectedTabEnum.CONTRACT}
          playerData={selectedPlayerForEdit}
          selectedTeam={selectedTeam}
        />
      )}
      {selectedPlayerForAddNote && (
        <AddTimelineNote
          playerId={selectedPlayerForAddNote.id}
          handleClose={() => {
            setSelectedPlayerForAddNote(undefined);
          }}
        />
      )}
      {addContract && (
        <AddUpdateContract
          handleClose={() => {
            setHandleAddContract(undefined);
          }}
          playerId={addContract.playerId}
          startSeason={addContract.selectedYear}
          refreshList={() => {
            setHandleAddContract(undefined);
            refreshData();
          }}
          seasonList={addContract.contracts.map((item) => ({
            endSeason: new Date(item.endDate).getFullYear().toString(),
            startSeason: new Date(item.startDate).getFullYear().toString(),
          }))}
          selectedTeam={selectedTeam}
        />
      )}
    </Grid>
  );
};

export default SmartList;
