import Styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material';
import { SIDEBAR_COLOR } from '../../theme/color';
import { FONT_THEME } from '../../theme/fontTheme';

export const SideBarContainer = Styled(Box)`
    background: ${SIDEBAR_COLOR.menuBackground};
    border-radius: 14px;
    height: calc(100vh - 95px);
    overflow: auto;
    padding: 30px 10px;
    ::-webkit-scrollbar {
      display: none;
    }
`;

type MenuProps = {
  isactive: number;
  issidebaropen?: number;
};
export const StyledMenuOption = Styled(Grid)<MenuProps>`
    background: ${(props) =>
      props.isactive
        ? SIDEBAR_COLOR.isActiveOptionBackground
        : SIDEBAR_COLOR.inActiveOptionBackground} ;
    box-shadow: ${(props) => (props.isactive ? '0px 0px 20px rgba(255, 217, 84, 0.56)' : 'none')};
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    ${(props) =>
      !props.issidebaropen &&
      `
        display: flex;
        justify-content: center;
    `}
`;

export const StyledMenuText = Styled(Typography)<MenuProps>`
    font-family: ${FONT_THEME.secondary};
    font-style: normal;
    font-weight: ${(props) => (props.isactive ? 700 : 600)};
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: ${(props) =>
      props.isactive ? SIDEBAR_COLOR.isActiveTextCOlor : SIDEBAR_COLOR.inActiveTextColor}; 
    svg {
        height: 18px;
        width: 18px;
    }
`;

export const LogoStyle = Styled.span`
  font-family: 'DM Sans', sans-serif;
  color: #1A2E49;
`;
