import CustomModal from '../../../common/component/CustomModal';
import { useAddTimelineNote } from '../hook';
import { Box, Divider, Grid, Typography } from '@mui/material';
import TimelineForm from '../../playerinfo/component/TimelineForm';
import TimeLineHistoryList from './TimeLineHistoryList';
import { PrimaryButton } from '../../../common/component/Button';
import { sortBy } from 'lodash';

type AddTimelineNoteProps = {
  handleClose: () => void;
  playerId: string;
};
const AddTimelineNote = ({ handleClose, playerId }: AddTimelineNoteProps) => {
  const {
    control,
    errors,
    handleSubmit,
    isLoading,
    onSubmit,
    register,
    selectedFormFieldInfo,
    timelineEntry,
  } = useAddTimelineNote(playerId);

  return (
    <CustomModal
      handleSubmit={handleSubmit(onSubmit)}
      modalSize={'md'}
      handleClose={handleClose}
      saveLoading={isLoading}
      modalTitle={'Add Entry'}
      hideDialogAction
      disableButton={!selectedFormFieldInfo}
    >
      <Grid container>
        {selectedFormFieldInfo && (
          <Grid item xs={12}>
            <TimelineForm
              control={control}
              errors={errors}
              register={register}
              fieldInfo={selectedFormFieldInfo}
            />
          </Grid>
        )}
        <Grid item xs={12} mt={'20px'}>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <PrimaryButton
              style={{
                borderRadius: 10,
                width: 134,
              }}
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
              title={'Save'}
            />
          </Box>
        </Grid>
        <Grid item xs={12} mt={'20px'}>
          <Divider />
        </Grid>
        <Grid item xs={12} mt={'20px'}>
          <Typography fontWeight={'bold'}>Previous</Typography>
        </Grid>
        <Grid item xs={12}>
          <TimeLineHistoryList
            timelineHistory={sortBy(
              timelineEntry.filter((item) => item.name === 'LIST_MANAGEMENT_NOTE'),
              (item) => {
                return new Date(item.createdAt);
              },
            ).reverse()}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default AddTimelineNote;
