import { RankingUpdateListType, UpdateRankingType } from '../../../container/playerranking/type';

export const reverseTransformRankingData = (data: RankingUpdateListType): any => {
  return {
    draft_year: new Date(data.draftYear).getFullYear(),
    name: data.name,
  };
};

export const reverseTransformPlayerRankingData = (data: UpdateRankingType): any => {
  return {
    ranking: data.ranking,
    tier: data.tier,
    player_id: data.playerId,
  };
};
