/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Routes } from 'react-router';
import PrivateRoute from './route/PrivateRoute';
import {
  DashboardRoute,
  DraftBoardRoute,
  DraftPickRoute,
  OrganisationRoute,
  PlayerDatabaseRoute,
  PlayerManagerRoute,
  ProjectRoute,
  ScenarioPlanningRoute,
  TradeAnalysisRoute,
} from './route/Routes';
import { routeUrl } from './route/Url';
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import Register from './container/register/Register';
import { useDispatch } from 'react-redux';
import { updateUserDetail } from './store/auth';
import { useLazyGetUserDetailQuery } from './store/service';
import { useGetAccessToken } from './store/auth/selector';
import { useAppSelector } from './store/hooks';
import LoginForm from './container/login/LoginForm';
import { SHOW_AUTH0_LOGIN } from './util/SiteHelper';
import SmartList from './container/smartlist/SmartList';
import TradeOfferList from './container/planner/TradeOffer';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Typography } from '@mui/material';
import { transformOrgList } from './store/auth/util';
import PlayerRanking from './container/playerranking/PlayerRanking';
import PlayerProfile from './container/playerprofile/PlayerProfile';

function App() {
  const dispatch = useDispatch();
  const [getUserDetail, { data }] = useLazyGetUserDetailQuery();
  const accessToken = useAppSelector(useGetAccessToken);
  useEffect(() => {
    if (SHOW_AUTH0_LOGIN && accessToken) {
      getUserDetail('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (data) {
      dispatch(
        updateUserDetail({
          auth0Id: data.auth0_id,
          email: data.email,
          groups: data.groups,
          isActive: data.is_active,
          isStaff: data.is_staff,
          teamId: data.Teams,
          userId: data.id,
          username: data.username,
          uui: data.uui,
          isSuperuser: data.is_superuser,
          organisation: transformOrgList(data.organisations),
        }),
      );
    }
  }, [data]);

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route
          path='*'
          element={
            <PrivateRoute>
              <Typography variant='h4' textAlign={'center'}>
                Page not found
              </Typography>
            </PrivateRoute>
          }
        />
        <Route
          path='/'
          element={
            <PrivateRoute>
              <Navigate to={routeUrl.dashboard} />
            </PrivateRoute>
          }
        />
        <Route path='/login' element={<LoginForm />} />
        <Route
          path='/register'
          element={
            <PrivateRoute>
              <Register />
            </PrivateRoute>
          }
        />
        <Route
          path={routeUrl.playerRanking}
          element={
            <PrivateRoute>
              <PlayerRanking />
            </PrivateRoute>
          }
        />
        <Route
          path={routeUrl.dashboard}
          element={
            <PrivateRoute>
              <DashboardRoute />
            </PrivateRoute>
          }
        />
        <Route
          path={routeUrl.draftBoard}
          element={
            <PrivateRoute>
              <DraftBoardRoute />
            </PrivateRoute>
          }
        />
        <Route
          path={routeUrl.scenarioPlanning}
          element={
            <PrivateRoute>
              <ScenarioPlanningRoute />
            </PrivateRoute>
          }
        />
        <Route
          path={routeUrl.draftPick}
          element={
            <PrivateRoute>
              <DraftPickRoute />
            </PrivateRoute>
          }
        />
        <Route
          path={routeUrl.projectList}
          element={
            <PrivateRoute>
              <ProjectRoute />
            </PrivateRoute>
          }
        />
        <Route
          path={routeUrl.tradeAnalysis}
          element={
            <PrivateRoute>
              <TradeAnalysisRoute />
            </PrivateRoute>
          }
        />
        <Route
          path={routeUrl.smartList}
          element={
            <PrivateRoute>
              <SmartList />
            </PrivateRoute>
          }
        />
        <Route
          path={routeUrl.tradeOffer}
          element={
            <PrivateRoute>
              <TradeOfferList />
            </PrivateRoute>
          }
        />
        <Route
          path={routeUrl.organisation}
          element={
            <PrivateRoute>
              <OrganisationRoute />
            </PrivateRoute>
          }
        />
        <Route
          path={routeUrl.playerDatabase}
          element={
            <PrivateRoute>
              <PlayerDatabaseRoute />
            </PrivateRoute>
          }
        />
        <Route
          path={routeUrl.playerManager}
          element={
            <PrivateRoute>
              <PlayerManagerRoute />
            </PrivateRoute>
          }
        />
        <Route
          path={routeUrl.playerHomeInfo}
          element={
            <PrivateRoute>
              <PlayerProfile />
            </PrivateRoute>
          }
        />
        <Route
          path={routeUrl.playerProfile}
          element={
            <PrivateRoute>
              <PlayerProfile />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
