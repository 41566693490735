import { CloseOutlined } from '@mui/icons-material';
import { Autocomplete, Box, IconButton, TextField } from '@mui/material';
import { OptionType } from '../../../common/fields/type';

type SearchPlayerTextfieldProps = {
  selectedPlayer: string;
  options: OptionType[];
  setSelectedPlayer: (selectedPlayer: string) => void;
  setShowSearchPlayerField: (status: boolean) => void;
};
const SearchPlayerTextfield = ({
  options,
  selectedPlayer,
  setSelectedPlayer,
  setShowSearchPlayerField,
}: SearchPlayerTextfieldProps) => {
  return (
    <Box display={'flex'}>
      <Autocomplete
        fullWidth
        size={'small'}
        value={options.find((item) => item.value === selectedPlayer)}
        onChange={(_: any, newValue: any) => {
          setSelectedPlayer(newValue?.value);
        }}
        options={options}
        renderInput={(params) => <TextField {...params} />}
      />
      <IconButton onClick={() => setShowSearchPlayerField(false)}>
        <CloseOutlined />
      </IconButton>
    </Box>
  );
};

export default SearchPlayerTextfield;
