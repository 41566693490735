import { Formik } from 'formik';
import React, { FC } from 'react';
import CustomModal from '../../../common/component/CustomModal';
import { DraftNightSelectionInitialValue } from '../util';
import { Grid } from '@mui/material';
import FormikAutocomplete from '../../../common/fields/FormikAutocomplete';
import { useDraftNightSelection } from '../hook';

type Props = {
  closeTransactionModal: () => void;
};

const DraftNightSection: FC<Props> = ({ closeTransactionModal }) => {
  const { allPickDataDropdown, playerDataDropdown, isLoading, submitData } =
    useDraftNightSelection();

  return (
    <>
      <Formik
        initialValues={DraftNightSelectionInitialValue}
        enableReinitialize
        onSubmit={submitData}
      >
        {({ handleSubmit }) => (
          <CustomModal
            handleSubmit={() => handleSubmit()}
            saveLoading={isLoading}
            modalSize={'sm'}
            handleClose={closeTransactionModal}
            modalTitle='Draft Night Selection'
          >
            <Grid container spacing={'10px'}>
              <Grid item xs={12}>
                <FormikAutocomplete
                  name='teamSelectPick'
                  id='teamSelectPick'
                  options={allPickDataDropdown}
                  label={'Select Pick'}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikAutocomplete
                  name='playerTaken'
                  id='playerTaken'
                  options={playerDataDropdown}
                  label={'Select Player Taken'}
                />
              </Grid>
            </Grid>
          </CustomModal>
        )}
      </Formik>
    </>
  );
};

export default DraftNightSection;
