import { object, string } from 'yup';
import { PassImpactDataType, PassPickType } from './type';
import { RadioOptionType } from '../../../../common/fields/reacthookfield/type';

export const transformPassPickOption = (data: any) => {
  if (!data) {
    return [];
  }

  return data
    .filter((item: any) => item.pick_status !== 'Used')
    .map((item: any) => ({
      value: item.value,
      label: item.label,
      unique: item.unique,
    }));
};

export const PassPickInitialValue: PassPickType = {
  pickId: '',
  selectedPassPick: 'ONE',
};

export const PassPickValidationSchema = object().shape({
  pickId: string().required('Please select Pick Id'),
});

export const transformPassImpactData = (data: any): PassImpactDataType | null => {
  if (!data) {
    return null;
  }

  return {
    passSummay: data.pass_summary,
    picksPassed: data.picks_passed,
  };
};

export const PassPickRadioOption: RadioOptionType[] = [
  {
    label: 'Pass this pick only',
    value: 'ONE',
  },
  {
    label: 'Pass this pick and all remaing picks',
    value: 'ALL',
  },
];

export const ContractEndReasonRadioOption: RadioOptionType[] = [
  {
    label: 'Delisted',
    value: 'DELISTED',
  },
  {
    label: 'Retired',
    value: 'RETIRED',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const DraftValueIndexRadioOption: RadioOptionType[] = [
  {
    label: 'Current DVI (Pre 2024)',
    value: 'RULE_1',
  },
  {
    label: 'Proposed DVI (2024+)',
    value: 'RULE_2',
  },
];

export const BidDiscountsRadioOption: RadioOptionType[] = [
  {
    label: 'Current Rules (20% & 197 post Pick 18)',
    value: 'RULE_1',
  },
  {
    label: 'Proposed Rule 1 (10% first bid, 0% after)',
    value: 'RULE_2',
  },
  {
    label: 'Proposed Rule 2 (20% first bid, 10% second bid, 0% after)',
    value: 'RULE_3',
  },
];
