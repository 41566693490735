export const storeAccesToken = (accessToken: string) => {
  localStorage.setItem('smart_draft', accessToken);
};

export const removeAccessToken = () => {
  localStorage.removeItem('persist:root');
};

export const getAccessToken = (): string => {
  return localStorage.getItem('smart_draft') || '';
};
