import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import { SLIDER_COLOR } from '../../../theme/color';
import { Box } from '@mui/material';

export const PrettoSlider = styled(Slider)({
  color: SLIDER_COLOR.primary,
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: SLIDER_COLOR.primary,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

export const StyledCustomDateRangePicker = styled(Box)`
  & .react-datepicker-wrapper {
    width: 96%;
    & .react-datepicker__input-container {
      width: 100%;
    }
    input {
      width: 98%;
      height: 40px;
      border: 1px #ccc solid;
      border-radius: 5px;
      padding: 0px 10px;
      font-size: 16px;
    }
  }
`;
