import Styled from '@emotion/styled';
import { Header1 } from '../../common/component/Text';

export const PlayerStyledTagNumber = Styled(Header1)`
    font-size: 50px;
    background-color: #f7e3a2;
    width: fit-content;
    padding: 20px;
    border-radius: 20px;
    color: #8b0042;
    box-shadow: 1px 1px 10px #f7e3a2;
`;

type StyledListProps = {
  hideDot?: boolean;
};
export const StyledList = Styled.li<StyledListProps>`
    ${(props) =>
      props.hideDot &&
      `
    list-style-type: none;
    
    `} 
    &::marker {
        color: #fff;
    }
`;
