import { baseQuesryWithErrorToast } from '..';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  createFreeAgentScenarioApiUrl,
  createScenarioMultiTradeTeam,
  fetchScenarioMultiTeamTradeImpact,
  getFreeAgentScenarioImpactApiUrl,
} from '../../../constant/ApiConstant';
import { ScenarioTradeType } from '../../../container/scenarioplanning/type';
import { IReverseFreeAgentCompensation } from '../type';
import { reverseFreeAgentTransformData } from '../util';

export const scenarioService = createApi({
  reducerPath: 'scenarioService',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQuesryWithErrorToast,
  endpoints: (builder) => ({
    getScenarioCompleteMultiTransactionImpactData: builder.mutation({
      query: ({
        data,
        projectId,
        scenarioInfo,
      }: {
        data: any;
        projectId: string;
        scenarioInfo: ScenarioTradeType;
      }) => ({
        url: fetchScenarioMultiTeamTradeImpact(
          projectId,
          scenarioInfo.scenarioId,
          scenarioInfo.transactionLength.toString(),
        ),
        method: 'POST',
        body: data,
      }),
    }),
    createScenarioMultiTransactionImpactData: builder.mutation({
      query: ({
        data,
        projectId,
        scenarioInfo,
      }: {
        data: any;
        projectId: string;
        scenarioInfo: ScenarioTradeType;
      }) => ({
        url: createScenarioMultiTradeTeam(
          projectId,
          scenarioInfo.scenarioId,
          scenarioInfo.transactionLength.toString(),
        ),
        method: 'POST',
        body: data,
      }),
    }),
    createScenarioFreeAgentCompensation: builder.mutation({
      query: ({
        data,
        projectId,
        scenarioInfo,
      }: {
        data: IReverseFreeAgentCompensation;
        projectId: string;
        scenarioInfo: ScenarioTradeType;
      }) => ({
        url: createFreeAgentScenarioApiUrl(
          projectId,
          scenarioInfo.scenarioId,
          scenarioInfo.transactionLength.toString(),
        ),
        method: 'POST',
        body: reverseFreeAgentTransformData(data),
      }),
    }),
    getScenarioTransactionImpactFreeAgent: builder.mutation({
      query: ({
        data,
        projectId,
        scenarioInfo,
      }: {
        data: IReverseFreeAgentCompensation;
        projectId: string;
        scenarioInfo: ScenarioTradeType;
      }) => ({
        url: getFreeAgentScenarioImpactApiUrl(
          projectId,
          scenarioInfo.scenarioId,
          scenarioInfo.transactionLength.toString(),
        ),
        method: 'POST',
        body: reverseFreeAgentTransformData(data),
      }),
    }),
  }),
});

export const {
  useCreateScenarioMultiTransactionImpactDataMutation,
  useGetScenarioCompleteMultiTransactionImpactDataMutation,
  useCreateScenarioFreeAgentCompensationMutation,
  useGetScenarioTransactionImpactFreeAgentMutation,
} = scenarioService;
