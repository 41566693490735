import { GridCellParams } from '@mui/x-data-grid';
import { CompactTag, Header2, Tag } from '../../../common/component/Text';
import { Box, IconButton, Tooltip } from '@mui/material';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { BUTTON_COLOR_THEME, TEXT_COLOR_THEME } from '../../../theme/color';
import {
  AccoladeType,
  ContractType,
  ElegibilityType,
  PlayerDatabaseType,
  PlayerManagerType,
} from '../type';
import {
  PlayerPositionOptions,
  TeamFlagOptions,
  draftPlayerPositionObj,
} from '../../../constant/OptionConstant';
import { MdHeight } from 'react-icons/md';
import { GiWeightScale } from 'react-icons/gi';
import CustomDataGridAction from '../../../common/component/CustomDataGridAction';
import { startCase } from 'lodash';

const getPreferredName = (playerData: PlayerDatabaseType) => {
  if (
    playerData.firstName === playerData.preferredFirstName &&
    playerData.lastName === playerData.preferredLastName
  ) {
    return '';
  }
  return `(${playerData.preferredFirstName} ${playerData.preferredLastName})`;
};

export const PlayerListColumn = (
  handleEdit: (data: PlayerDatabaseType) => void,
  handleDelete: (data: PlayerDatabaseType) => void,
  handleView: (id: string) => void,
) => [
  {
    field: 'firstName',
    headerName: 'Name',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, PlayerDatabaseType>) => (
      <Header2
        sx={{
          cursor: 'pointer',
        }}
        fontcolor={TEXT_COLOR_THEME.link}
        onClick={() => handleView(params.row.id)}
      >
        {params.row.firstName} {params.row.lastName}
        {getPreferredName(params.row)}
      </Header2>
    ),
  },
  {
    field: 'position',
    headerName: 'Position',
    minWidth: 100,
    sortable: false,
    renderCell: (params: GridCellParams<any, PlayerDatabaseType>) => (
      <Tag
        textAlign={'center'}
        textsize='12px'
        height={'fit-content'}
        bgcolor={draftPlayerPositionObj?.[params.row.position]?.color || '#000'}
      >
        {PlayerPositionOptions.find((pos) => pos.value === params.row.position)?.label}
      </Tag>
    ),
  },
  {
    field: 'secondaryPosition',
    headerName: 'Secondary Position',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, PlayerDatabaseType>) => (
      <Tag
        textAlign={'center'}
        textsize='12px'
        height={'fit-content'}
        bgcolor={draftPlayerPositionObj?.[params.row.secondaryPosition]?.color || '#000'}
      >
        {PlayerPositionOptions.find((pos) => pos.value === params.row.secondaryPosition)?.label}
      </Tag>
    ),
  },
  {
    field: 'dateOfBirth',
    headerName: 'Date of birth',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, PlayerDatabaseType>) => (
      <Header2>{params.row.dateOfBirth}</Header2>
    ),
  },
  {
    field: 'height',
    headerName: 'Height/Weight',
    minWidth: 250,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, PlayerDatabaseType>) => (
      <Box mt={'5px'} mb={'5px'} display={'flex'} gap={'10px'}>
        <CompactTag
          gap={'5px'}
          display={'flex'}
          alignItems={'center'}
          textsize={'12px'}
          textAlign={'center'}
          bgcolor={'transparent'}
          fontcolor={TEXT_COLOR_THEME.primary}
          width={70}
        >
          <MdHeight /> {params.row.height} cm
        </CompactTag>
        <CompactTag
          gap={'5px'}
          display={'flex'}
          alignItems={'center'}
          textsize={'12px'}
          textAlign={'center'}
          bgcolor={'transparent'}
          fontcolor={TEXT_COLOR_THEME.primary}
        >
          <GiWeightScale /> {params.row.weight} kg
        </CompactTag>
      </Box>
    ),
  },
  {
    field: 'id',
    headerName: '',
    maxWidth: 150,
    sortable: false,
    renderCell: (params: GridCellParams<any, PlayerDatabaseType>) => (
      <Box>
        <CustomDataGridAction
          onEditClick={() => handleEdit(params.row)}
          onDeleteClick={() => handleDelete(params.row)}
          onViewClick={() => handleView(params.row.id)}
        />
      </Box>
    ),
  },
];

export const ContractListColumn = (
  handleEdit: (data: ContractType) => void,
  handleDelete: (id: ContractType) => void,
) => [
  {
    field: 'team',
    headerName: 'Team',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, ContractType>) => (
      <Header2>
        {
          TeamFlagOptions.find((item) => item.id.toString() === params.row.team.toString())
            ?.teamName
        }
      </Header2>
    ),
  },
  {
    field: 'startSeason',
    headerName: 'Start Season',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, ContractType>) => (
      <Header2>{params.row.startSeason}</Header2>
    ),
  },
  {
    field: 'endSeason',
    headerName: 'End Season',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, ContractType>) => (
      <Header2>{params.row.endSeason}</Header2>
    ),
  },
  {
    field: 'signingDate',
    headerName: 'Sigining Date',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, ContractType>) => (
      <Header2>{params.row.signingDate}</Header2>
    ),
  },
  {
    field: 'contractType',
    headerName: 'Contract Type',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, ContractType>) => (
      <Header2>{params.row.contractType}</Header2>
    ),
  },
  {
    field: 'id',
    headerName: '',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, ContractType>) => (
      <Box display={'flex'}>
        <IconButton title='Edit' onClick={() => handleEdit(params.row)}>
          <EditOutlined sx={{ color: BUTTON_COLOR_THEME.primary }} />
        </IconButton>
        <IconButton title={'Delete'} onClick={() => handleDelete(params.row)}>
          <DeleteOutline color={'error'} />
        </IconButton>
      </Box>
    ),
  },
];

export const AccoladeListColumn = (
  handleEdit: (data: AccoladeType) => void,
  handleDelete: (data: AccoladeType) => void,
) => [
  {
    field: 'accoladeName',
    headerName: 'Name',
    minWidth: 150,
    flex: 2,
    sortable: false,
    renderCell: (params: GridCellParams<any, AccoladeType>) => (
      <Tooltip title={params.row.accoladeName}>
        <Header2
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {params.row.accoladeName}
        </Header2>
      </Tooltip>
    ),
  },
  {
    field: 'season',
    headerName: 'Season',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, AccoladeType>) => (
      <Header2>{params.row.season}</Header2>
    ),
  },
  {
    field: 'accoladeCategory',
    headerName: 'Category',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, AccoladeType>) => (
      <Header2>{params.row.accoladeCategory}</Header2>
    ),
  },
  {
    field: 'id',
    headerName: '',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, AccoladeType>) => (
      <Box display={'flex'}>
        <IconButton title='Edit' onClick={() => handleEdit(params.row)}>
          <EditOutlined sx={{ color: BUTTON_COLOR_THEME.primary }} />
        </IconButton>
        <IconButton title={'Delete'} onClick={() => handleDelete(params.row)}>
          <DeleteOutline color={'error'} />
        </IconButton>
      </Box>
    ),
  },
];

export const PlayerManagerListColumn = (
  handleEdit: (data: PlayerManagerType) => void,
  handleDelete: (data: PlayerManagerType) => void,
) => [
  {
    field: 'manager',
    headerName: 'Manager',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, PlayerManagerType>) => (
      <Header2>{params.row.agent.name}</Header2>
    ),
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, PlayerManagerType>) => (
      <Header2>{params.row.startDate}</Header2>
    ),
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, PlayerManagerType>) => (
      <Header2>{params.row.endDate}</Header2>
    ),
  },
  {
    field: 'id',
    headerName: '',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, PlayerManagerType>) => (
      <Box display={'flex'}>
        <IconButton title='Edit' onClick={() => handleEdit(params.row)}>
          <EditOutlined sx={{ color: BUTTON_COLOR_THEME.primary }} />
        </IconButton>
        <IconButton title={'Delete'} onClick={() => handleDelete(params.row)}>
          <DeleteOutline color={'error'} />
        </IconButton>
      </Box>
    ),
  },
];

export const ElegibilityListColumn = (
  handleEdit: (data: ElegibilityType) => void,
  handleDelete: (id: ElegibilityType) => void,
) => [
  {
    field: 'season',
    headerName: 'Season',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, ElegibilityType>) => (
      <Header2>{params.row.season}</Header2>
    ),
  },
  {
    field: 'eligibilityType',
    headerName: 'Eligibility Type',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, ElegibilityType>) => (
      <Header2>{startCase(params.row.eligibilityType)}</Header2>
    ),
  },
  {
    field: 'eligibilityStatus',
    headerName: 'Eligibility Status',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, ElegibilityType>) => (
      <Header2>{params.row.eligibilityStatus}</Header2>
    ),
  },
  {
    field: 'id',
    headerName: '',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, ElegibilityType>) => (
      <Box display={'flex'}>
        <IconButton title='Edit' onClick={() => handleEdit(params.row)}>
          <EditOutlined sx={{ color: BUTTON_COLOR_THEME.primary }} />
        </IconButton>
        <IconButton title={'Delete'} onClick={() => handleDelete(params.row)}>
          <DeleteOutline color={'error'} />
        </IconButton>
      </Box>
    ),
  },
];
