import { Box, Grid, Tooltip, Typography } from '@mui/material';
import React, { FC } from 'react';
import { InitialDraftHandType } from '../../type';
import { CompactTag } from '../../../../common/component/Text';
import { TEXT_COLOR_INFO_TYPE } from '../../../../theme/color';

type DraftHandInfoProps = {
  draftHand: InitialDraftHandType[];
  title: string;
};
const DraftHandInfo: FC<DraftHandInfoProps> = ({ draftHand, title }) => {
  return (
    <Grid container my={'30px'}>
      <Grid item xs={12}>
        <Typography align='center'>{title}</Typography>
      </Grid>
      <Grid item xs={12} mt={'10px'}>
        <Grid container alignItems={'center'} flexWrap={'nowrap'} gap={'20px'}>
          <Grid item>{draftHand.find((item) => item.yearType === 'Current')?.year}</Grid>
          <Grid item>
            <Box display={'flex'} gap={'5px'} flexWrap={'wrap'}>
              {draftHand
                .filter((item) => item.yearType === 'Current')
                .map((item) => (
                  <Tooltip key={`tooltip-${item.overallPick}`} title={item.overallPick}>
                    <CompactTag
                      gap={'5px'}
                      display={'flex'}
                      alignItems={'center'}
                      textsize={'12px'}
                      textAlign={'center'}
                      bgcolor={'transparent'}
                      fontcolor={
                        item.pickStatus === 'Used' ? TEXT_COLOR_INFO_TYPE.error : '#189a18'
                      }
                      minWidth={15}
                    >
                      {item.overallPick}
                    </CompactTag>
                  </Tooltip>
                ))}
            </Box>
          </Grid>
        </Grid>
        <Grid container mt={'10px'} alignItems={'center'} flexWrap={'nowrap'} gap={'20px'}>
          <Grid item>{draftHand.find((item) => item.yearType === 'Next')?.year}</Grid>
          <Grid item>
            <Box display={'flex'} gap={'5px'} flexWrap={'wrap'}>
              {draftHand
                .filter((item) => item.yearType === 'Next')
                .map((item) => (
                  <Tooltip key={`overall-tooltip-${item.overallPick}`} title={item.overallPick}>
                    <CompactTag
                      gap={'5px'}
                      display={'flex'}
                      alignItems={'center'}
                      textsize={'12px'}
                      textAlign={'center'}
                      bgcolor={'transparent'}
                      fontcolor={'#a9a9a9'}
                      style={{ width: 20 }}
                    >
                      {item.overallPick}
                    </CompactTag>
                  </Tooltip>
                ))}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DraftHandInfo;
