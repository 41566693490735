import { GridCellParams } from '@mui/x-data-grid';
import { Header2 } from '../../../common/component/Text';

export const csvListDataColumn = () => [
  {
    field: 'order',
    headerName: 'Order',
    width: 100,
    sortable: false,
    editable: true,
    renderCell: (params: GridCellParams) => <Header2>{params.row.order}</Header2>,
  },
  {
    field: 'Transaction',
    headerName: 'Transaction',
    width: 250,
    sortable: false,
    renderCell: (params: GridCellParams) => <Header2>{params.row.transaction}</Header2>,
  },
  {
    field: 'Notes',
    headerName: 'Note',
    minWidth: 200,
    sortable: false,
    renderCell: (params: GridCellParams) => <Header2>{params.row.notes}</Header2>,
  },
  {
    field: 'apiLink',
    headerName: 'Api Link',
    width: 400,
    sortable: false,
    renderCell: (params: GridCellParams) => <Header2>{params.row.apiLink}</Header2>,
  },
  {
    field: 'apiEndPoints',
    headerName: 'Api End Points',
    sortable: false,
    width: 20000,
    renderCell: (params: GridCellParams) => <Header2>{params.row.apiEndPoints}</Header2>,
  },
];
