import { object, string } from 'yup';
import { RegisterType } from './type';
import { OptionType } from '../../common/fields/type';

export const registerInitialValue: RegisterType = {
  firstName: '',
  lastName: '',
  email: '',
  teamId: '',
  permission: '',
};

export const registerValidationSchema = object().shape({
  firstName: string().required('Please enter first name'),
  lastName: string().required('Please enter last name'),
  teamId: string().required('Please enter team'),
  email: string().email('Please enter correct email').required('Please enter email'),
  permission: string().required('Please select user permission'),
});

export const transformTeamNameDropdownOption = (data?: any): OptionType[] => {
  if (!data) {
    return [];
  }

  return data.map((item: any) => ({
    value: item.id,
    label: item.TeamNames,
  }));
};
