import { format } from 'date-fns';
import { PlayerAgencyAddUpdateType, PlayerManagerAgencyAddUpdateType } from './type';

export const reverseTransformPlayerAgency = (data: PlayerAgencyAddUpdateType) => {
  return {
    name: data.name,
    phone: data.phone.replace(/\s+/g, ''),
    website: data.website,
    email: data.email,
  };
};

export const reverseTransformPlayerManagerAgency = (data: PlayerManagerAgencyAddUpdateType) => {
  return {
    company_id: data.companyId,
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    phone: data.phone.replace(/\s+/g, ''),
    position: data.position,
    start_date: format(new Date(data.startDate), 'yyyy-MM-dd'),
    end_date: format(new Date(data.endDate), 'yyyy-MM-dd'),
  };
};
