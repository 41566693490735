import { baseQuesryWithErrorToast } from '..';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  fetchClubSummaryApiUrl,
  roasterAllocationYearTeamApiUrl,
} from '../../../constant/ApiConstant';

export const clubSummaryServiceApi = createApi({
  reducerPath: 'clubsummarysearvice',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQuesryWithErrorToast,
  endpoints: (builder) => ({
    getClubSummaryInfo: builder.query({
      query: ({ orgId, teamId }: { orgId: string; teamId: string }) =>
        fetchClubSummaryApiUrl(orgId, teamId),
    }),
    roasterAllocationYearTeamInfo: builder.query({
      query: ({ orgId, teamId }: { orgId: string; teamId: string }) =>
        roasterAllocationYearTeamApiUrl(orgId, teamId),
    }),
  }),
});

export const { useLazyGetClubSummaryInfoQuery, useLazyRoasterAllocationYearTeamInfoQuery } =
  clubSummaryServiceApi;
