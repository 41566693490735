import { useEffect, useMemo } from 'react';
import { OptionType } from '../../../../common/fields/type';
import { useGetTransactionImpactFreeAgentMutation } from '../../../../store/service';
import { FreeAgentCompensationType, TransactionImpactPriorityPick } from '../../type';
import { transformImpactPriorityPick } from '../../util';
import { ScenarioTradeType } from '../../../scenarioplanning/type';
import { useGetScenarioTransactionImpactFreeAgentMutation } from '../../../../store/service/scenarioservice';

export const useFreeAgentTransactionCompensation = (
  projectId: string,
  values: FreeAgentCompensationType,
  allPickDataDropdown: OptionType[],
  scenarioTradeInfo?: ScenarioTradeType,
) => {
  const [getTransaction, { data }] = useGetTransactionImpactFreeAgentMutation();
  const [getScenarioTransaction, { data: scenarioTransaction }] =
    useGetScenarioTransactionImpactFreeAgentMutation();

  useEffect(() => {
    values && getTransactionImpact();
  }, [values]);

  const getTransactionImpact = () => {
    if (
      values.compensationPickType &&
      values.pickInstruction &&
      values.teamReceivingCompensation &&
      values.reason &&
      values.compensationPickType
    ) {
      if (scenarioTradeInfo) {
        getScenarioTransaction({
          data: {
            ...values,
            uniquePick:
              allPickDataDropdown.find((item) => item.value === values.compensationAligned)!
                .unique || '',
          },
          projectId,
          scenarioInfo: {
            ...scenarioTradeInfo,
            transactionLength: scenarioTradeInfo.transactionLength - 1,
          },
        });
        return;
      }
      getTransaction({
        data: {
          ...values,
          uniquePick:
            allPickDataDropdown.find((item) => item.value === values.compensationAligned)!.unique ||
            '',
        },
        projectId: projectId,
      });
    }
  };

  const impactData: TransactionImpactPriorityPick = useMemo(
    () => data && transformImpactPriorityPick(data),
    [data],
  );

  const scenarioImpactData: TransactionImpactPriorityPick = useMemo(
    () => scenarioTransaction && transformImpactPriorityPick(scenarioTransaction),
    [scenarioTransaction],
  );
  return {
    impactData: scenarioTradeInfo ? scenarioImpactData : impactData,
  };
};
