import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProjectList, PROJECT_LIST } from './type';

const initialState: IProjectList[] = [];

const projectSlice = createSlice({
  name: PROJECT_LIST,
  initialState,
  reducers: {
    updateProjectList(state, action: PayloadAction<IProjectList[]>) {
      state = action.payload;
      return state;
    },
  },
  extraReducers: {},
});

export const { updateProjectList } = projectSlice.actions;

export default projectSlice.reducer;
