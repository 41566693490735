import { Box, IconButton } from '@mui/material';
import React, { FC } from 'react';
import { Header2 } from '../../../common/component/Text';
import { StyledLogo } from '../../dashboard/style';
import { TeamFlagOptions } from '../../../constant/OptionConstant';
import { RosterListType } from '../type';
import { BiMinus, BiPlus } from 'react-icons/bi';

type RosterCardProps = {
  item: RosterListType;
  setRosterAmount: (rosterId: number, rosterYear: string, amount: number) => void;
  index: number;
};
const RosterCard: FC<RosterCardProps> = ({ item, setRosterAmount, index }) => {
  return (
    <Box borderBottom={'1px #efefef solid'} px={'10px'} mt={'12px'} display={'flex'} key={item.id}>
      <Box flex={1}>
        <Header2>{index + 1}</Header2>
      </Box>
      <Box flex={2}>
        <Box display={'flex'} gap={'10px'} alignItems={'center'}>
          <StyledLogo
            src={TeamFlagOptions.find((flag) => flag.id === item.teamId)?.flagIcon || ''}
            style={{ height: 30, width: 30 }}
            alt={item.teamId.toString()}
          />
          <Header2>{TeamFlagOptions.find((flag) => flag.id === item.teamId)?.shortName}</Header2>
        </Box>
      </Box>
      <Box flex={2}>
        <Box display={'flex'} gap={'10px'} alignItems={'center'}>
          <IconButton
            onClick={() => setRosterAmount(item.id, item.currentYear, item.currentYearAmount - 1)}
            disabled={item.currentYearAmount <= 1}
          >
            <BiMinus />
          </IconButton>
          <Header2>{item.currentYearAmount}</Header2>
          <IconButton
            onClick={() => setRosterAmount(item.id, item.currentYear, item.currentYearAmount + 1)}
            disabled={item.currentYearAmount >= 10}
          >
            <BiPlus />
          </IconButton>
        </Box>
      </Box>
      <Box flex={2}>
        <Box display={'flex'} gap={'10px'} alignItems={'center'}>
          <IconButton
            onClick={() => setRosterAmount(item.id, item.nextYear, item.nextYearAmount - 1)}
            disabled={item.nextYearAmount <= 1}
          >
            <BiMinus />
          </IconButton>
          <Header2>{item.nextYearAmount}</Header2>
          <IconButton
            onClick={() => setRosterAmount(item.id, item.nextYear, item.nextYearAmount + 1)}
            disabled={item.nextYearAmount >= 10}
          >
            <BiPlus />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default RosterCard;
