import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISiteStatus, SITE_STATUS, UploadCsvTransactionType } from './type';
import { cloneDeep } from 'lodash';
import { IFlagList } from '../../container/flags/type';

const initialState: ISiteStatus = {
  currentProject: '',
  currentOrganisation: '',
  selectedFlag: '',
  hoveredTeamId: '',
  selectedTransactionModal: '',
  uploadCsvTransactionTypeModal: null,
  isSideBarOpen: false,
  flagList: [],
  refreshFlagData: '',
};

const siteStatusSlice = createSlice({
  name: SITE_STATUS,
  initialState,
  reducers: {
    setFlagList(state, flagList: PayloadAction<IFlagList[]>) {
      const stateClone = cloneDeep(state);
      stateClone.flagList = flagList.payload;
      stateClone.refreshFlagData = Math.random().toString();
      return stateClone;
    },
    setHoveredTeamId(state, teamId: PayloadAction<string>) {
      const stateClone = cloneDeep(state);
      stateClone.hoveredTeamId = teamId.payload;
      return stateClone;
    },
    setRefreshFlag(state, value: PayloadAction<string>) {
      const stateClone = cloneDeep(state);
      stateClone.refreshFlagData = value.payload;
      return stateClone;
    },
    setCurrentProject(state, currentProjectValue: PayloadAction<string>) {
      const stateClone = cloneDeep(state);
      stateClone.currentProject = currentProjectValue.payload;
      return stateClone;
    },
    setSelectedFlag(state, selectedFlag: PayloadAction<string>) {
      const stateClone = cloneDeep(state);
      stateClone.selectedFlag = selectedFlag.payload;
      return stateClone;
    },
    setSelectedTransactionModal(state, selectedTransactionModal: PayloadAction<string>) {
      const stateClone = cloneDeep(state);
      stateClone.selectedTransactionModal = selectedTransactionModal.payload;
      return stateClone;
    },
    setSelectedOrganisation(state, orgId: PayloadAction<string>) {
      const stateClone = cloneDeep(state);
      stateClone.currentOrganisation = orgId.payload;
      return stateClone;
    },
    setUploadCsvSelectedTransactionTypeModal(
      state,
      uploadCsvTransactionType: PayloadAction<UploadCsvTransactionType | null>,
    ) {
      const stateClone = cloneDeep(state);
      stateClone.uploadCsvTransactionTypeModal = uploadCsvTransactionType.payload;
      return stateClone;
    },
    setIsSideBarOpen(state, isSideBarOpen: PayloadAction<boolean>) {
      const stateClone = cloneDeep(state);
      stateClone.isSideBarOpen = isSideBarOpen.payload;
      return stateClone;
    },
  },
  extraReducers: {},
});

export const {
  setCurrentProject,
  setIsSideBarOpen,
  setSelectedFlag,
  setSelectedTransactionModal,
  setFlagList,
  setHoveredTeamId,
  setRefreshFlag,
  setUploadCsvSelectedTransactionTypeModal,
  setSelectedOrganisation,
} = siteStatusSlice.actions;

export default siteStatusSlice.reducer;
