import { cacheImages } from '../../util/SiteHelper';
import { IFlagList } from './type';

export const transformData = (data: any): IFlagList[] => {
  if (!data) {
    return [];
  }

  const flagImages = data.data.map((item: any) => item.Image);
  cacheImages(flagImages);

  return data.data.map((item: any) => ({
    id: item.id,
    teamName: item.TeamNames,
    shortName: item.ShortName,
    image: item.Image,
    listSpotsAvailaible: item.ListSpotsAvailable,
  }));
};

export const handleRightMovement = () => {
  const scrollElement = document.getElementById('flaglist');

  if (scrollElement) {
    if (scrollElement.scrollLeft < scrollElement.scrollWidth) {
      scrollElement.scroll({
        left: scrollElement.scrollLeft + 50,
        behavior: 'smooth',
      });
    }
  }
};

export const handleLeftMovement = () => {
  const scrollElement = document.getElementById('flaglist');

  if (scrollElement) {
    if (scrollElement.scrollLeft < scrollElement.scrollWidth) {
      scrollElement.scroll({
        left: scrollElement.scrollLeft - 50,
        behavior: 'smooth',
      });
    }
  }
};
