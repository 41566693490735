import { usePlanningTradeOfferHook } from './hook';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { PrimaryButton } from '../../common/component/Button';
import CompletedTrade from '../transaction/component/CompletedTrade';
import PlanningTradeList from './component/PlanningTradeList';
import DeleteModal from '../../common/component/DeleteModal';
import ConfirmModal from '../../common/component/ConfirmModal';
import EmailTradeOfferModal from './component/EmailTradeOfferModal';
import { PlanningTradeOfferType, TradeStatus } from './type';
import { filterTradeOfferList } from './util';
import { TradeStatusOption } from '../../constant/OptionConstant';

const TradeOfferList = () => {
  const {
    tradeOfferList,
    deleteTradeOfferLoading,
    openTransationModal,
    refreshData,
    setOpenTransactionModal,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    deleteTradeSubmit,
    isLoading,
    setShowAcceptConfirmationModal,
    showAcceptConfirmationModal,
    acceptTradeSubmit,
    confirmTradeOfferLoading,
    emailTrade,
    setEmailTrade,
    currentProject,
    selectedTradeStatus,
    setSelectedTradeStatus,
    showDeclineOfferConfirmation,
    setShowDeclineOfferConfirmation,
    declineTradeLoading,
    declineTradeOfferSubmit,
  } = usePlanningTradeOfferHook();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container alignItems={'center'} justifyContent={'end'} spacing={'30px'}>
          {openTransationModal && (
            <CompletedTrade
              refreshTrade={refreshData}
              isTradeAnalysis
              editTrade={openTransationModal as PlanningTradeOfferType}
              closeTransactionModal={() => setOpenTransactionModal(false)}
            />
          )}
          <Grid item xs={2}>
            <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size='small'>
              <InputLabel id='demo-select-small-label'>Trade Status</InputLabel>
              <Select
                value={selectedTradeStatus}
                label={'Trade Status'}
                fullWidth
                size={'small'}
                onChange={(event: SelectChangeEvent) =>
                  setSelectedTradeStatus(event.target.value as TradeStatus)
                }
              >
                {TradeStatusOption.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <PrimaryButton
              style={{ borderRadius: 5 }}
              onClick={() => setOpenTransactionModal(true)}
              title='Add An Offer'
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={'20px'}>
        <PlanningTradeList
          declineOffer={setShowDeclineOfferConfirmation}
          isLoading={isLoading}
          acceptOffer={setShowAcceptConfirmationModal}
          list={filterTradeOfferList(tradeOfferList, selectedTradeStatus)}
          deleteOffer={setShowDeleteConfirmation}
          emailTrade={setEmailTrade}
          editTrade={setOpenTransactionModal}
        />
      </Grid>
      {emailTrade && (
        <EmailTradeOfferModal
          projectId={currentProject}
          handleClose={() => setEmailTrade(null)}
          tradeOffer={emailTrade}
        />
      )}
      {showDeleteConfirmation && (
        <DeleteModal
          description={`Are you sure you want to delete the offer`}
          modalTitle='Delete Offer'
          handleClose={() => setShowDeleteConfirmation(null)}
          handleSubmit={deleteTradeSubmit}
          isLoading={deleteTradeOfferLoading}
        />
      )}
      {showDeclineOfferConfirmation && (
        <DeleteModal
          description={`Are you sure, you want to decline this offer?`}
          modalTitle='Decline Offer'
          handleClose={() => setShowDeclineOfferConfirmation(null)}
          handleSubmit={declineTradeOfferSubmit}
          submitButtonLabel='Decline'
          isLoading={declineTradeLoading}
        />
      )}
      {showAcceptConfirmationModal && (
        <ConfirmModal
          description={`Are you sure you want to Accept the trade`}
          modalTitle='Accept Trade'
          handleClose={() => setShowAcceptConfirmationModal(null)}
          handleSubmit={acceptTradeSubmit}
          isLoading={confirmTradeOfferLoading}
        />
      )}
    </Grid>
  );
};

export default TradeOfferList;
