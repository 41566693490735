import React from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerInitialValue, registerValidationSchema } from './util';
import { LoginContainer, LoginFormContainer } from '../login/style';
import CustomTextInput from '../../common/fields/reacthookfield/CustomTextInput';
import CustomSelect from '../../common/fields/reacthookfield/CustomSelect';
import { PrimaryButton } from '../../common/component/Button';
import { useRegister } from './hook';
import { PermissionOption } from '../../constant/OptionConstant';

const Register = () => {
  const { teamList, registerUser, isLoading } = useRegister();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: registerInitialValue,
    resolver: yupResolver(registerValidationSchema),
  });

  return (
    <LoginContainer container minHeight={'100vh'} justifyContent={'center'} alignItems={'center'}>
      <LoginFormContainer item xs={10} sm={6} md={4}>
        <form onSubmit={handleSubmit(registerUser)}>
          <Grid container spacing={'20px'}>
            <Grid item xs={12}>
              <CustomTextInput
                name={'firstName'}
                register={register}
                isMandatory
                errors={errors}
                fullWidth
                variant='outlined'
                label={'First Name'}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextInput
                name={'lastName'}
                register={register}
                errors={errors}
                isMandatory
                fullWidth
                variant='outlined'
                label={'Last Name'}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextInput
                name={'email'}
                register={register}
                errors={errors}
                isMandatory
                fullWidth
                variant='outlined'
                label={'Email'}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomSelect
                id={'teamId'}
                name={'teamId'}
                register={register}
                options={teamList}
                size='small'
                errors={errors}
                fullWidth
                variant='outlined'
                label={'Team'}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomSelect
                id={'permission'}
                name={'permission'}
                register={register}
                options={PermissionOption}
                size='small'
                errors={errors}
                fullWidth
                variant='outlined'
                label={'Permission'}
              />
            </Grid>
            <Grid item xs={12}>
              <PrimaryButton fullWidth isLoading={isLoading} type='submit' title={'Register'} />
            </Grid>
          </Grid>
        </form>
      </LoginFormContainer>
    </LoginContainer>
  );
};

export default Register;
