import { Box, Chip, Grid } from '@mui/material';
import { Header1, Header2 } from '../../../common/component/Text';
import { PlayerStyledTagNumber, StyledList } from '../style';
import { TeamFlagOptions } from '../../../constant/OptionConstant';
import { PlayerInfoType } from '../type';
import { differenceInCalendarYears, format } from 'date-fns';
import { capitalize } from 'lodash';
import { TEXT_COLOR_THEME } from '../../../theme/color';

type PlayerInfoSectionProps = {
  bioDetails: PlayerInfoType;
};
const PlayerInfoSection = ({ bioDetails }: PlayerInfoSectionProps) => {
  const currentTeam =
    bioDetails.currentRosterAllocation?.teamId &&
    TeamFlagOptions.find((item) => item.id === bioDetails.currentRosterAllocation.teamId);
  return (
    <Grid container p={'40px'}>
      <Grid item xs={12}>
        <Grid container alignItems={'center'}>
          <Grid item xs={12}>
            <Grid container columnSpacing={'100px'}>
              <Grid item xs={4}>
                <PlayerStyledTagNumber mb={'20px'}>{bioDetails.id}</PlayerStyledTagNumber>
                <Header1
                  style={{
                    fontSize: 80,
                    lineHeight: '72px',
                    wordBreak: 'break-all',
                    color: TEXT_COLOR_THEME.light,
                  }}
                >
                  {bioDetails.firstName}
                </Header1>
                <Header1
                  style={{
                    fontSize: 80,
                    lineHeight: '72px',
                    color: TEXT_COLOR_THEME.light,
                  }}
                >
                  {bioDetails.lastName}
                </Header1>
              </Grid>
              <Grid item xs={8}>
                <Grid container>
                  <Grid item xs={4} mt={'40px'}>
                    <Header2
                      style={{
                        color: TEXT_COLOR_THEME.light,
                        fontSize: '24px',
                        fontWeight: 'bold',
                      }}
                    >
                      BIO DETAILS
                    </Header2>
                    <ul
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        columnGap: '50px',
                        rowGap: '10px',
                        paddingInlineStart: 20,
                      }}
                    >
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>
                          Age:{' '}
                          {differenceInCalendarYears(new Date(), new Date(bioDetails.dateOfBirth))}{' '}
                        </Header2>
                      </StyledList>
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>
                          Date Of Birth: {format(new Date(bioDetails.dateOfBirth), 'dd MMM, yyyy')}
                        </Header2>
                      </StyledList>
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>
                          Height: {bioDetails.height}cm
                        </Header2>
                      </StyledList>
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>
                          Prefered Foot: {capitalize(bioDetails.preferredFoot)}
                        </Header2>
                      </StyledList>
                      <StyledList>
                        <Header2
                          fontcolor={TEXT_COLOR_THEME.light}
                          display={'flex'}
                          alignItems={'center'}
                          gap={'10px'}
                        >
                          Previous Club: {bioDetails.juniorTeam.split('/').join(' / ') || '-'}
                        </Header2>
                      </StyledList>
                    </ul>
                  </Grid>
                  <Grid item xs={4} mt={'40px'}>
                    <Header2
                      style={{
                        color: TEXT_COLOR_THEME.light,
                        fontSize: '24px',
                        fontWeight: 'bold',
                      }}
                    >
                      CONTRACT DETAILS
                    </Header2>
                    <ul
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        columnGap: '50px',
                        rowGap: '10px',
                        paddingInlineStart: 20,
                      }}
                    >
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>
                          Status:{' '}
                          <span
                            style={{
                              fontWeight: 'bold',
                              color: bioDetails.currentAflActive
                                ? TEXT_COLOR_THEME.positive
                                : TEXT_COLOR_THEME.error,
                            }}
                          >
                            {bioDetails.currentAflActive ? 'Active' : 'InActive'}
                          </span>
                        </Header2>
                      </StyledList>
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>
                          Current Club:{' '}
                          {currentTeam ? (
                            <>
                              <img
                                src={currentTeam.flagIcon}
                                alt={currentTeam.teamName}
                                height={20}
                                style={{
                                  borderRadius: '50%',
                                  position: 'relative',
                                  top: '5px',
                                  marginRight: '5px',
                                }}
                              />

                              {currentTeam.shortName}
                            </>
                          ) : (
                            '-'
                          )}
                        </Header2>
                      </StyledList>
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>
                          Current Contract:{' '}
                          {bioDetails.currentRosterAllocation.contractTypeDescription || '-'}
                        </Header2>
                      </StyledList>
                      <StyledList>
                        <Header2
                          fontcolor={TEXT_COLOR_THEME.light}
                          display={'flex'}
                          alignItems={'center'}
                          gap={'10px'}
                        >
                          Contract Start: {bioDetails.currentRosterAllocation.startSeason || '-'}
                        </Header2>
                      </StyledList>
                      <StyledList>
                        <Header2
                          fontcolor={TEXT_COLOR_THEME.light}
                          display={'flex'}
                          alignItems={'center'}
                          gap={'10px'}
                        >
                          Contract Expiring: {bioDetails.currentRosterAllocation.endSeason || '-'}
                        </Header2>
                      </StyledList>
                    </ul>
                  </Grid>
                  <Grid item xs={4} mt={'40px'}>
                    <Header2
                      style={{
                        color: TEXT_COLOR_THEME.light,
                        fontSize: '24px',
                        fontWeight: 'bold',
                      }}
                    >
                      Manager Details
                    </Header2>
                    <ul
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        columnGap: '50px',
                        rowGap: '10px',
                        paddingInlineStart: 20,
                      }}
                    >
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>
                          Current Manager: {bioDetails.currentManagementAllocation.agentName || '-'}
                        </Header2>
                      </StyledList>
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>
                          Agency: {bioDetails.currentManagementAllocation.companyName || '-'}{' '}
                        </Header2>
                      </StyledList>
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>
                          Start Date: {bioDetails.currentManagementAllocation.startDate || '-'}
                        </Header2>
                      </StyledList>
                      <StyledList>
                        <Header2
                          gap={'10px'}
                          flexWrap={'wrap'}
                          display={'flex'}
                          fontcolor={TEXT_COLOR_THEME.light}
                        >
                          Group:{' '}
                          {bioDetails?.elegibility?.map((ele) => (
                            <Chip
                              label={ele.elegibilityType}
                              sx={{
                                color: TEXT_COLOR_THEME.primary,
                                fontWeight: 'bold',
                                background: '#efefef',
                                ':hover': {
                                  background: '#efefef',
                                  opacity: 0.5,
                                },
                              }}
                              clickable
                              key={ele.id}
                            />
                          )) || '-'}
                        </Header2>
                      </StyledList>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={7}>
                <Box bgcolor={'#f7e3a2'} height={'30px'} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlayerInfoSection;
