import { FC } from 'react';
import { TransactionImpactType } from '../type';
import { Grid } from '@mui/material';
import { Header2 } from '../../../common/component/Text';
import { TEXT_COLOR_INFO_TYPE } from '../../../theme/color';
import { CheckCircleOutline, CloseOutlined } from '@mui/icons-material';
import { MdInfoOutline } from 'react-icons/md';
import { StyledLogo } from '../../dashboard/style';
import { TeamFlagOptions } from '../../../constant/OptionConstant';

type CompletedTradeImpactProps = {
  tradeImpactInfo: TransactionImpactType;
  team1: number;
  team2: number;
};
const CompletedTradeImpact: FC<CompletedTradeImpactProps> = ({ tradeImpactInfo, team1, team2 }) => {
  const renderHeader = () => {
    if (tradeImpactInfo.summaryValidity === 'Valid') {
      return (
        <>
          <Grid display={'flex'} justifyContent={'center'}>
            <Header2
              display={'flex'}
              alignItems={'center'}
              gap={'10px'}
              fontcolor={TEXT_COLOR_INFO_TYPE.success}
              textsize='18px'
              align='center'
            >
              <CheckCircleOutline style={{ color: TEXT_COLOR_INFO_TYPE.success }} />{' '}
              {tradeImpactInfo.summaryDescription}
            </Header2>
          </Grid>
          <Grid container mt={'10px'} justifyContent={'center'}>
            <Grid item xs={12} mb={'20px'}>
              <Header2 align='center'>Impact</Header2>
            </Grid>
            <Grid item xs={6} textAlign={'center'}>
              <StyledLogo
                src={TeamFlagOptions.find((item) => item.id === team1)!.flagIcon}
                style={{ height: 30, width: 30 }}
                alt={'Flag'}
              />
              <Header2>Points Trading Out</Header2>
              <Header2>{tradeImpactInfo.team1PtsOut}</Header2>
              <Header2>Net Points Result</Header2>
              <Header2
                fontcolor={
                  tradeImpactInfo.team1NetResult > 0
                    ? TEXT_COLOR_INFO_TYPE.success
                    : TEXT_COLOR_INFO_TYPE.error
                }
              >
                {tradeImpactInfo.team1NetResult}
              </Header2>
            </Grid>
            <Grid item xs={6} textAlign={'center'}>
              <StyledLogo
                src={TeamFlagOptions.find((item) => item.id === team2)!.flagIcon}
                style={{ height: 30, width: 30 }}
                alt={'Flag'}
              />
              <Header2>Points Trading Out</Header2>
              <Header2>{tradeImpactInfo.team2PtsOut}</Header2>
              <Header2>Net Points Result</Header2>
              <Header2
                fontcolor={
                  tradeImpactInfo.team2NetResult < 0
                    ? TEXT_COLOR_INFO_TYPE.error
                    : TEXT_COLOR_INFO_TYPE.success
                }
              >
                {tradeImpactInfo.team2NetResult}
              </Header2>
            </Grid>
          </Grid>
        </>
      );
    }

    if (tradeImpactInfo.summaryValidity === 'Warning') {
      return (
        <>
          <Grid>
            <Header2 fontcolor={TEXT_COLOR_INFO_TYPE.info} textsize='18px' align='center'>
              <MdInfoOutline style={{ color: TEXT_COLOR_INFO_TYPE.info }} />{' '}
              {tradeImpactInfo.summaryDescription}
            </Header2>
          </Grid>
          <Grid container mt={'10px'} justifyContent={'center'}>
            <Grid item xs={12} mb={'20px'}>
              <Header2 align='center'>
                {tradeImpactInfo.pickMatchValidity.picksDontMatch.map((item) => (
                  <p key={JSON.stringify(item)}>
                    {item.oldPick} is now {item.newPick}
                  </p>
                ))}
              </Header2>
            </Grid>
            <Grid item xs={12} mb={'20px'}>
              <Header2 align='center'>Impact</Header2>
            </Grid>
            <Grid item xs={6} textAlign={'center'}>
              <Header2>Net Points Result</Header2>
              <Header2
                fontcolor={
                  tradeImpactInfo.team1NetResult > 0
                    ? TEXT_COLOR_INFO_TYPE.success
                    : TEXT_COLOR_INFO_TYPE.error
                }
              >
                {tradeImpactInfo.team1NetResult}
              </Header2>
            </Grid>
            <Grid item xs={6} textAlign={'center'}>
              <Header2>Net Points Result</Header2>
              <Header2
                fontcolor={
                  tradeImpactInfo.team2NetResult < 0
                    ? TEXT_COLOR_INFO_TYPE.error
                    : TEXT_COLOR_INFO_TYPE.success
                }
              >
                {tradeImpactInfo.team2NetResult}
              </Header2>
            </Grid>
          </Grid>
        </>
      );
    }

    if (tradeImpactInfo.summaryValidity === 'Invalid') {
      return (
        <>
          <Grid>
            <Header2
              display={'flex'}
              justifyContent={'center'}
              fontcolor={TEXT_COLOR_INFO_TYPE.error}
              textsize='18px'
              align='center'
              gap={'10px'}
            >
              <CloseOutlined style={{ color: TEXT_COLOR_INFO_TYPE.error }} />{' '}
              {tradeImpactInfo.summaryDescription}
            </Header2>
          </Grid>
          <Grid container mt={'10px'} justifyContent={'center'}>
            {tradeImpactInfo.tradeBackRuleValidity.tradeBackRule === 'Invalid' && (
              <Grid item xs={12} mb={'20px'}>
                <Header2 align='center'>
                  {tradeImpactInfo.tradeBackRuleValidity.cannotTradeBack.map((item) => (
                    <span>{item}, </span>
                  ))}
                </Header2>
              </Grid>
            )}
            {tradeImpactInfo.pickExistsValididty.checkPickExists === 'Invalid' && (
              <Grid item xs={12} mb={'20px'}>
                <Header2>{tradeImpactInfo.pickExistsValididty.description}</Header2>
                <Header2 align='center'>
                  {tradeImpactInfo.pickExistsValididty.picksNotValid.map((item) => (
                    <span>{item}, </span>
                  ))}
                </Header2>
              </Grid>
            )}
          </Grid>
        </>
      );
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {renderHeader()}
      </Grid>
    </Grid>
  );
};

export default CompletedTradeImpact;
