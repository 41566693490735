import { Grid } from '@mui/material';
import DataGridWrapper from '../../../common/component/DataGridWrapper';
import { useAccolade } from '../hook';
import { AccoladeListColumn } from './ListColumn';
import { PrimaryButton } from '../../../common/component/Button';
import AddUpdateAccolade from './AddUpdateAccolade';
import DeleteModal from '../../../common/component/DeleteModal';

type AccoladeProps = {
  playerId: string;
};
const Accolade = ({ playerId }: AccoladeProps) => {
  const {
    data,
    loading,
    openAddModal,
    selectedAccoladeForEdit,
    setOpenAddModal,
    setSelecetedAccoladeForEdit,
    refreshList,
    selectedAccoladeForDelete,
    setSelectedAccoladeForDelete,
    handelAccoladeDelete,
    deleteLoading,
  } = useAccolade(playerId);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent={'flex-end'}>
          <Grid item xs={4}>
            <PrimaryButton onClick={() => setOpenAddModal(true)} title='Create Accolade' />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={'10px'}>
        <DataGridWrapper
          columns={AccoladeListColumn(setSelecetedAccoladeForEdit, setSelectedAccoladeForDelete)}
          rows={data}
          loading={loading}
        />
      </Grid>
      {(openAddModal || selectedAccoladeForEdit) && (
        <AddUpdateAccolade
          handleClose={() => {
            setSelecetedAccoladeForEdit(undefined);
            setOpenAddModal(false);
          }}
          playerId={playerId}
          refreshList={refreshList}
          selectedAccolade={selectedAccoladeForEdit}
        />
      )}
      {selectedAccoladeForDelete && (
        <DeleteModal
          isLoading={deleteLoading}
          description='Are you sure you want to delete?'
          modalTitle='Delete Accolade'
          handleClose={() => setSelectedAccoladeForDelete(undefined)}
          handleSubmit={handelAccoladeDelete}
        />
      )}
    </Grid>
  );
};

export default Accolade;
