import { Box, Typography } from '@mui/material';
import { TEXT_COLOR_INFO_TYPE, TEXT_COLOR_THEME } from '../../../theme/color';

type SummaryInfoCardProps = {
  officialValue: number;
  projectedValue: number;
  summaryInfoYear: string;
  year: string;
};
const SummaryInfoCard = ({
  summaryInfoYear,
  officialValue,
  projectedValue,
  year,
}: SummaryInfoCardProps) => {
  if (summaryInfoYear !== year) {
    return null;
  }
  return (
    <Box
      height={'30px'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={'20px'}
    >
      <Typography color={TEXT_COLOR_THEME.positive}>{officialValue || ''}</Typography>
      <Typography color={TEXT_COLOR_INFO_TYPE.info}>{projectedValue || ''}</Typography>
    </Box>
  );
};

export default SummaryInfoCard;
