/* eslint-disable react-hooks/rules-of-hooks */
import { Formik } from 'formik';
import React, { FC, useEffect } from 'react';
import CustomModal from '../../../common/component/CustomModal';
import { NgaBidMatchInitialValue } from '../util';
import { Grid } from '@mui/material';
import FormikAutocomplete from '../../../common/fields/FormikAutocomplete';
import { useNgaBidMatch } from '../hook';
import VisualVersion from './ngabidmatch/VisualVersion';
import CalculationInfo from './fatherSonInfo/CalculationInfo';

type Props = {
  closeTransactionModal: () => void;
};

const NgaBidMatch: FC<Props> = ({ closeTransactionModal }) => {
  const {
    allPickDataDropdown,
    playerDataDropdown,
    teamDataDropdown,
    isLoading,
    submitData,
    fetchNgaBidLine,
    sumNgaBidLines,
  } = useNgaBidMatch();
  return (
    <>
      <Formik initialValues={NgaBidMatchInitialValue} enableReinitialize onSubmit={submitData}>
        {({ handleSubmit, values }) => {
          useEffect(() => {
            fetchNgaBidLine(values);
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [values.ngaPlayer, values.pickOfBid, values.teamNgaPlayer]);
          return (
            <CustomModal
              saveLoading={isLoading}
              handleSubmit={() => handleSubmit()}
              modalSize={'md'}
              disableButton={!sumNgaBidLines?.validity}
              handleClose={closeTransactionModal}
              modalTitle='NGA Bid Match'
            >
              <Grid container spacing={'10px'}>
                <Grid item xs={sumNgaBidLines?.visualVersion === '2024' ? 6 : 12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormikAutocomplete
                        name='teamNgaPlayer'
                        id='teamNgaPlayer'
                        options={teamDataDropdown}
                        label={'Select Team NGA Player Belongs To'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikAutocomplete
                        name='ngaPlayer'
                        id='ngaPlayer'
                        options={playerDataDropdown}
                        label={'Select NGA Player'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikAutocomplete
                        name='pickOfBid'
                        id='pickOfBid'
                        options={allPickDataDropdown}
                        label={'Select Pick Of Bid2'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {sumNgaBidLines && <VisualVersion transactionInfo={sumNgaBidLines} />}

                      <div></div>
                    </Grid>
                  </Grid>
                </Grid>
                {sumNgaBidLines && sumNgaBidLines?.visualVersion === '2024' && (
                  <Grid item xs={6}>
                    <CalculationInfo draftInfo={sumNgaBidLines} />
                  </Grid>
                )}
              </Grid>
            </CustomModal>
          );
        }}
      </Formik>
    </>
  );
};

export default NgaBidMatch;
