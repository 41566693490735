import { range } from 'lodash';
import { OptionType } from '../common/fields/type';

function stringToColor(string: string) {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')?.[0]?.[0] || ''}${name.split(' ')?.[1]?.[0] || ''}`.toUpperCase(),
  };
};

export const cacheImages = async (srcArray: string[]) => {
  const promises = await srcArray.map((src: string) => {
    return new Promise((resolve, reject) => {
      const img: any = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
    });
  });
  await Promise.all(promises);
};

export const SHOW_REGISTER_BUTTON = true;
export const SHOW_AUTH0_LOGIN = true;

export const getOrderPickBackColor = (value: number) => {
  const hue = ((1 - value) * 120).toString(10);
  return ['hsl(', hue, ',100%,60%)'].join('');
};

export const getInitials = (name: string) => {
  return name
    .split(' ')
    .filter((word) => word.length > 0)
    .map((word) => word[0].toUpperCase())
    .slice(0, 2)
    .join('');
};

export const getYearOptions = (endYear?: number): OptionType[] => {
  const currentYear = endYear || new Date().getFullYear();
  const years = range(2010, currentYear + 1).map((year) => ({
    label: year.toString(),
    value: year.toString(),
  }));

  return years;
};

export const replaceDay = (date: string, day?: number, month?: number) => {
  const newDate = new Date(date);
  day && newDate.setDate(day);
  month && newDate.setMonth(month - 1);
  return newDate;
};

export const generateUniqueId = (): string => {
  return Math.random().toString(36).substr(2, 9); // Simple unique ID generator
};
