import { Grid, IconButton, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { FC } from 'react';
import { CompactTag } from '../../../common/component/Text';
import { TAG_COLOR, TEXT_COLOR_INFO_TYPE, TOOLTIP_COLOR } from '../../../theme/color';
import { styled } from '@mui/material/styles';
import { FlagTooltipType } from '../../header/type';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { AiOutlineSetting } from 'react-icons/ai';

type Props = {
  data?: FlagTooltipType;
  setRosterModalStatus: () => void;
};

export const CustomStyleTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: TOOLTIP_COLOR.background,
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 700,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${TOOLTIP_COLOR.border}`,
    padding: 10,
    zIndex: 9999,
  },
}));

const CustomTooltip: FC<Props> = ({ data, setRosterModalStatus }) => {
  return (
    <Grid
      container
      pt={'25px'}
      flexWrap={'nowrap'}
      spacing={'20px'}
      alignItems={'center'}
      width={'100%'}
    >
      <Grid item>
        <Typography>{data?.teamName || 'Hello'}</Typography>
      </Grid>
      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container columnSpacing={'20px'}>
              <Grid item xs={12} position={'absolute'} right={0} top={0}>
                <IconButton onClick={setRosterModalStatus} title='Edit List Spots' color={'info'}>
                  <AiOutlineSetting />{' '}
                  <Typography ml={'10px'} fontSize={'16px'}>
                    Edit List Spots
                  </Typography>
                </IconButton>
              </Grid>
              <Grid item>
                <Typography>{data?.currentYear}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Box display={'flex'} gap={'5px'} flexWrap={'wrap'}>
                  {data?.currentYearPickDict.map((item) => (
                    <CompactTag
                      gap={'5px'}
                      key={item.pick}
                      display={'flex'}
                      alignItems={'center'}
                      textsize={'12px'}
                      textAlign={'center'}
                      bgcolor={'transparent'}
                      fontcolor={
                        item.pickStatus === 'Used'
                          ? TEXT_COLOR_INFO_TYPE.error
                          : item.clubPickNumber > Number(data.currentYearRosterSpots)
                          ? TAG_COLOR.disabled
                          : TAG_COLOR.success
                      }
                      minWidth={15}
                    >
                      {item.pick}
                    </CompactTag>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box>
                  <Box
                    width={'20%'}
                    position={'absolute'}
                    top={'0px'}
                    display={'flex'}
                    gap={'30px'}
                    alignItems={'center'}
                  >
                    <Typography>Pts</Typography>
                    <Typography>Useable</Typography>
                  </Box>
                  <Box width={'20%'} display={'flex'} gap={'30px'} alignItems={'center'}>
                    <Typography>{data?.currentYearsPts}</Typography>
                    <Typography>{data?.currentYearPtsRemaining}</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={'20px'}>
            <Grid container columnSpacing={'20px'}>
              <Grid item>
                <Typography>{data?.nextYear}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Box display={'flex'} gap={'5px'} flexWrap={'wrap'}>
                  {data?.nextYearPicks.split(',').map((item) => (
                    <CompactTag
                      gap={'5px'}
                      key={item}
                      display={'flex'}
                      alignItems={'center'}
                      textsize={'12px'}
                      textAlign={'center'}
                      bgcolor={'transparent'}
                      fontcolor={TAG_COLOR.disabled}
                    >
                      {item}
                    </CompactTag>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box>
                  <Box
                    width={'20%'}
                    position={'absolute'}
                    top={'0px'}
                    display={'flex'}
                    gap={'30px'}
                    alignItems={'center'}
                    visibility={'hidden'}
                  >
                    <Typography>Pts</Typography>
                    <Typography>Useable</Typography>
                  </Box>
                  <Box width={'20%'} display={'flex'} gap={'30px'} alignItems={'center'}>
                    <Typography>{data?.nextYersPts}</Typography>
                    <Typography>{data?.nextYearPtsRemaining}</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box width={'150px'} pl={'30px'} borderLeft={'1px grey solid'}>
          <Typography textAlign={'center'}>List Spots Total</Typography>
          <Typography textAlign={'center'}>{data?.currentYearRosterSpots}</Typography>
          <Typography textAlign={'center'}>List Spots Availaible</Typography>
          <Typography textAlign={'center'}>{data?.currentYearPickRemaining}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CustomTooltip;
