import { object, string } from 'yup';
import { OptionType } from '../../../../common/fields/type';

export const transformUploadCsvOption = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }

  return data.map(
    (item: any) =>
      ({
        label: item,
        value: item,
      } as OptionType),
  );
};

export const uploadCsvValidation = object().shape({
  csv: string().required('Please select csv file'),
});
