import { Box, Typography } from '@mui/material';
import React from 'react';
import { BG_COLOR, TEXT_COLOR_THEME } from '../../../theme/color';
import { AddOutlined } from '@mui/icons-material';

type RowHeaderProps = {
  onAddNoteClick?: () => void;
  onEditNoteClick?: () => void;
  title: string;
};
const RowHeader = ({ onAddNoteClick, onEditNoteClick, title }: RowHeaderProps) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      bgcolor={BG_COLOR.white}
      px={'20px'}
      py={'2px'}
      borderRadius={'0px'}
      height={'27px'}
      width={'200px'}
      pt={'8px'}
      justifyContent={'space-between'}
    >
      <Box display={'flex'} gap={'5px'} alignItems={'center'}>
        {onAddNoteClick && (
          <AddOutlined style={{ cursor: 'pointer' }} onClick={() => onAddNoteClick()} />
        )}

        <Typography
          sx={{
            cursor: 'pointer',
            ':hover': {
              color: TEXT_COLOR_THEME.link,
            },
          }}
          variant='body2'
          onClick={() => onEditNoteClick && onEditNoteClick()}
        >
          {title}
        </Typography>
      </Box>
      {onEditNoteClick && (
        <Box display={'flex'} gap={'5px'}>
          <Typography
            sx={{
              cursor: 'pointer',
              color: TEXT_COLOR_THEME.link,
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={() => onEditNoteClick()}
            variant='body2'
          >
            Edit
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default RowHeader;
