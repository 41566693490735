import {
  Box,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { RadioOptionType } from './type';
import { Control, Controller } from 'react-hook-form';
import { FieldLabel } from '../../component/Text';
import { TEXT_COLOR_THEME } from '../../../theme/color';

type CustomRadioGroupProps = {
  radioOption: RadioOptionType[];
  control: Control<any, any>;
  name: string;
  label?: string;
  isMandatory?: boolean;
  isClearable?: boolean;
} & RadioGroupProps;
const CustomRadioGroup: FC<CustomRadioGroupProps> = ({
  control,
  radioOption,
  name,
  label,
  isMandatory,
  isClearable,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={`${name}`}
      render={({ field, fieldState: { error } }) => (
        <Box>
          {label && (
            <FieldLabel>
              {label}
              {isMandatory && <sup style={{ color: TEXT_COLOR_THEME.error }}>*</sup>}
            </FieldLabel>
          )}
          <RadioGroup
            value={field.value}
            name={name}
            onChange={(e) => {
              field.onChange(e.target.value);
            }}
            key={field.value}
            {...rest}
          >
            {radioOption.map((item) => (
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={<Radio />}
                label={item.label}
              />
            ))}
          </RadioGroup>
          {isClearable && field.value && (
            <Typography
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={() => {
                field.onChange('');
              }}
              color={TEXT_COLOR_THEME.link}
            >
              Clear Selection
            </Typography>
          )}
          {error && (
            <FormHelperText style={{ color: TEXT_COLOR_THEME.error }}>
              {(error?.message as string) || ''}
            </FormHelperText>
          )}
        </Box>
      )}
    />
  );
};

export default CustomRadioGroup;
