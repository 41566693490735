import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { Header2 } from '../../../common/component/Text';
import CustomModal from '../../../common/component/CustomModal';
import { useDeleteUnusablePick } from '../hook';
import RosterList from '../../project/component/RosterList';

type Props = {
  closeTransactionModal: () => void;
};
const DeleteUnusablePick: FC<Props> = ({ closeTransactionModal }) => {
  const { isLoading, submit, fetchRosterLoading, setRosterAmount, rosterList } =
    useDeleteUnusablePick();
  return (
    <CustomModal
      handleSubmit={submit}
      saveLoading={isLoading}
      modalSize={'md'}
      handleClose={closeTransactionModal}
      modalTitle='Enter Draft Mode'
    >
      <>
        <Grid>
          <Header2>
            You are about to enter draft mode. This will remove any excess club picks from the draft
            order. Confirm the Available List Spots each club has to then proceed
          </Header2>
        </Grid>
        <Grid mt={'20px'}>
          <RosterList
            isLoading={fetchRosterLoading}
            rosterList={rosterList}
            setRosterAmount={setRosterAmount}
          />
        </Grid>
      </>
    </CustomModal>
  );
};

export default DeleteUnusablePick;
