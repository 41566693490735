import { FieldInfoType, FieldsType } from '../type';
import { Grid } from '@mui/material';
import CustomTextInput from '../../../common/fields/reacthookfield/CustomTextInput';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';
import CustomeDatepicker from '../../../common/fields/reacthookfield/CustomeDatepicker';
import CustomSlider from '../../../common/fields/reacthookfield/CustomSlider';

type TimelineFormProps = {
  fieldInfo: FieldsType;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  control: Control<any>;
};
const TimelineForm = ({ fieldInfo, register, errors, control }: TimelineFormProps) => {
  const renderField = (field: FieldInfoType, index: number) => {
    if (field.fieldType === 'DateInputType') {
      return (
        <CustomeDatepicker
          fieldLabel={field.fieldLabel}
          control={control}
          isMandatory={!field.nullable}
          tooltipInfo={field.fieldTooltip}
          {...register(field.fieldId, {
            required: {
              message: `Please select ${field.fieldName}`,
              value: !field.nullable,
            },
          })}
          name={field.fieldId}
        />
      );
    }
    if (field.fieldType === 'SliderInputType') {
      return (
        <CustomSlider
          label={field.fieldLabel}
          control={control}
          isMandatory={!field.nullable}
          {...register(field.fieldId, {
            required: {
              message: `Please select ${field.fieldName}`,
              value: !field.nullable,
            },
          })}
          min={field.minValue}
          max={field.maxValue}
          sliderMeasurment=''
          tooltipInfo={field.fieldTooltip}
        />
      );
    }
    if (field.fieldType === 'IntegerInputType') {
      return (
        <CustomTextInput
          register={register}
          isMandatory
          errors={errors}
          fullWidth
          variant='outlined'
          tooltipInfo={field.fieldTooltip}
          label={field.fieldName}
          type={'number'}
          {...register(field.fieldId, {
            required: {
              message: `Please enter ${field.fieldName}`,
              value: !field.nullable,
            },

            min: {
              message: `Number should be more then ${field.minValue}`,
              value: field.minValue,
            },
            max: {
              message: `Number should be less then ${field.maxValue}`,
              value: field.maxValue,
            },
          })}
        />
      );
    }
    if (field.fieldType === 'StringInputType') {
      return (
        <CustomTextInput
          register={register}
          isMandatory
          errors={errors}
          fullWidth
          variant='outlined'
          tooltipInfo={field.fieldTooltip}
          label={field.fieldName}
          {...register(field.fieldId, {
            value: field.value,
            required: {
              message: `Please enter ${field.fieldName}`,
              value: !field.nullable,
            },
            minLength: {
              message: `Length should be more then ${field.minLength}`,
              value: field.minLength,
            },
            maxLength: {
              message: `Length should be less then ${field.maxLength}`,
              value: field.maxLength,
            },
          })}
        />
      );
    }
  };
  return (
    <Grid container>
      {fieldInfo.fields.map((field, index) => (
        <Grid mt={'20px'} key={field.fieldId} item xs={12}>
          {renderField(field, index)}
        </Grid>
      ))}
    </Grid>
  );
};

export default TimelineForm;
