import { DeficitImpactDatagridType } from '../../type';
import DataGridWrapper from '../../../../common/component/DataGridWrapper';
import { deficitImpactColumn } from './ListColumn';

type DeficitImpactDataGridProps = {
  data: DeficitImpactDatagridType[];
};
const DeficitImpactDataGrid = ({ data }: DeficitImpactDataGridProps) => {
  return <DataGridWrapper rows={data} columns={deficitImpactColumn()} />;
};

export default DeficitImpactDataGrid;
