import { yupResolver } from '@hookform/resolvers/yup';
import {
  useDeleteCsvLastRowMutation,
  useGetCsvUploadListQuery,
  useLazyGetCsvPayloadQuery,
} from '../../store/service/uploadcsvservice';
import {
  transformUploadCsvOption,
  uploadCsvValidation,
} from '../transaction/component/uploadcsvmodal/util';
import { useForm, useWatch } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { transformCsvList } from './util';
import { toast } from 'react-toastify';

export const useCsvView = () => {
  const { data, isLoading } = useGetCsvUploadListQuery('');
  const [getCsvFileData, { data: csvFileData }] = useLazyGetCsvPayloadQuery();
  const [deleteLastRow, { isLoading: deleteCsvLastRowLoading }] = useDeleteCsvLastRowMutation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      csv: '',
    },
    resolver: yupResolver(uploadCsvValidation),
  });

  const selectedCsvOption = useWatch({
    control,
    name: 'csv',
  });

  useEffect(() => {
    selectedCsvOption && fetchCsvData();
  }, [selectedCsvOption]);

  const fetchCsvData = () => {
    getCsvFileData(selectedCsvOption);
  };

  const fileListOption = useMemo(() => transformUploadCsvOption(data), [data]);

  const csvData = useMemo(() => transformCsvList(csvFileData), [csvFileData]);

  const handleDeleteLastRow = () => {
    deleteLastRow({
      fileName: selectedCsvOption,
    }).then(() => {
      toast.success('Last row deleted Successfully');
      fetchCsvData();
      setShowDeleteModal(false);
    });
  };
  return {
    data,
    isLoading,
    handleSubmit,
    register,
    selectedCsvOption,
    errors,
    fileListOption,
    csvListData: csvData,
    showDeleteModal,
    handleDeleteLastRow,
    setShowDeleteModal,
    deleteCsvLastRowLoading,
  };
};