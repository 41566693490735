import CustomModal from '../../../common/component/CustomModal';
import { Grid } from '@mui/material';
import { useAddUpdatePlayerManager } from '../hook';
import CustomeDatepicker from '../../../common/fields/reacthookfield/CustomeDatepicker';
import CustomSelect from '../../../common/fields/reacthookfield/CustomSelect';
import { PlayerManagerType } from '../type';
import CustomCheckbox from '../../../common/fields/reacthookfield/CustomCheckbox';

type AddUpdateAccoladeProps = {
  handleClose: () => void;
  selectedManager?: PlayerManagerType;
  playerId: string;
  refreshList: () => void;
};
const AddUpdatePlayerManager = ({
  handleClose,
  playerId,
  refreshList,
  selectedManager,
}: AddUpdateAccoladeProps) => {
  const {
    agentOptions,
    agentId,
    control,
    isLoading,
    updateLoading,
    errors,
    handleSubmit,
    onSubmit,
    register,
    currentlyWorking,
  } = useAddUpdatePlayerManager(playerId, refreshList, selectedManager);
  return (
    <CustomModal
      modalTitle={selectedManager ? 'Update Allocation' : 'Add Allocation'}
      modalSize='xs'
      handleSubmit={handleSubmit(onSubmit)}
      handleClose={handleClose}
      saveLoading={isLoading || updateLoading}
    >
      <Grid container spacing={'20px'}>
        <Grid item xs={12}>
          <CustomSelect
            options={agentOptions}
            id='agentId'
            name={'agentId'}
            register={register}
            isMandatory
            errors={errors}
            value={agentId}
            fullWidth
            variant='outlined'
            label={'Agent'}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={'20px'}>
            <Grid item xs={6}>
              <CustomeDatepicker
                isMandatory
                control={control}
                fieldLabel={'Start Date'}
                name={'startDate'}
                views={['year']}
                defaultDay={1}
                defaultMonth={1}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomeDatepicker
                isMandatory
                control={control}
                fieldLabel={'End Date'}
                name={'endDate'}
                views={['year']}
                defaultDay={31}
                defaultMonth={12}
                disabled={currentlyWorking}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomCheckbox
                key={currentlyWorking}
                control={control}
                label='Currently working'
                name='currentlyWorking'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default AddUpdatePlayerManager;
