export interface IUserData {
  loading: boolean;
  userInfo: IUserInfo | null;
  token: string;
  error: string | null;
  success: boolean;
}

export interface IUserInfo {
  userId: number | null;
  username: string;
  teamId: number | null;
  email: string;
  isActive: boolean;
  isStaff: boolean;
  isSuperuser: boolean;
  uui: string;
  groups: string[];
  auth0Id: string;
  organisation: UserOrganisationType[];
}

export type UserOrganisationType = {
  organisationId: number;
  organisationTitle: string;
  organisationSportingCode: string;
  organisationDefaultTeamId: string;
  organisationDefaultTeamName: string;
  organisationDefaultTeamShortName: string;
  created: string;
  updated: string;
  invitationAccepted: string;
  roles: string;
};

export interface ILoginUserData {
  email: string;
  password: string;
}

export const AUTH = 'AUTH';
