import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/store';
import { BrowserRouter } from 'react-router-dom';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react';
import Auth0ProviderWithHistory from './route/AuthProviderWIthHistory';
import AuthProvider from './context/AuthProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

let persistor = persistStore(store);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<h1>Loading</h1>}>
        <Suspense fallback={<h1>.....</h1>}>
          <Auth0ProviderWithHistory>
            <AuthProvider>
              <App />
            </AuthProvider>
          </Auth0ProviderWithHistory>
        </Suspense>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
