import { Autocomplete, TextField } from '@mui/material';
import { OptionType } from './type';

type SimpleAutocompleteProps = {
  options: OptionType[];
  label: string;
  value: string;
  handleOptionChange: (option: string) => void;
};

const SimpleAutocomplete = ({
  label,
  options,
  value,
  handleOptionChange,
}: SimpleAutocompleteProps) => {
  const getValue = () => {
    return options.find((item) => item.value === value);
  };
  return (
    <Autocomplete
      fullWidth
      size={'small'}
      value={getValue()}
      onChange={(_: any, newValue: any) => {
        handleOptionChange(newValue.value);
      }}
      key={value}
      onInputChange={(_, value, reason) => {
        if (reason === 'clear') {
          handleOptionChange('');
        }
      }}
      options={options}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default SimpleAutocomplete;
