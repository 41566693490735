import { FieldLabel } from '../../component/Text';
import { TEXT_COLOR_THEME } from '../../../theme/color';
import { FormHelperText, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { StyledCustomDateRangePicker } from './Style';
import { Control, Controller } from 'react-hook-form';

type CustomDateRangePickerProps = {
  fieldLabel: string;
  isMandatory?: boolean;
  tooltipInfo?: string;
  name: string;
  control: Control<any, any>;
};
const CustomDateRangePicker = ({
  fieldLabel,
  isMandatory,
  tooltipInfo,
  control,
  name,
}: CustomDateRangePickerProps) => {
  return (
    <StyledCustomDateRangePicker>
      <FieldLabel display={'flex'} alignItems={'center'}>
        {fieldLabel}
        {isMandatory && <sup style={{ color: TEXT_COLOR_THEME.error }}>*</sup>}
        {tooltipInfo && (
          <Tooltip title={tooltipInfo}>
            <InfoOutlined style={{ marginLeft: 10 }} />
          </Tooltip>
        )}
      </FieldLabel>
      <Controller
        control={control}
        name={`${name}`}
        render={({ field, fieldState }) => (
          <>
            <DatePicker
              selectsRange={true}
              startDate={field.value.startDate}
              endDate={field.value.endDate}
              onChange={(update) => {
                field.onChange({
                  startDate: update[0],
                  endDate: update[1],
                });
              }}
              dateFormat={'dd/MM/yyyy'}
              placeholderText='DD/MM/YYYY - DD/MM/YYYY'
              isClearable={true}
            />
            {fieldState.error && (
              <FormHelperText style={{ color: TEXT_COLOR_THEME.error }}>
                {fieldState.error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </StyledCustomDateRangePicker>
  );
};

export default CustomDateRangePicker;
