import { Grid } from '@mui/material';
import { PrimaryButton } from '../../../common/component/Button';
import CompletedTrade from '../../transaction/component/CompletedTrade';
import { usePlanningTradeOfferHook } from '../hook';
import PlanningTradeCard from './PlanningTradeCard';
import DeleteModal from '../../../common/component/DeleteModal';
import LoadingWrapper from '../../../common/component/LoadingWrapper';
import ConfirmModal from '../../../common/component/ConfirmModal';

const PlanningTradeOffer = () => {
  const {
    refreshData,
    tradeOfferList,
    openTransationModal,
    setOpenTransactionModal,
    isLoading,
    deleteTradeOfferLoading,
    deleteTradeSubmit,
    setShowDeleteConfirmation,
    showDeleteConfirmation,
    setShowAcceptConfirmationModal,
    showAcceptConfirmationModal,
    acceptTradeSubmit,
    confirmTradeOfferLoading,
  } = usePlanningTradeOfferHook();
  return (
    <Grid container>
      <Grid item xs={12} pt={'15px'}>
        <PrimaryButton onClick={() => setOpenTransactionModal(true)} title='Add an offer' />
      </Grid>
      {openTransationModal && (
        <CompletedTrade
          refreshTrade={refreshData}
          isTradeAnalysis
          closeTransactionModal={() => setOpenTransactionModal(false)}
        />
      )}
      <Grid item xs={12} mt={'50px'}>
        <Grid container spacing={'30px'}>
          <LoadingWrapper isLoading={isLoading}>
            {tradeOfferList.map((item) => (
              <Grid key={item.id} item xs={12}>
                <PlanningTradeCard
                  onDeleteOffer={() => setShowDeleteConfirmation(item)}
                  planningTrade={item}
                  onAcceptOffer={() => setShowAcceptConfirmationModal(item)}
                />
              </Grid>
            ))}
          </LoadingWrapper>
        </Grid>
      </Grid>
      {showDeleteConfirmation && (
        <DeleteModal
          description={`Are you sure you want to delete the offer`}
          modalTitle='Delete Offer'
          handleClose={() => setShowDeleteConfirmation(null)}
          handleSubmit={deleteTradeSubmit}
          isLoading={deleteTradeOfferLoading}
        />
      )}
      {showAcceptConfirmationModal && (
        <ConfirmModal
          description={`Are you sure you want to Accept the offer`}
          modalTitle='Accept Offer'
          handleClose={() => setShowAcceptConfirmationModal(null)}
          handleSubmit={acceptTradeSubmit}
          isLoading={confirmTradeOfferLoading}
        />
      )}
    </Grid>
  );
};

export default PlanningTradeOffer;
