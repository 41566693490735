const primaryColor = '#7D003B';
const LIGHT_DARK_COLOR = '#dee4e7';

export const BG_COLOR = {
  lightDark: LIGHT_DARK_COLOR,
  white: '#fff',
  lightWhite: '#ccc',
  white200: '#efefef',
};
export const TEXT_COLOR_THEME = {
  light: '#fff',
  primary: primaryColor,
  error: '#d32f2f',
  dark: '#47546D',
  link: '#1976d2',
  positive: '#189a18',
};

export const BUTTON_COLOR_THEME = {
  primary: primaryColor,
  light: '#fff',
  danger: '#d32f2f',
};

export const LOGIN_COLOR_THEME = {
  primary: primaryColor,
  secondary: 'rgba(245,245,245,1)',
  light: '#fff',
  dark: '#000',
};

export const SIDEBAR_COLOR = {
  menuBackground: '#fff',
  isActiveOptionBackground: primaryColor,
  inActiveOptionBackground: '#fff',
  isActiveShadow: 'rgba(255, 217, 84, 0.56)',
  isActiveTextCOlor: '#fff',
  inActiveTextColor: '#97A6BA',
};

export const FLAG_OPTION_COLOR = {
  activeBorder: 'gray',
  activeShadowColor: primaryColor,
};
export const HEADER_COLOR = {
  background: '#FFFFFF',
};

export const DATAGRID_COLOR = {
  headerBackground: '#efefef',
  headerColor: '#27303F',
};
export const CARD_COLOR = {
  primary: '#FFFFFF',
};

export const TOOLTIP_COLOR = {
  background: '#fbfbfb',
  border: '#2564BF',
  textcolor: '#2564BF',
};

export const DRAFT_BOARD_TAG_COLOR = {
  mid: '#ce5a57',
  wing: '#ce5a57',
  keyDef: '#444c5c',
  genDef: '#444c5c',
  keFwd: '#e1b16a',
  genFwd: '#e1b16a',
  ruck: '#78A5A3',
};
export const BACKGROUND_COLOR = '#E5E5E5';

export const ORDER_LIST_COLOR = {
  lessThan10: '#93c47d',
  bet1020: '#d9e8d3',
  bet2030: '#ffe599',
  bet3040: '#f1c232',
  gret40: '#ea9999',
  default: '#efefef',
};

export const STYLED_SELECT_COLOR = {
  background: '#fff',
  primary: primaryColor,
};

export const COLOR_DIFF_COLOR = {
  negative: 'red',
  greaterThen100: 'green',
  between: 'orange',
};

export const TAG_COLOR = {
  success: '#189a18',
  player: '#ce5a57',
  disabled: '#a9a9a9',
};

export const DATAGRID_SELECT_COLUMN_COLOR = {
  primary: '#ffdee9',
  secondary: '#ff',
  success: '#99FF33',
};

export const ORDER_COLOR_LIST = [
  '#FF7070',
  '#FF8C7A',
  '#FFA988',
  '#FFD57A',
  '#FFF57A',
  '#D1FF7A',
  '#A4FF8C',
  '#7AFF9D',
  '#66f47c',
];
export const SLIDER_COLOR = {
  primary: primaryColor,
};

export const TEXT_COLOR_INFO_TYPE = {
  success: 'green',
  info: 'orange',
  error: 'red',
  neutral: 'grey',
};
