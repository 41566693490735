import { FC } from 'react';
import { StyledCard } from '../../../common/component/Card';
import { Grid } from '@mui/material';
import DataGridWrapper from '../../../common/component/DataGridWrapper';
import { PlanningTradeOfferType } from '../type';
import { tradeListColumn } from './ListColumn';

type PlanningTradeListProps = {
  list: PlanningTradeOfferType[];
  isLoading: boolean;
  acceptOffer: (tradeOffer: PlanningTradeOfferType) => void;
  deleteOffer: (tradeOffer: PlanningTradeOfferType) => void;
  emailTrade: (tradeOffer: PlanningTradeOfferType) => void;
  editTrade: (tradeOffer: PlanningTradeOfferType) => void;
  declineOffer: (tradeOffer: PlanningTradeOfferType) => void;
};
const PlanningTradeList: FC<PlanningTradeListProps> = ({
  list,
  isLoading,
  acceptOffer,
  deleteOffer,
  emailTrade,
  editTrade,
  declineOffer,
}) => {
  return (
    <StyledCard container>
      <Grid item xs={12} mt={'10px'}>
        <DataGridWrapper
          columns={tradeListColumn(acceptOffer, declineOffer, editTrade, deleteOffer, emailTrade)}
          loading={isLoading}
          rowHeight={80}
          rows={list || []}
        />
      </Grid>
    </StyledCard>
  );
};

export default PlanningTradeList;
