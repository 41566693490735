import { toast } from 'react-toastify';
import { PlanningTradeOfferType, TradeStatus, TradeTransactionEmailType } from './type';

export const transformTradeData = (data: any): PlanningTradeOfferType[] => {
  if (!data) {
    return [];
  }

  return data.map(
    (item: any, index: number) =>
      ({
        index: index + 1,
        id: item.id,
        notes: item.notes,
        payload: {
          player2: item.payload.player2,
          teamid1: item.payload.teamid1,
          teamid2: item.payload.teamid2,
          pick1: item.payload.pickid1,
          pick2: item.payload.pickid2,
          player1: item.payload.player1,
        },
        pickExistsValidity: {
          checkPickExists: item.pick_exists_valididty.check_pick_exists,
          description: item.pick_exists_valididty.description,
          picksNotValid: item.pick_exists_valididty.picks_not_valid,
        },
        pickMatchValidity: {
          checkPickMatch: item.pick_match_validity.check_picks_match,
          description: item.pick_match_validity.description,
          picksNotValid: item.pick_match_validity.picks_not_valid,
        },
        summaryDescription: item.summary_description,
        summaryValidity: item.summary_validity,
        team1NetResult: item.team1_netresult,
        team1Name: item.team1name,
        team1PicksOut: item.team1picksout,
        team1Playersout: item.team1playersout,
        team1PtsOut: item.team1_pts_out,
        team2Name: item.team2name,
        team2NetResult: item.team2_netresult,
        team2PicksOut: item.team2picksout,
        team2PlayersOut: item.team2playersout,
        team2PtsOut: item.team2_pts_out,
        teamId1: item.teamid1,
        teamId2: item.teamid2,
        tradeBackRuleValidity: {
          cannotTradeBack: item.trade_back_rule_validity.cannot_trade_back,
          description: item.trade_back_rule_validity.description,
          tradeBackRule: item.trade_back_rule_validity.trade_back_rule,
        },
        transactionDescription: item.transaction_description,
        transactionType: item.transaction_type,
        conditionalDetails: item.conditional_details,
        tradeCreated: item.created,
        tradeLastModified: item.last_modified,
        tradeOfferType: item.type,
        tradeStatus: item.status,
      } as PlanningTradeOfferType),
  );
};

export const transformEmailInfo = (data: any): TradeTransactionEmailType | null => {
  if (!data) {
    return null;
  }
  return {
    team1Name: data.team1name,
    team2Name: data.team2name,
    team1PicksOut: data.team1picksout,
    team1PlayersOut: data.team1playersout,
    team2PicksOut: data.team2picksout,
    team2PlayersOut: data.team2playersout,
  };
};

export const copyTradeBody = () => {
  const preElement = document.getElementById('email_body');
  if (!preElement) {
    return;
  }
  const selection: any = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(preElement);
  selection.removeAllRanges();
  selection.addRange(range);
  document.execCommand('copy');
  toast.success('Body copied to clipboard');
};

export const filterTradeOfferList = (list: PlanningTradeOfferType[], status: TradeStatus) => {
  if (status === TradeStatus.ALL) {
    return list;
  }

  return list.filter((item) => item.tradeStatus.toLowerCase() === status.toLowerCase());
};
