import { Grid } from '@mui/material';
import { useAddUpdateAccolade } from '../hook';
import { AccoladeType } from '../type';
import CustomModal from '../../../common/component/CustomModal';
import CustomSelect from '../../../common/fields/reacthookfield/CustomSelect';
import { getYearOptions } from '../../../util/SiteHelper';

type AddUpdateAccoladeProps = {
  handleClose: () => void;
  selectedAccolade?: AccoladeType;
  playerId: string;
  refreshList: () => void;
};
const AddUpdateAccolade = ({
  handleClose,
  selectedAccolade,
  playerId,
  refreshList,
}: AddUpdateAccoladeProps) => {
  const {
    isLoading,
    errors,
    handleSubmit,
    register,
    onSubmit,
    updateLoading,
    accoladeCategory,
    accoladeName,
    categoryOptions,
    nameOptions,
    setValue,
    accoladeSeason,
  } = useAddUpdateAccolade(playerId, refreshList, selectedAccolade);
  return (
    <CustomModal
      modalTitle={selectedAccolade ? 'Update Accolade' : 'Add Accolade'}
      modalSize='xs'
      handleSubmit={handleSubmit(onSubmit)}
      handleClose={handleClose}
      saveLoading={isLoading || updateLoading}
    >
      <Grid container>
        <Grid item xs={12}>
          <CustomSelect
            options={getYearOptions()}
            id='season'
            name={'season'}
            register={register}
            isMandatory
            errors={errors}
            fullWidth
            value={accoladeSeason}
            variant='outlined'
            label={'Season'}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomSelect
            options={categoryOptions || []}
            id='accoladeCategory'
            name={'accoladeCategory'}
            register={register}
            isMandatory
            errors={errors}
            onChange={(value) => {
              setValue('accoladeCategory', value.target.value as string);
              setValue('accoladeName', '');
            }}
            value={accoladeCategory}
            fullWidth
            variant='outlined'
            label={'Category'}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomSelect
            id='accoladeName'
            options={nameOptions || []}
            name={'accoladeName'}
            register={register}
            isMandatory
            value={accoladeName}
            errors={errors}
            fullWidth
            variant='outlined'
            label={'Name'}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default AddUpdateAccolade;
