import { Formik } from 'formik';
import React, { FC } from 'react';
import CustomModal from '../../../common/component/CustomModal';
import { ManualPickMoveInitialValue } from '../util';
import { Grid } from '@mui/material';
import FormikTextInput from '../../../common/fields/FormikTextInput';
import FormikAutocomplete from '../../../common/fields/FormikAutocomplete';
import { useManualPickInsert } from '../hook';
import { InsertInstructionOption } from '../../../constant/OptionConstant';

type Props = {
  closeTransactionModal: () => void;
};

const ManualPickMove: FC<Props> = ({ closeTransactionModal }) => {
  const {
    teamDataDropdown,
    allPickDataDropdown,
    roundDropdown,
    manualMoveLoading,
    submitManualMove,
  } = useManualPickInsert();
  return (
    <>
      <Formik
        initialValues={ManualPickMoveInitialValue}
        enableReinitialize
        onSubmit={submitManualMove}
      >
        {({ handleSubmit }) => (
          <CustomModal
            handleSubmit={() => handleSubmit()}
            saveLoading={manualMoveLoading}
            modalSize={'sm'}
            handleClose={closeTransactionModal}
            modalTitle='Manual Pick Move'
          >
            <Grid container spacing={'10px'}>
              <Grid item xs={12}>
                <FormikAutocomplete
                  name='team'
                  id='team'
                  options={teamDataDropdown}
                  label={'Select Team from Dropdown'}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikAutocomplete
                  name='pickMove'
                  id='pickMove'
                  options={allPickDataDropdown}
                  label={'Select Pick Being Moved'}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikAutocomplete
                  name='round'
                  id='round'
                  options={roundDropdown}
                  label={'Select Round from Dropdown'}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikAutocomplete
                  name='pickDestination'
                  id='pickDestination'
                  options={allPickDataDropdown}
                  label={'Select Pick Destination'}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikAutocomplete
                  name='insertInstruction'
                  id='insertInstruction'
                  options={InsertInstructionOption}
                  label={'Insert Instruction'}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikTextInput
                  name={'enterReason'}
                  id={'enterReason'}
                  multiline
                  fullWidth
                  variant='outlined'
                  rows={4}
                  label={'Enter Reson for Manual Pick Move'}
                />
              </Grid>
            </Grid>
          </CustomModal>
        )}
      </Formik>
    </>
  );
};

export default ManualPickMove;
