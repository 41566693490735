import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Header1 } from '../../../common/component/Text';
import { LogoStyle, SideBarContainer } from '../style';
import { sideMenuOptions } from '../util';
import MenuOption from './MenuOption';
import { AiOutlineLeftCircle, AiOutlineRightCircle } from 'react-icons/ai';
import { TEXT_COLOR_THEME } from '../../../theme/color';
import { getInitials } from '../../../util/SiteHelper';
import { StyledOptionButton } from '../../../common/component/Style';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useGetUserDetail, useGetUserOrganisation } from '../../../store/auth/selector';
import { useCurrentOrganisation } from '../../../store/header/selector';
import CurrentUserOrg from '../../header/component/CurrentUserOrg';
import { setSelectedOrganisation } from '../../../store/header';

type Props = {
  setIsSideBarOpen: (isSideBarOpen: boolean) => void;
  isSideBarOpen: boolean;
  navigate: (to: string) => void;
};
const Sidebar: FC<Props> = ({ isSideBarOpen, setIsSideBarOpen, navigate }) => {
  const location = useLocation();
  const [currentOrgAnchorEl, setCurrentOrgAnchorEl] = useState<HTMLButtonElement | null>(null);
  const organisationOption = useAppSelector(useGetUserOrganisation);
  const currentOrganisation = useAppSelector(useCurrentOrganisation);
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(useGetUserDetail);

  return (
    <SideBarContainer justifyContent={'space-between'} display={'flex'} flexDirection={'column'}>
      <Grid>
        {isSideBarOpen ? (
          <Header1 textAlign={'center'}>
            <LogoStyle>
              <span style={{ fontWeight: 'bold' }}>SMART</span>{' '}
              <span style={{ fontWeight: 300 }}>DRAFT</span>
            </LogoStyle>
          </Header1>
        ) : (
          <Header1 textAlign={'center'}>
            <LogoStyle>
              <img src={'/assets/smallLogo.png'} width={'100%'} alt={'icon'} />{' '}
            </LogoStyle>
          </Header1>
        )}
        <Grid mt={'24px'} container spacing={'7px'}>
          <StyledOptionButton
            buttontype={'text'}
            style={{
              padding: '2px',
              width: '100%',
              borderRadius: 1000,
              minWidth: 53,
            }}
            onClick={({ currentTarget }) => setCurrentOrgAnchorEl(currentTarget)}
            endIcon={isSideBarOpen && <MdKeyboardArrowDown />}
          >
            <Box>
              <Tooltip
                title={
                  organisationOption?.find(
                    (item) => item.organisationId.toString() === currentOrganisation,
                  )?.organisationTitle || 'CURRENT ORG'
                }
              >
                <Typography textOverflow={'ellipsis'} overflow={'hidden'} noWrap>
                  {organisationOption?.find(
                    (item) => item.organisationId.toString() === currentOrganisation,
                  )?.organisationTitle || 'CURRENT ORG'}
                </Typography>
              </Tooltip>
            </Box>
          </StyledOptionButton>
          {sideMenuOptions(!!userInfo?.isStaff)
            .filter((menu) => !menu.hide)
            .map((menu) => (
              <Grid
                borderBottom={`2px #efefef solid`}
                pb='10px'
                mx={isSideBarOpen ? '10px' : ''}
                key={`${menu.id}${menu.menuLabel}`}
                item
                xs={12}
              >
                {menu.menuLabel && (
                  <Box mt={'5px'}>
                    <Tooltip title={menu.menuLabel} placement='right-end'>
                      <Typography
                        textAlign={isSideBarOpen ? 'left' : 'center'}
                        variant='body2'
                        fontWeight={'bold'}
                        ml={isSideBarOpen ? '6px' : ''}
                        color={TEXT_COLOR_THEME.primary}
                      >
                        {!isSideBarOpen ? getInitials(menu.menuLabel) : menu.menuLabel}
                      </Typography>
                    </Tooltip>
                  </Box>
                )}
                {menu.subMenu
                  .filter((sub) => sub.toShow)
                  .map((subMenu) => (
                    <MenuOption
                      navigate={navigate}
                      isSideBarOpen={isSideBarOpen}
                      isactive={location.pathname.includes(subMenu.url)}
                      menuInfo={subMenu}
                      key={subMenu.id}
                    />
                  ))}
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid>
        <Grid container justifyContent={'center'}>
          <IconButton
            onClick={() => {
              setIsSideBarOpen(!isSideBarOpen);
            }}
          >
            {isSideBarOpen ? (
              <AiOutlineLeftCircle fontSize={'30px'} />
            ) : (
              <AiOutlineRightCircle fontSize={'30px'} />
            )}
          </IconButton>
        </Grid>
      </Grid>
      {currentOrgAnchorEl && (
        <CurrentUserOrg
          anchorEl={currentOrgAnchorEl}
          handleClose={() => setCurrentOrgAnchorEl(null)}
          handleOrgSelect={(orgId: string) => {
            dispatch(setSelectedOrganisation(orgId));
            setCurrentOrgAnchorEl(null);
          }}
        />
      )}
    </SideBarContainer>
  );
};

export default Sidebar;
