import Styled from '@emotion/styled';
import { Grid, IconButton } from '@mui/material';
import { FLAG_OPTION_COLOR } from '../../theme/color';

type StyledFlagProps = {
  isactive?: number;
};
export const StyledFlag = Styled.img<StyledFlagProps>`
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px ${FLAG_OPTION_COLOR.activeBorder} solid;
    box-shadow: 1px 1px 10px ${FLAG_OPTION_COLOR.activeBorder};
    ${(props) =>
      props.isactive &&
      `
        box-shadow: 1px 1px 10px ${FLAG_OPTION_COLOR.activeShadowColor};
        transform: scale(1.3);
    `};

    &:hover {
     transform: scale(1.1);   
    }
    
`;

export const StyledArrowButton = Styled(IconButton)`
    border-radius: 10px;
    backdrop-filter: blur(27px);
`;

export const StyledCustomTooltipContainer = Styled(Grid)`
    
`;
