import React, { FC } from 'react';
import { FlagTooltipType } from '../../header/type';
import { Box, Grid, Typography } from '@mui/material';
import { ColumnHeaderText, CompactTag, Header2 } from '../../../common/component/Text';

type CompletedTradeDataGridProps = {
  data: FlagTooltipType[];
};
const CompletedTradeDataGrid: FC<CompletedTradeDataGridProps> = ({ data }) => {
  return (
    <Grid container height={'500px'} overflow={'scroll'}>
      <Grid item xs={12}>
        <Box
          p={'10px'}
          border={'1px solid rgba(224, 224, 224, 1)'}
          bgcolor={'#efefef'}
          py={'20px'}
          borderRadius={'10px'}
          style={{ borderRadius: '10px 10px 0px 0px' }}
        >
          <ColumnHeaderText>CURRENT PICKS</ColumnHeaderText>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {data.map((item) => (
          <Box
            key={item.id}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            padding={'20px'}
            borderTop={0}
            border={'1px rgba(224, 224, 224, 1) solid'}
          >
            <Header2 width={'60px'}>{item.teamName}</Header2>
            <Grid>
              <Typography gap={'5px'} display={'flex'} flexWrap={'wrap'}>
                {item.currentYear}{' '}
                {item.currentYearPicks.split(', ').map((item: string) => (
                  <CompactTag
                    gap={'5px'}
                    display={'flex'}
                    alignItems={'center'}
                    textsize={'12px'}
                    textAlign={'center'}
                    bgcolor={'transparent'}
                    fontcolor={'#189a18'}
                  >
                    {item}
                  </CompactTag>
                ))}
              </Typography>

              <Header2 gap={'5px'} display={'flex'} flexWrap={'wrap'} mt={1}>
                {item.nextYear}
                {item.nextYearPicks.split(', ').map((item: string) => (
                  <CompactTag
                    gap={'5px'}
                    display={'flex'}
                    alignItems={'center'}
                    textsize={'12px'}
                    textAlign={'center'}
                    bgcolor={'tramsparent'}
                    fontcolor={'#737373'}
                  >
                    {item}
                  </CompactTag>
                ))}
              </Header2>
            </Grid>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default CompletedTradeDataGrid;
