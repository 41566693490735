import { Tooltip } from '@mui/material';
import React, { FC } from 'react';
import { StyledMenuOption, StyledMenuText } from '../style';
import { SideSubMenuType } from '../type';

type Props = {
  menuInfo: SideSubMenuType;
  isactive: boolean;
  isSideBarOpen: boolean;
  navigate: (to: string) => void;
};
const MenuOption: FC<Props> = ({ menuInfo, isactive, isSideBarOpen, navigate }) => {
  return (
    <Tooltip placement='right-end' title={!isSideBarOpen ? menuInfo.label : ''}>
      <StyledMenuOption
        onClick={() => navigate(menuInfo.url)}
        issidebaropen={isSideBarOpen ? 1 : 0}
        isactive={isactive ? 1 : 0}
      >
        <StyledMenuText
          isactive={isactive ? 1 : 0}
          alignItems={'center'}
          display={'flex'}
          gap={'16px'}
        >
          {menuInfo.icon} {isSideBarOpen ? menuInfo.label : null}
        </StyledMenuText>
      </StyledMenuOption>
    </Tooltip>
  );
};

export default MenuOption;
