/* eslint-disable react-hooks/rules-of-hooks */
import { Grid } from '@mui/material';
import { Formik } from 'formik';
import { loginInitialValue, loginValidationSchema } from './util';
import { usePressEnterHook } from '../../common/hooks';
import FormikTextInput from '../../common/fields/FormikTextInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import { useLogin } from './hook';
import { LoginContainer, LoginFormContainer } from './style';
import { PrimaryButton } from '../../common/component/Button';
import { ErrorText } from '../../common/component/Text';
import { userLogin } from '../../store/auth/api';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const { setShowPassword, showPassword, error, loading, dispatch } = useLogin();
  const navigate = useNavigate();
  return (
    <LoginContainer container minHeight={'100vh'} justifyContent={'center'} alignItems={'center'}>
      <LoginFormContainer item xs={10} sm={6} md={4}>
        <Formik
          initialValues={loginInitialValue}
          validationSchema={loginValidationSchema}
          enableReinitialize
          onSubmit={(values) => {
            dispatch(userLogin(values)).then(() => {
              navigate('/dashboard');
            });
          }}
        >
          {({ handleSubmit }) => {
            usePressEnterHook(handleSubmit);
            return (
              <Grid container spacing={'20px'}>
                <Grid item xs={12}>
                  <FormikTextInput name={'email'} fullWidth label={'Email'} />
                </Grid>
                <Grid item xs={12}>
                  <FormikTextInput
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    name={'password'}
                    label={'Password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                            {!showPassword ? <MdOutlineVisibility /> : <MdOutlineVisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {error && (
                  <Grid item xs={12} textAlign={'center'}>
                    <ErrorText>{error}</ErrorText>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <PrimaryButton
                    onClick={() => handleSubmit()}
                    fullWidth
                    isLoading={loading}
                    title={'Login'}
                  />
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      </LoginFormContainer>
    </LoginContainer>
  );
};

export default LoginForm;
