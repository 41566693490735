import { OptionType } from '../../common/fields/type';
import { PlayerDatabaseType } from '../playerdatabase/type';
import {
  ClubSummaryPlayerType,
  ClubSummaryType,
  ContractDetails,
  PlayerContractDetails,
  SummaryDataType,
} from './type';

const generateYearRange = () => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 15;
  const endYear = currentYear + 15;
  const yearRange = [];

  for (let year = startYear; year <= endYear; year++) {
    yearRange.push(year.toString());
  }

  return yearRange;
};
const transformContract = (data: any): ContractDetails[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        contractId: item.contract_id,
        contractType: item.contract_type,
        created: item.created,
        endDate: item.end_date,
        signingDate: item.signing_date,
        startDate: item.start_date,
        updated: item.updated,
      } as ContractDetails),
  );
};
const transformPlayerList = (data: any): ClubSummaryPlayerType[] => {
  if (!data) {
    return [];
  }
  const playerTransform = data.map(
    (item: any) =>
      ({
        id: item.player.id,
        playerName: `${item.player.first_name} ${item.player.last_name}`,
        contractDetails: item.contractual_years.map(
          ({ contract_year, contracts }: { contract_year: number; contracts: any }) => ({
            year: contract_year,
            contract: transformContract(contracts),
          }),
        ),
      } as ClubSummaryPlayerType),
  );
  return addShowLineToPlayers(playerTransform);
};

const transformSummaryData = (data: Record<string, any>): SummaryDataType[] => {
  if (!data) {
    return [];
  }
  return Object.entries(data).map(([year, stats]) => ({
    year: Number(year),
    primaryListOfficial: stats.primary_list_official,
    primaryListProjected: stats.primary_list_projected,
    rookieListOfficial: stats.rookie_list_official,
    rookieListProjected: stats.rookie_list_projected,
    totalListOfficial: stats.total_list_official,
    totalListProjected: stats.total_list_projected,
  }));
};
export const transformSmartListPlayer = (data: any): ClubSummaryType => {
  return {
    columns: generateYearRange(),
    playerList: transformPlayerList(data?.players),
    summaryData: transformSummaryData(data?.summary),
  };
};

export const trasnformPlayerListOptions = (data: PlayerDatabaseType[]): OptionType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (playerInfo) =>
      ({
        label: `${playerInfo.firstName} ${playerInfo.lastName}`,
        value: playerInfo.id,
      } as OptionType),
  );
};

export const addShowLineToPlayers = (players: ClubSummaryPlayerType[]) => {
  // Helper function to check if two years have the same contract ID
  const hasSameContractId = (year1: { contract: any[] }, year2: { contract: any[] }) => {
    const contractIds1 = year1.contract.map((contract: { contractId: any }) => contract.contractId);
    const contractIds2 = year2.contract.map((contract: { contractId: any }) => contract.contractId);
    return contractIds1.some((id: any) => contractIds2.includes(id));
  };

  players.forEach((player: { contractDetails: any }) => {
    const contractDetails = player.contractDetails;

    // Initialize showLine and addBorderRadius as false for all years
    contractDetails.forEach(
      (year: { showLine: boolean; addBorderRadius: boolean }, index: number) => {
        year.showLine = false;
        year.addBorderRadius = false;
      },
    );

    // Set addBorderRadius for the first and last items in the row
    if (contractDetails.length > 0) {
      contractDetails[0].addBorderRadius = true; // First item
    }

    // Loop through each year and determine if showLine should be true
    for (let i = 0; i < contractDetails.length; i++) {
      const currentYear = contractDetails[i];
      const nextYear = contractDetails[i < contractDetails.length ? i + 1 : contractDetails.length];
      if (
        i < contractDetails.length - 1 &&
        hasSameContractId(currentYear, contractDetails[i + 1])
      ) {
        // Skip setting showLine for current year if the next year has the same contract ID
        continue;
      }

      if (i > 0 && hasSameContractId(currentYear, contractDetails[i - 1])) {
        // Set showLine for the current year if the previous year has the same contract ID
        currentYear.showLine = true;
        if (nextYear) {
          nextYear.addBorderRadius = true;
        }
      } else if (currentYear.contract.length === 1) {
        // If the current year is an isolated year with a single contract
        const contractId = currentYear.contract[0].contractId;
        const isIsolated = contractDetails.every((year: { year: any; contract: any[] }) => {
          if (year.year === currentYear.year) return true;
          return !year.contract.some(
            (contract: { contractId: any }) => contract.contractId === contractId,
          );
        });

        currentYear.showLine = isIsolated;
      }
    }
  });

  return players;
};

export const getSummaryGridBgColor = (type: string) => {
  if (type === 'rookie') {
    return '#C6E0B4';
  }
  if (type === 'PROJECTED_ROOKIE') {
    return '#FFFF33';
  }
  if (type === 'PROJECTED') {
    return 'orange';
  }
  return '#70AC47';
};

export const getBorderRadiusForContract = (contractDetails: PlayerContractDetails) => {
  if (contractDetails.contract.length > 1) {
    return '5px';
  }
  if (contractDetails.showLine) {
    return '0px 5px 5px 0px';
  }
  if (contractDetails.addBorderRadius) {
    return '5px 0px 0px 5px';
  }
  return '0px';
};
