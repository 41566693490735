import { FC } from 'react';
import { CsvListType } from '../type';
import DataGridWrapper from '../../../common/component/DataGridWrapper';
import { csvListDataColumn } from './ListColumn';

type CsvDataGridProps = {
  csvList: CsvListType[];
};
const CsvDataGrid: FC<CsvDataGridProps> = ({ csvList }) => {
  return (
    <DataGridWrapper
      loading={false}
      rowHeight={30}
      columns={csvListDataColumn()}
      rows={csvList || []}
      hideFooterPagination={false}
      hideFooter={false}
    />
  );
};

export default CsvDataGrid;
