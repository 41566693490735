/* eslint-disable react-hooks/exhaustive-deps */
import { useForm, useWatch } from 'react-hook-form';
import { useCurrentProject } from '../../../../store/header/selector';
import { useAppSelector } from '../../../../store/hooks';
import { useGetAllPickQuery } from '../../../../store/service';
import {
  PassPickInitialValue,
  PassPickValidationSchema,
  transformPassImpactData,
  transformPassPickOption,
} from './util';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useCreatePassPickImpactMutation,
  useGetpassPickImpactMutation,
} from '../../../../store/service/passpickservice';
import { useEffect, useMemo } from 'react';
import { PassPickType } from './type';
import { useDispatch } from 'react-redux';
import { setUploadCsvSelectedTransactionTypeModal } from '../../../../store/header';
import { useGetUserDetail } from '../../../../store/auth/selector';
import { reverseTransformPassPickData } from '../../../../store/service/passpickservice/util';

export const usePassPick = (closeTransactionModal: () => void) => {
  const currentProject = useAppSelector(useCurrentProject);
  const { data: allPicksData } = useGetAllPickQuery(parseInt(currentProject));
  const [getData, { data: passPickImpactData, isLoading: impactLoading }] =
    useGetpassPickImpactMutation();
  const [createPassPick, { isLoading: createPassPickLoading }] = useCreatePassPickImpactMutation();
  const { userInfo } = useAppSelector(useGetUserDetail);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: PassPickInitialValue,
    resolver: yupResolver(PassPickValidationSchema),
  });
  const dispatch = useDispatch();
  const pickId = useWatch({
    control,
    name: 'pickId',
  });
  const selectedPassPick = useWatch({
    control,
    name: 'selectedPassPick',
  });

  useEffect(() => {
    fetchImpactData();
  }, [pickId, selectedPassPick]);

  const fetchImpactData = () => {
    if (pickId !== '' && selectedPassPick) {
      getData({
        data: {
          pickId,
          selectedPassPick,
        },
        projectId: currentProject,
      });
    }
  };
  const impactData = useMemo(
    () => transformPassImpactData(passPickImpactData),
    [passPickImpactData],
  );

  const onSubmit = (value: PassPickType) => {
    createPassPick({
      data: value,
      projectId: currentProject,
    }).then((res: any) => {
      if (!res.error) {
        closeTransactionModal();
        userInfo?.isStaff &&
          dispatch(
            setUploadCsvSelectedTransactionTypeModal({
              transactionType: 'Pass Pick',
              payload: reverseTransformPassPickData(value),
            }),
          );
        return;
      }
    });
  };
  return {
    allPickDataDropdown: transformPassPickOption(allPicksData),
    handleSubmit,
    register,
    control,
    errors,
    impactData,
    onSubmit,
    createPassPickLoading,
    impactLoading,
  };
};
