import React, { FC } from 'react';
import CustomModal from '../../../common/component/CustomModal';
import { Divider, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddPlayerValidationSchema, addPlayerInitialValue } from '../util';
import CustomTextInput from '../../../common/fields/reacthookfield/CustomTextInput';
import CustomSelect from '../../../common/fields/reacthookfield/CustomSelect';
import { FieldLabel } from '../../../common/component/Text';
import CustomFileInput from '../../../common/fields/reacthookfield/CustomFileInput';
import { useAddPlayer } from '../hook';
type AddPlayerProps = {
  handleClose: () => void;
  refreshList: () => void;
};
const AddPlayer: FC<AddPlayerProps> = ({ handleClose, refreshList }) => {
  const { isLoading, submitData, playerPositionDropdownOption } = useAddPlayer(
    handleClose,
    refreshList,
  );
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: addPlayerInitialValue,
    resolver: yupResolver(AddPlayerValidationSchema),
  });

  return (
    <>
      <CustomModal
        modalType='react-hook'
        handleReactFormSubmit={handleSubmit(submitData)}
        modalSize='sm'
        saveLoading={isLoading}
        modalTitle={'Add New Player'}
        handleClose={handleClose}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextInput
              name={'firstName'}
              register={register}
              isMandatory
              errors={errors}
              fullWidth
              variant='outlined'
              label={'First Name'}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              name={'lastName'}
              register={register}
              errors={errors}
              isMandatory
              fullWidth
              variant='outlined'
              label={'Last Name'}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomSelect
              id={'position1'}
              name={'position1'}
              register={register}
              options={playerPositionDropdownOption}
              size='small'
              errors={errors}
              fullWidth
              variant='outlined'
              label={'Position1'}
            />
          </Grid>
          <Grid item my={'5px'} xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} mt={'5px'}>
            <FieldLabel textAlign={'center'}>OR</FieldLabel>
          </Grid>
          <Grid item xs={12} mt={'5px'}>
            <CustomFileInput name='file' label={'Add Player from CSV'} control={control} />
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};

export default AddPlayer;