import { FC } from 'react';
import CustomModal from '../../../../common/component/CustomModal';
import { Grid } from '@mui/material';
import { usePassPick } from './hook';
import CustomAutocomplete from '../../../../common/fields/reacthookfield/CustomAutocomplete';
import PassPickImpactData from './PassPickImpactData';
import LoadingWrapper from '../../../../common/component/LoadingWrapper';
import { PassPickRadioOption } from './util';
import CustomRadioGroup from '../../../../common/fields/reacthookfield/CustomRadioGroup';

type PassPicksProps = {
  closeTransactionModal: () => void;
};
const PassPicks: FC<PassPicksProps> = ({ closeTransactionModal }) => {
  const {
    allPickDataDropdown,
    control,
    errors,
    handleSubmit,
    impactData,
    createPassPickLoading,
    onSubmit,
    impactLoading,
  } = usePassPick(closeTransactionModal);

  return (
    <CustomModal
      handleSubmit={handleSubmit(onSubmit)}
      saveLoading={createPassPickLoading}
      modalSize={'sm'}
      handleClose={closeTransactionModal}
      modalTitle='Pass Picks'
    >
      <Grid container>
        <Grid item xs={12}>
          <CustomAutocomplete
            name='pickId'
            id='team1.tradingOut'
            control={control}
            errors={errors}
            options={allPickDataDropdown}
            label={'Select picks to pass'}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomRadioGroup
            control={control}
            name='selectedPassPick'
            radioOption={PassPickRadioOption}
          />
        </Grid>
        <LoadingWrapper height='150px' isLoading={impactLoading}>
          {impactData && (
            <Grid item xs={12}>
              <PassPickImpactData impactData={impactData} />
            </Grid>
          )}
        </LoadingWrapper>
      </Grid>
    </CustomModal>
  );
};

export default PassPicks;
