import { UserOrganisationType } from './type';

export const transformOrgList = (data: any): UserOrganisationType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (org: any) =>
      ({
        created: org.created,
        invitationAccepted: org.invitation_accepted,
        organisationDefaultTeamId: org.organisation_default_team_id,
        organisationDefaultTeamName: org.organisation_default_team_name,
        organisationDefaultTeamShortName: org.organisation_default_team_short_name,
        organisationId: org.organisation_id,
        organisationSportingCode: org.organisation_sporting_code,
        organisationTitle: org.organisation_title,
        roles: org.roles,
        updated: org.updated,
      } as UserOrganisationType),
  );
};
