import { FC, useCallback } from 'react';
import { RosterListType } from '../type';
import { Box, Grid } from '@mui/material';
import { ColumnHeaderText } from '../../../common/component/Text';
import RosterCard from './RosterCard';

type RosterListProps = {
  isLoading: boolean;
  setRosterAmount: (rosterId: number, rosterYear: string, amount: number) => void;
  rosterList: RosterListType[];
};
const RosterList: FC<RosterListProps> = ({ rosterList, setRosterAmount }) => {
  const rosterCard = useCallback(
    (
      item: RosterListType,
      index: number,
      setRosterAmount: (rosterId: number, rosterYear: string, amount: number) => void,
    ) => <RosterCard key={index} index={index} item={item} setRosterAmount={setRosterAmount} />,
    [],
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          borderRadius={'10px 10px 0px 0px'}
          px={'10px'}
          py={'16px'}
          bgcolor={'#efefef'}
        >
          <Box flex={1}>
            <ColumnHeaderText>#</ColumnHeaderText>
          </Box>
          <Box flex={2}>
            <ColumnHeaderText>Team</ColumnHeaderText>
          </Box>
          <Box flex={2}>
            <ColumnHeaderText>{rosterList?.[0]?.currentYear || 'Current Year'}</ColumnHeaderText>
          </Box>
          <Box flex={2}>
            <ColumnHeaderText>{rosterList?.[0]?.nextYear || 'Next Year'}</ColumnHeaderText>
          </Box>
        </Box>
        {rosterList.map((item, index) => rosterCard(item, index, setRosterAmount))}
      </Grid>
    </Grid>
  );
};

export default RosterList;
