import { Box, Tooltip } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { Header2, Tag } from '../../common/component/Text';
import { draftPlayerPositionObj } from '../../constant/OptionConstant';

export const playerListInfoColumn = () => [
  {
    field: 'fullName',
    headerName: 'NAME',
    flex: 3,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <Box gap={'10px'} display={'flex'}>
        <Tooltip placement='right-start' title={params.row.position1}>
          <Tag
            textAlign={'center'}
            width={'20px'}
            bgcolor={draftPlayerPositionObj?.[params.row.position1]?.color || ''}
          >
            {draftPlayerPositionObj?.[params.row.position1]?.text || params.row.position1}
          </Tag>
        </Tooltip>
        <Header2>{params.row.fullName}</Header2>
      </Box>
    ),
  },
  {
    field: 'rank',
    headerName: 'RANK',
    flex: 1,
    renderCell: (params: GridCellParams) => <Header2>{params.row.rank}</Header2>,
  },
  {
    field: 'tier',
    headerName: 'TIER',
    flex: 1,
    renderCell: (params: GridCellParams) => <Header2>{params.row.tier}</Header2>,
  },
];