import { GridCellParams } from '@mui/x-data-grid';
import { DeficitImpactDatagridType } from '../../type';
import { Box } from '@mui/material';
import { Header2 } from '../../../../common/component/Text';

export const deficitImpactColumn = () => [
  {
    field: 'draftRound',
    headerName: 'RD',
    flex: 1,
    minWidth: 35,
    sortable: false,
    renderCell: (params: GridCellParams<DeficitImpactDatagridType, DeficitImpactDatagridType>) => (
      <Box>
        <Header2>{params.row.draftRound}</Header2>
      </Box>
    ),
  },
  {
    field: 'overallPick',
    headerName: 'Pick',
    flex: 1,
    minWidth: 50,
    sortable: false,
    renderCell: (params: GridCellParams<DeficitImpactDatagridType, DeficitImpactDatagridType>) => (
      <Box>
        <Header2>{params.row.overallPick}</Header2>
      </Box>
    ),
  },
  {
    field: 'aflPointsValue',
    headerName: 'DVI',
    flex: 1,
    minWidth: 50,
    sortable: false,
    renderCell: (params: GridCellParams<DeficitImpactDatagridType, DeficitImpactDatagridType>) => (
      <Box>
        <Header2>{params.row.aflPointsValue}</Header2>
      </Box>
    ),
  },
  {
    field: 'maxDeficitPoints',
    headerName: 'MAX RD DVI',
    flex: 1,
    minWidth: 90,
    sortable: false,
    renderCell: (params: GridCellParams<DeficitImpactDatagridType, DeficitImpactDatagridType>) => (
      <Box>
        <Header2>{params.row.maxDeficitPoints}</Header2>
      </Box>
    ),
  },
  {
    field: 'pointsSubtracted',
    headerName: 'Pts Subtracted',
    flex: 1,
    minWidth: 100,
    sortable: false,
    renderCell: (params: GridCellParams<DeficitImpactDatagridType, DeficitImpactDatagridType>) => (
      <Box>
        <Header2>{params.row.pointsSubtracted}</Header2>
      </Box>
    ),
  },
  {
    field: 'newOverallPick',
    headerName: 'New Pick',
    flex: 1,
    minWidth: 100,
    sortable: false,
    renderCell: (params: GridCellParams<DeficitImpactDatagridType, DeficitImpactDatagridType>) => (
      <Box>
        <Header2>{params.row.newOverallPick}</Header2>
      </Box>
    ),
  },
];
