import { Drawer, Grid } from '@mui/material';
import { Header1 } from '../../../common/component/Text';
import { FilterOptionType } from '../type';
import { useTimelineFilterDrawer } from '../hook';
import CustomSelect from '../../../common/fields/reacthookfield/CustomSelect';
import { PrimaryButton, PrimaryTextButton } from '../../../common/component/Button';
import CustomDateRangePicker from '../../../common/fields/reacthookfield/CustomDateRangePicker';

type TimelineFilterDrawerProps = {
  handleClose: () => void;
  selectedFilter: FilterOptionType;
  setSelectedFilter: (filter: FilterOptionType) => void;
};
const TimelineFilterDrawer = ({
  handleClose,
  selectedFilter,
  setSelectedFilter,
}: TimelineFilterDrawerProps) => {
  const { control, handleSubmit, formDataOpt, register, formType } =
    useTimelineFilterDrawer(selectedFilter);

  const applyFilter = (value: FilterOptionType) => {
    setSelectedFilter(value);
  };
  return (
    <Drawer open onClose={handleClose} anchor='right'>
      <Grid container p={'20px'} width={['400px']}>
        <Grid item xs={12}>
          <Header1 style={{ textAlign: 'center' }}>Filter</Header1>
        </Grid>
        <Grid item xs={12} mt={'20px'}>
          <CustomSelect
            id={'formType'}
            name={'formType'}
            register={register}
            options={formDataOpt}
            size='small'
            value={formType}
            fullWidth
            control={control}
            variant='outlined'
            label={'Form Type'}
          />
        </Grid>
        <Grid item xs={12} mt={'20px'}>
          <CustomDateRangePicker fieldLabel={'Created At'} name={'createdAt'} control={control} />
        </Grid>
        <Grid item xs={12} mt={'20px'}>
          <CustomDateRangePicker fieldLabel={'Updated At'} name={'updatedAt'} control={control} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={'flex-end'} mt={'20px'}>
            <Grid item xs={6}>
              <PrimaryTextButton onClick={() => handleClose()} title={'Close'} />
            </Grid>
            <Grid item xs={6}>
              <PrimaryButton onClick={handleSubmit(applyFilter)} title='Apply Filter' />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default TimelineFilterDrawer;
