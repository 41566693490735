import { Box, Grid, IconButton } from '@mui/material';
import React, { FC } from 'react';
import { FieldLabel, Header3 } from '../../../../common/component/Text';
import { OptionType } from '../../../../common/fields/type';
import CustomAutocomplete from '../../../../common/fields/reacthookfield/CustomAutocomplete';
import { Control, FieldErrors, UseFormRegister, UseFormSetValue, useWatch } from 'react-hook-form';
import { MultiPlayerType, MultiTradeType } from './type';
import PlayerInfoForm from './PlayerInfoForm';
import { PrimaryTextButton } from '../../../../common/component/Button';
import { Add, DeleteOutline } from '@mui/icons-material';
import { PicksOptionType, PicksTradingType } from '../../type';
import TradingOptionPicker from './TradingOptionPicker';

type TeamTradeFormProps = {
  title: string;
  name: string;
  teamOption: OptionType[];
  control: Control<MultiTradeType>;
  errors: FieldErrors<MultiTradeType>;
  index: number;
  currentProject: string;
  register: UseFormRegister<MultiTradeType>;
  setValue: UseFormSetValue<MultiTradeType>;
  removeTeam: () => void;
  flattenPicksTrading: PicksOptionType[];
  allPickDropdown: PicksTradingType[];
};
const TeamTradeForm: FC<TeamTradeFormProps> = ({
  title,
  name,
  control,
  errors,
  teamOption,
  index,
  setValue,
  register,
  removeTeam,
  allPickDropdown,
  flattenPicksTrading,
}) => {
  const playersIn = useWatch({
    control,
    name: `team[${index}].playersIn` as any,
  });

  const picksIn = useWatch({
    control,
    name: `team[${index}].picksIn` as any,
  });

  const removePlayer = (itemId: string) => {
    setValue(
      `${name}.playersIn` as any,
      playersIn.filter((_: MultiPlayerType, index: number) => _.id !== itemId),
    );
  };

  const handlePicksTrading = (pick: string) => {
    const picksFind = picksIn.find((item: string) => item === pick);
    if (picksFind) {
      setValue(`team[${index}].picksIn` as any, [
        ...picksIn.filter((item: string) => item !== pick),
      ]);
      return;
    }

    setValue(`team[${index}].picksIn` as any, [...picksIn, pick]);
  };

  return (
    <Grid container rowSpacing={'10px'}>
      <Grid item xs={12}>
        <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
          <Header3>{title}</Header3>
          <IconButton
            style={{
              visibility: index >= 2 ? 'initial' : 'hidden',
            }}
            onClick={() => removeTeam()}
          >
            <DeleteOutline color={'error'} />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <CustomAutocomplete
          name={`${name}.teamId`}
          id={`${name}.teamId`}
          control={control}
          errorMessage={errors?.team?.[index]?.message}
          errors={errors}
          options={teamOption}
          label={`Select Team ${index + 1}`}
        />
      </Grid>
      <Grid item xs={12}>
        <TradingOptionPicker
          setValue={handlePicksTrading}
          pickOptions={allPickDropdown}
          value={picksIn}
          flattenPicksTrading={flattenPicksTrading}
        />
      </Grid>
      <FieldLabel mt={'30px'}>Select Player Trading In:</FieldLabel>
      {playersIn.map((item: MultiPlayerType, index: number) => (
        <Grid item xs={12} key={item.id}>
          <PlayerInfoForm
            control={control}
            name={`${name}.playersIn[${index}]`}
            register={register}
            index={index}
            deletePlayer={() => removePlayer(item.id)}
            teamDropdown={teamOption}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <PrimaryTextButton
          onClick={() =>
            setValue(`${name}.playersIn` as any, [
              ...playersIn,
              {
                id: Math.random().toString(),
                playerName: '',
                clubId: '',
              },
            ])
          }
          startIcon={<Add />}
          title='Click to add another player'
        />
      </Grid>
    </Grid>
  );
};

export default TeamTradeForm;
