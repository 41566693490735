import React, { FC } from 'react';
import { MultiPickType, MultiTradeImpactType } from './type';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { StyledLogo } from '../../../dashboard/style';
import { TeamFlagOptions } from '../../../../constant/OptionConstant';
import { CompactTag } from '../../../../common/component/Text';
import { getInitials } from '../../../../util/SiteHelper';
import { TAG_COLOR, TEXT_COLOR_INFO_TYPE } from '../../../../theme/color';

type MultipleTransactionImpactProps = {
  impactData: MultiTradeImpactType;
};
const MultipleTransactionImpact: FC<MultipleTransactionImpactProps> = ({ impactData }) => {
  return (
    <Grid container>
      <Grid item xs={11}>
        <Box display={'flex'} justifyContent={'center'}>
          <StyledLogo
            style={{ height: 30, width: 30 }}
            src={
              TeamFlagOptions.find((item) => item.id === Number(impactData.teamId))!.flagIcon || ''
            }
            alt={'flag'}
          />
        </Box>
        <Typography mt={'20px'} textAlign={'center'}>
          IN
        </Typography>
        <Box mt={'5px'} display={'flex'} justifyContent={'center'} gap={'5px'}>
          {(impactData?.picksIn as unknown as MultiPickType[])?.map((item) => (
            <Tooltip title={item.label}>
              <CompactTag
                gap={'5px'}
                key={item.display}
                display={'flex'}
                style={{ padding: 3 }}
                alignItems={'center'}
                textsize={'12px'}
                textAlign={'center'}
                bgcolor={'transparent'}
                fontcolor={TAG_COLOR.success}
                minWidth={30}
                minHeight={30}
              >
                {' '}
                {item?.overallPick}
              </CompactTag>
            </Tooltip>
          ))}
          {impactData?.playersIn?.map((item) => (
            <Tooltip title={item.id}>
              <CompactTag
                style={{ padding: '3px' }}
                gap={'5px'}
                key={item.playerName}
                display={'flex'}
                alignItems={'center'}
                textsize={'16px'}
                textAlign={'center'}
                bgcolor={'transparent'}
                fontcolor={'#2a93d5'}
                minWidth={30}
                minHeight={30}
              >
                {' '}
                {getInitials(item.playerName)}
              </CompactTag>
            </Tooltip>
          ))}
        </Box>

        <Typography mt={'20px'} textAlign={'center'}>
          OUT
        </Typography>
        {impactData?.picksOut.length === 0 && (
          <Typography color={'GrayText'} mt={'20px'} textAlign={'center'}>
            (No Trades)
          </Typography>
        )}
        <Box mt={'5px'} display={'flex'} justifyContent={'center'} gap={'20px'}>
          {(impactData?.picksOut as unknown as MultiPickType[])?.map((item) => (
            <Tooltip title={item.label}>
              <CompactTag
                gap={'5px'}
                key={item.display}
                display={'flex'}
                style={{ padding: 3 }}
                alignItems={'center'}
                textsize={'12px'}
                textAlign={'center'}
                bgcolor={'transparent'}
                fontcolor={TAG_COLOR.success}
                minWidth={30}
                minHeight={30}
              >
                {' '}
                {item?.overallPick}
              </CompactTag>
            </Tooltip>
          ))}

          {impactData?.playersOut?.map((item) => (
            <Tooltip title={item.id}>
              <CompactTag
                style={{ padding: '3px' }}
                gap={'5px'}
                key={item.playerName}
                display={'flex'}
                alignItems={'center'}
                textsize={'16px'}
                textAlign={'center'}
                bgcolor={'transparent'}
                fontcolor={'#2a93d5'}
                minWidth={30}
                minHeight={30}
              >
                {' '}
                {getInitials(item.playerName)}
              </CompactTag>
            </Tooltip>
          ))}
        </Box>
        <Box mt={'20px'}>
          <Typography textAlign={'center'}>Net Point result</Typography>
          <Typography
            textAlign={'center'}
            color={
              impactData.netResult < 0 ? TEXT_COLOR_INFO_TYPE.error : TEXT_COLOR_INFO_TYPE.success
            }
          >
            {impactData.netResult}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MultipleTransactionImpact;
