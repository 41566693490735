import React from 'react';
import { NgaLineType } from '../../type';
import { Grid } from '@mui/material';
import { Header2 } from '../../../../common/component/Text';
import DraftHandInfo from '../fatherSonInfo/DraftHandInfo';

type VisualVersionProps = {
  transactionInfo: NgaLineType;
};
const VisualVersion = ({ transactionInfo }: VisualVersionProps) => {
  if (transactionInfo.visualVersion === '2024') {
    return (
      <Grid container>
        <Grid item xs={12}>
          <DraftHandInfo
            title='Starting Draft Hand'
            draftHand={transactionInfo?.initialDraftHand}
          />
          <DraftHandInfo
            title='Draft Hand if Bid Matched:'
            draftHand={transactionInfo?.newDraftHand}
          />
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Header2 textsize={'12px'}>{transactionInfo?.sumLine1}</Header2>
        <Header2 textsize={'12px'}>{transactionInfo?.sumLine2}</Header2>
      </Grid>
    </Grid>
  );
};

export default VisualVersion;
