import { Grid } from '@mui/material';
import DataGridWrapper from '../../../common/component/DataGridWrapper';
import { useContract } from '../hook';
import { ContractListColumn } from './ListColumn';
import { PrimaryButton } from '../../../common/component/Button';
import AddUpdateContract from './AddUpdateContract';
import DeleteModal from '../../../common/component/DeleteModal';

type ContractProps = {
  playerId: string;
  selectedTeam?: string;
};

const Contract = ({ playerId, selectedTeam }: ContractProps) => {
  const {
    data,
    loading,
    setOpenAddModal,
    openAddModal,
    selectedContractForEdit,
    setSelecetedContractForEdit,
    refreshList,
    deleteLoading,
    handleContractDelete,
    setSelectedContractForDelete,
    selectedContractForDelete,
  } = useContract(playerId);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent={'flex-end'}>
          <Grid item>
            <PrimaryButton onClick={() => setOpenAddModal(true)} title='Create Contract' />
          </Grid>
        </Grid>
      </Grid>
      {(openAddModal || selectedContractForEdit) && (
        <AddUpdateContract
          handleClose={() => {
            setSelecetedContractForEdit(undefined);
            setOpenAddModal(false);
          }}
          playerId={playerId}
          refreshList={refreshList}
          seasonList={data.map((item) => ({
            endSeason: item.endSeason.toString(),
            startSeason: item.startSeason.toString(),
          }))}
          selectedTeam={selectedTeam}
          selectedContract={selectedContractForEdit}
        />
      )}
      <Grid item xs={12} mt={'20px'}>
        <DataGridWrapper
          columns={ContractListColumn(setSelecetedContractForEdit, setSelectedContractForDelete)}
          rows={data}
          loading={loading}
        />
      </Grid>
      {selectedContractForDelete && (
        <DeleteModal
          isLoading={deleteLoading}
          description='Are you sure you want to delete?'
          modalTitle='Delete Contract'
          handleClose={() => setSelectedContractForDelete(undefined)}
          handleSubmit={handleContractDelete}
        />
      )}
    </Grid>
  );
};

export default Contract;
