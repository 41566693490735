import { lazy } from 'react';

export const LoginRoute = lazy(() => import('../container/login'));
export const DashboardRoute = lazy(() => import('../container/dashboard'));
export const DraftPickRoute = lazy(() => import('../container/draftpick'));
export const ProjectRoute = lazy(() => import('../container/project'));
export const DraftBoardRoute = lazy(() => import('../container/draftboard'));
export const TradeAnalysisRoute = lazy(() => import('../container/tradeanalysis/TradeAnalysis'));
export const ScenarioPlanningRoute = lazy(() => import('../container/scenarioplanning'));
export const OrganisationRoute = lazy(() => import('../container/organisation/Organisation'));
export const PlayerDatabaseRoute = lazy(() => import('../container/playerdatabase/PlayerDatabase'));
export const PlayerManagerRoute = lazy(() => import('../container/playermanager/PlayerManager'));
