import { Box, CircularProgress, Grid } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { Header1 } from './Text';

type LoadingWrapperProps = {
  isLoading: boolean;
  children: ReactNode;
  height?: string;
};
const LoadingWrapper: FC<LoadingWrapperProps> = ({ isLoading, height, children }) => {
  if (isLoading) {
    return (
      <Grid container height={height || '50vh'} justifyContent={'center'} alignItems={'center'}>
        <Box>
          <Box display={'flex'} justifyContent={'center'}>
            <CircularProgress />
          </Box>
          <Header1 mt={'10px'}>Loading</Header1>
        </Box>
      </Grid>
    );
  }
  return <>{children}</>;
};

export default LoadingWrapper;
