import { StepType } from './type';
import { Box, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { startCase } from 'lodash';

type VerticalStepperProps = {
  steps: StepType[];
};
const VerticalStepper = ({ steps }: VerticalStepperProps) => {
  return (
    <Box>
      <Stepper orientation='vertical'>
        {steps.map((step, _) => (
          <Step
            sx={{
              position: 'relative',
            }}
            active
            key={step.id}
          >
            <StepLabel>
              {step.label && <Typography>{startCase(step.label)}</Typography>}

              {step.subTitle && (
                <Typography position={'absolute'} left={'-180px'} top={0} mt={'8px'}>
                  {step.subTitle}
                </Typography>
              )}
            </StepLabel>
            <StepContent>{step.description}</StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default VerticalStepper;
