import React, { FC, Suspense } from 'react';
import SideNavbar from '../container/navbar';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid } from '@mui/material';
import Login from '../container/login';
import { SHOW_AUTH0_LOGIN } from '../util/SiteHelper';
import LoginForm from '../container/login/LoginForm';
import { useAppSelector } from '../store/hooks';
import { useGetAccessToken } from '../store/auth/selector';

type Props = {
  children: JSX.Element;
};

const PrivateRoute: FC<Props> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const accessToken = useAppSelector(useGetAccessToken);
  if (isLoading) {
    return (
      <Grid container height={'100vh'} justifyContent={'center'} alignItems={'center'}>
        <h1>Loading</h1>
      </Grid>
    );
  }

  if (accessToken === '' && !SHOW_AUTH0_LOGIN) {
    return (
      <Suspense fallback={'..'}>
        <LoginForm />
      </Suspense>
    );
  }
  if (!isAuthenticated && SHOW_AUTH0_LOGIN) {
    return (
      <Suspense fallback={'..'}>
        <Login />
      </Suspense>
    );
  }

  return <SideNavbar>{children}</SideNavbar>;
};

export default PrivateRoute;
