import { FC, useState } from 'react';
import { usePlayerList } from './hook';
import { Box, Drawer, Grid } from '@mui/material';
import { Header1, Header2 } from '../../common/component/Text';
import LoadingWrapper from '../../common/component/LoadingWrapper';
import { filterPlayerList } from './util';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../common/component/Button';
import AddPlayer from '../draftboard/component/AddPlayer';
import DataGridWrapper from '../../common/component/DataGridWrapper';
import { playerListInfoColumn } from './ListColumn';
import { StyledCard } from '../../common/component/Card';
import CustomSwitch from '../../common/component/CustomSwitch';

type PlayersListProps = {
  open: boolean;
  onClose: () => void;
};

const PlayersList: FC<PlayersListProps> = ({ onClose, open }) => {
  const { playerList, isLoading, refreshList } = usePlayerList();
  const navigate = useNavigate();
  const [addPlayer, setAddPlayer] = useState(false);
  const [showSelectedPlayer, setShowSelectedPlayer] = useState(false);

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: '40%',
        },
      }}
      anchor={'right'}
      open={open}
      onClose={onClose}
    >
      <Grid container px={'40px'} py={'10px'}>
        <Grid item xs={12}>
          <Header1 style={{ textAlign: 'center' }}>Player List</Header1>
          <Header2
            textsize='14px'
            fontcolor='#7D003B'
            style={{ textAlign: 'center', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              navigate('/draftboard');
              onClose();
            }}
          >
            Go to Draft board
          </Header2>
          <Box position={'absolute'} top={'20px'} right={'10px'}>
            <PrimaryButton
              onClick={() => setAddPlayer(true)}
              title='Add Player'
              style={{ width: 160 }}
            />
          </Box>
          {addPlayer && (
            <AddPlayer refreshList={refreshList} handleClose={() => setAddPlayer(false)} />
          )}
        </Grid>
        <LoadingWrapper isLoading={isLoading}>
          <StyledCard mt={'10px'} container>
            <Grid item xs={12}>
              <Box display={'flex'}>
                <CustomSwitch
                  label="Hide selected players"
                  isChecked={showSelectedPlayer}
                  onChange={() => setShowSelectedPlayer((prev) => !prev)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <DataGridWrapper
                loading={isLoading}
                columns={playerListInfoColumn()}
                rows={filterPlayerList(playerList, showSelectedPlayer)}
                getRowClassName={(params) => (params.row.selected ? `grey-bg` : `white-bg`)}
              />
            </Grid>
          </StyledCard>
        </LoadingWrapper>
      </Grid>
    </Drawer>
  );
};

export default PlayersList;