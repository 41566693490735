import { Box, Tooltip, Typography } from '@mui/material';
import { BG_COLOR } from '../../../theme/color';
import SummaryInfoCard from './SummaryInfoCard';
import { ClubSummaryPlayerType, CreateContractType, SummaryDataType } from '../type';
import { format } from 'date-fns';
import { getBorderRadiusForContract, getSummaryGridBgColor } from '../util';
import { flatten } from 'lodash';

type ContractColumnProps = {
  year: string;
  playerList: ClubSummaryPlayerType[];
  summaryList: SummaryDataType[];
  handlePlayerYearClick: (playerDetail: CreateContractType) => void;
};
const ContractColumn = ({
  playerList,
  summaryList,
  year,
  handlePlayerYearClick,
}: ContractColumnProps) => {
  return (
    <Box>
      <Box
        borderRight={`1px ${BG_COLOR.lightWhite} solid`}
        borderBottom={`1px ${BG_COLOR.lightWhite} solid`}
        bgcolor={BG_COLOR.white200}
        p={'20px'}
        id={`${year}`}
      >
        <Typography width={'40px'} fontWeight={900} fontSize={'12px'}>
          {year}
        </Typography>
      </Box>
      <Box bgcolor={BG_COLOR.white200} mt={'5px'} border={'1px #ccc dashed'} height={'30px'}>
        {summaryList.map((summary, index) => (
          <SummaryInfoCard
            officialValue={summary.primaryListOfficial}
            projectedValue={summary.primaryListProjected}
            summaryInfoYear={summary.year.toString()}
            year={year.toString()}
            key={`${summary.year}-${index}`}
          />
        ))}
      </Box>
      <Box bgcolor={BG_COLOR.white200} border={'1px #ccc dashed'} mt={'5px'} height={'30px'}>
        {summaryList.map((summary, index) => (
          <SummaryInfoCard
            officialValue={summary.rookieListOfficial}
            projectedValue={summary.rookieListProjected}
            summaryInfoYear={summary.year.toString()}
            year={year.toString()}
            key={`${summary.year}-${index}`}
          />
        ))}
      </Box>
      <Box bgcolor={BG_COLOR.white200} border={'1px #ccc dashed'} mt={'5px'} height={'30px'}>
        {summaryList.map((summary, index) => (
          <SummaryInfoCard
            officialValue={summary.totalListOfficial}
            projectedValue={summary.totalListProjected}
            summaryInfoYear={summary.year.toString()}
            year={year.toString()}
            key={`${summary.year}-${index}`}
          />
        ))}
      </Box>
      <Box bgcolor={BG_COLOR.white200} className={'player'}>
        {playerList.map((player) => {
          const contractDetails = player.contractDetails.find(
            (det) => det.year.toString() === year.toString(),
          );
          if (contractDetails) {
            return (
              <Box
                mt={'5px'}
                border={'1px transparent dashed'}
                display={'flex'}
                key={player.id}
                borderLeft={0}
                borderRight={0}
                gap={'5px'}
              >
                {contractDetails.contract.map((det) => (
                  <Tooltip
                    key={det.contractId}
                    title={
                      <Box>
                        <Typography>Player name: {player.playerName}</Typography>
                        <Typography>
                          Start Season: {format(new Date(det.startDate), 'yyyy')}
                        </Typography>
                        <Typography>End Season: {format(new Date(det.endDate), 'yyyy')}</Typography>
                        <Typography>Contract Type: {det.contractType}</Typography>
                      </Box>
                    }
                  >
                    <Box
                      flex={1}
                      key={det.contractId}
                      height={'30px'}
                      borderRadius={getBorderRadiusForContract(contractDetails)}
                      bgcolor={getSummaryGridBgColor(det.contractType)}
                    />
                  </Tooltip>
                ))}
                {contractDetails.showLine && (
                  <Box
                    border={`1px ${BG_COLOR.white200} solid`}
                    width={'2px'}
                    borderLeft={0}
                    borderRight={0}
                    bgcolor={BG_COLOR.white200}
                  />
                )}
              </Box>
            );
          }
          return (
            <Box
              border={'1px #ccc dashed'}
              flex={1}
              mt={'5px'}
              key={player.id}
              sx={{ cursor: 'pointer' }}
              height={'30px'}
              bgcolor={BG_COLOR.white200}
              onClick={() => {
                handlePlayerYearClick({
                  playerId: player.id.toString(),
                  contracts: flatten(player.contractDetails.map((detail) => detail.contract)),
                  selectedYear: year,
                });
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default ContractColumn;
