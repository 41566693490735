import { Grid } from '@mui/material';
import { useRankingCreateUpdateForm } from '../hook';
import CustomModal from '../../../common/component/CustomModal';
import CustomeDatepicker from '../../../common/fields/reacthookfield/CustomeDatepicker';
import { add, sub } from 'date-fns';
import CustomTextInput from '../../../common/fields/reacthookfield/CustomTextInput';
import { RankingListType } from '../type';

type RankingCreateUpdateFormProps = {
  handleClose: () => void;
  refreshList: () => void;
  selectedRankingItem?: RankingListType | null;
};
const RankingCreateUpdateForm = ({
  handleClose,
  refreshList,
  selectedRankingItem,
}: RankingCreateUpdateFormProps) => {
  const { control, handleSubmit, onSubmit, isLoading, register, errors } =
    useRankingCreateUpdateForm({ refreshList, handleClose, selectedRankingItem });

  return (
    <CustomModal
      modalType='react-hook'
      handleReactFormSubmit={handleSubmit(onSubmit)}
      modalSize='sm'
      saveLoading={isLoading}
      confirmButtonLabel={'Save'}
      modalTitle={'Add New Rank'}
      handleClose={handleClose}
    >
      <Grid container>
        <Grid item xs={12}>
          <CustomTextInput
            name={'name'}
            register={register}
            errors={errors}
            fullWidth
            variant='outlined'
            label={'Name'}
            isMandatory
          />
        </Grid>
        <Grid item xs={12}>
          <CustomeDatepicker
            isMandatory
            control={control}
            fieldLabel={'Draft Year'}
            name={'draftYear'}
            views={['year']}
            format='yyyy'
            defaultDay={1}
            defaultMonth={1}
            minDate={sub(new Date(), {
              years: 10,
            })}
            maxDate={add(new Date(), {
              years: 10,
            })}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default RankingCreateUpdateForm;
