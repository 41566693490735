import { max } from 'lodash';
import { object, string } from 'yup';
import { OptionType } from '../../common/fields/type';
import {
  AcademyBidMatchType,
  AcademySummaryDictType,
  AcademySummaryType,
  CompletedTradeType,
  CompleteTradeType,
  DeficitImpactDatagridType,
  DeficitImpactType,
  DraftNightSelectionType,
  FatherBidMatchType,
  FatherSonDataSummaryType,
  FreeAgentCompensationType,
  ImpactDictType,
  ImpactVisualType,
  InitialDraftHandType,
  ManualPickEditType,
  ManualPickInsertType,
  ManualPickMoveType,
  NewDraftHandCurrentType,
  NgaBidMatchType,
  NgaLineType,
  PicksTradingType,
  PriorityPickType,
  TransactionImpactPriorityPick,
  TransactionImpactType,
} from './type';
import { YearPickDict } from '../header/type';
import { PlanningTradeOfferType } from '../planner/type';
import { generateUniqueId } from '../../util/SiteHelper';

export const ManualPickEditInitialValue: ManualPickEditType = {
  selectPickEditOwner: '',
  newOwnerPick: '',
  mostRecentPick: '',
  selectReasonPickEdit: '',
};

export const DraftNightSelectionInitialValue: DraftNightSelectionType = {
  teamSelectPick: '',
  playerTaken: '',
};

export const ManualPickMoveInitialValue: ManualPickMoveType = {
  team: '',
  pickMove: '',
  round: '',
  pickDestination: '',
  insertInstruction: '',
  enterReason: '',
};

export const ManualPickInsertInitialValue: ManualPickInsertType = {
  team: '',
  round: '',
  pickLocation: '',
  insertInstruction: '',
  enterReason: '',
};

export const NgaBidMatchInitialValue: NgaBidMatchType = {
  teamNgaPlayer: '',
  ngaPlayer: '',
  pickOfBid: '',
};

export const FreeAgentCompensationInitialValue: FreeAgentCompensationType = {
  teamReceivingCompensation: '',
  compensationPickType: '',
  compensationAligned: '',
  pickInstruction: '',
  reason: '',
};

export const PriorityPickInitialValue: PriorityPickType = {
  teamPriorityPick: '',
  typePriorityPick: '',
  pickAligned: '',
  pickInstruction: '',
  reason: '',
};

const tradeOfferInitialValue = (editTrade?: PlanningTradeOfferType, isTradeAnalysis?: boolean) => {
  return isTradeAnalysis
    ? {
        note: editTrade?.notes || '',
        conditionalDetails: editTrade?.conditionalDetails || '',
        created: editTrade?.tradeCreated || new Date().toString(),
        lastModified: editTrade?.tradeLastModified || new Date().toString(),
        offerStatus: editTrade?.tradeStatus || '',
        offerType: editTrade?.tradeOfferType,
      }
    : {};
};

export const CompletedTradeInitialValue = (
  editTrade?: PlanningTradeOfferType,
  isTradeAnalysis?: boolean,
): CompletedTradeType => {
  return {
    team1: {
      name: editTrade?.payload?.teamid1 || '',
      tradingOut: editTrade?.payload?.pick1.map((item) => item.value) || [],
      playerName: editTrade?.payload?.player1.map((item: any) => item.player_id).join(',') || '',
    },
    team2: {
      name: editTrade?.payload?.teamid2 || '',
      tradingOut: editTrade?.payload?.pick2?.map((item) => item.value) || [],
      playerName: editTrade?.payload?.player2?.map((item: any) => item.player_id).join(',') || '',
    },
    ...tradeOfferInitialValue(editTrade, isTradeAnalysis),
  };
};

export const CompletionTradeValidationSchema = object().shape({
  team1: object().shape({
    name: string().required('Please select Team name'),
  }),
  team2: object().shape({
    name: string().required('Please select Team name'),
  }),
});

export const priorityPickValidationSchema = object().shape({
  teamPriorityPick: string().required('Please select Team receiving priority pick'),
  typePriorityPick: string().required('Please select Priority Pick Type'),
  pickAligned: string().required('Please select priority pick'),
  pickInstruction: string().required('Please select Pick Instruction'),
  reason: string().required('Please select reason'),
});

export const AcademyBidValidationSchema = object().shape({
  playerBelongsTo: string().required('Please select Team Academy Player Belongs To'),
  academyPlayer: string().required('Please select Academy Player'),
  bidPick: string().required('Please select Pick of Bid'),
});

export const AcademyBidMatchInitialValue: AcademyBidMatchType = {
  playerBelongsTo: '',
  academyPlayer: '',
  bidPick: '',
};

export const FatherBidMatchInitialValue: FatherBidMatchType = {
  fatherSonBelongTo: '',
  fatherSonPlayers: '',
  pickOfBid: '',
};

export const transformAcademyTeamNameDropdownOption = (data?: any): OptionType[] => {
  if (!data) {
    return [];
  }

  return data.map((item: any) => ({
    value: item.id,
    label: item.TeamNames,
  }));
};

export const transformAcademyPlayerDropdownOption = (data?: any): OptionType[] => {
  if (!data) {
    return [];
  }

  return data
    .filter((item: any) => !item.Selected)
    .map((item: any) => ({
      value: item.Full_Name,
      label: item.Full_Name,
    }));
};

export const transformAcademyPickDataOption = (data?: any): OptionType[] => {
  if (!data) {
    return [];
  }

  return data.map((item: any) => ({
    value: item,
    label: item,
  }));
};

export const transformFaPickDataOption = (data?: any): OptionType[] => {
  if (!data) {
    return [];
  }

  return data.map((item: any) => ({
    value: item.display_name,
    label: item.display_name,
  }));
};

export const transformAcademyAllPickDataOption = (data?: any): OptionType[] => {
  if (!data) {
    return [];
  }

  return data
    .filter((item: any) => item.pick_status !== 'Used')
    .map((item: any) => ({
      value: item.value,
      label: item.label,
      unique: item.unique,
    }));
};

export const transformTradingAllPickDataOption = (data?: any): OptionType[] => {
  if (!data) {
    return [];
  }

  return data.map((item: any) => ({
    value: item.value,
    label: item.label,
    unique: item.unique,
    currentOwner: item.current_owner,
    overallPick: item.overall_pick,
    pickStatus: item.pick_status,
  }));
};

const transformPicksTradingOutDropdown = (data: any): PicksTradingType[] => {
  return Object.values(
    data.reduce((result: any, item: any) => {
      const teamName = item.current_owner;
      if (!result[teamName]) {
        result[teamName] = {
          teamName,
          picksOption: [],
        };
      }
      result[teamName].picksOption.push(item);
      return result;
    }, {}),
  );
};
export const transformMulitNestedPickDataOption = (data?: any): PicksTradingType[] => {
  if (!data) {
    return [];
  }
  const filtereData = data.filter((item: any) => item.pick_status !== 'Used');
  const transformData: PicksTradingType[] = transformPicksTradingOutDropdown(filtereData);
  let currentYear = '';
  let nextYear = '';
  transformData[0].picksOption.forEach((item) => {
    if (currentYear !== '' && nextYear !== '') {
      if (currentYear === nextYear || nextYear === '') {
        nextYear = `${item.year}`;
      }
    } else if (currentYear === '') {
      currentYear = `${item.year}`;
    } else if (currentYear === nextYear || nextYear === '') {
      nextYear = `${item.year}`;
    }
  });

  return transformData.map((item) => ({
    ...item,
    currentYear: currentYear,
    nextYear: nextYear,
  }));
};

const transformDraftHandCurrent = (data: any): NewDraftHandCurrentType[] => {
  if (!data) {
    return [];
  }
  return data.map((item: any) => ({
    key: item.key,
    value: item.value,
  }));
};

export const transformAcademyTransactionData = (data: any): AcademySummaryType | null => {
  if (!data) {
    return null;
  }
  return {
    academySummaryDict: {
      movesTo: data.academy_summary_dict.Moves_To,
      newPointValue: data.academy_summary_dict.New_Points_Value,
      pick: data.academy_summary_dict.Pick,
    },
    newDraftHandCurrent: transformDraftHandCurrent(data.new_draft_hand_current),
    sumLine1: data.sum_line1,
    sumLine2: data.sum_line2,
  };
};

export const transformAcademyRowSummaryData = (data: AcademySummaryDictType) => {
  if (!data) {
    return [];
  }
  const length = max([data.movesTo.length, data.newPointValue.length, data.pick.length]);
  return Array(length)
    .fill(1)
    .map((_, index) => ({
      id: index + 1,
      movesTo: data?.movesTo?.[index] ?? '',
      newPointValue: data?.newPointValue?.[index].toFixed(1) ?? '-',
      pick: data?.pick?.[index] ?? '-',
    }));
};

const transformDraftHand = (data: any): InitialDraftHandType[] => {
  if (!data) return [];

  return data.map(
    (item: any) =>
      ({
        draftRoundInit: item.Draft_Round_Int,
        overallPick: item.Overall_Pick,
        pickStatus: item.Pick_Status,
        year: item.Year,
        yearType: item.Year_Type,
      } as InitialDraftHandType),
  );
};

const transformOverallImpactDict = (data: any): ImpactDictType[] => {
  if (!data) return [];

  return data.map(
    (item: any) =>
      ({
        action: item.Action,
        aflPointValue: item.AFL_Points_Value,
        aflPtsLeft: item.AFL_Pts_Left,
        cumulativePts: item.Cumulative_Pts,
        deficitAmount: item.Deficit_Amount,
        displayNameDetailed: item.Display_Name_Detailed,
        overallPick: item.Overall_Pick,
        payoffDiff: item.Payoff_Diff,
        deficitSummary: item.deficit_summary,
        deficitWarning: item.deficit_warning,
        deficitYear: item.deficit_year,
        pointsDeficit: item.points_deficit,
        deficitImpact: transformDeficitImpact(item.deficit_impact),
      } as ImpactDictType),
  );
};

const transformDeficitImpact = (data: any): DeficitImpactType | undefined => {
  if (!data) {
    return undefined;
  }
  return {
    aflPointsValue: data.AFL_Points_Value,
    draftRound: data.Draft_Round_Int,
    maxDeficitPoints: data.Max_Deficit_Points,
    newOverallPick: data.new_overall_pick,
    overallPick: data.Overall_Pick,
    pointsSubtracted: data.points_subtracted,
  };
};
const transformOverallImpactVisualDict = (data: any): ImpactVisualType[] => {
  if (!data) return [];

  return data.map(
    (item: any) =>
      ({
        firstValueTooltip: item.first_value_tooltip,
        firstVisual: item.first_value,
        pointsRemaining: item.points_remaining,
        prefix: item.prefix,
        secondValue: item.second_value,
        summary: item.summary,
        deficitSummary: item.deficit_summary,
        deficitWarning: item.deficit_warning,
        deficitYear: item.deficit_year,
        pointsDeficit: item.points_deficit,
        deficitImpact: transformDeficitImpact(item.deficit_impact),
      } as ImpactVisualType),
  );
};

export const transformFamilyTransactionData = (data: any): FatherSonDataSummaryType | null => {
  if (!data) {
    return null;
  }

  return {
    bidPickNo: data.bid_pick_no,
    bidSummary: data.bid_summary,
    bidSummary2: data.bid_summary_2,
    bidTeam: data.bid_team,
    draftSurPlus: data.draftsurplus,
    fsTeam: data.fs_team || data.academy_team,
    initialDraftHand: transformDraftHand(data.initial_draft_hand),
    listSpotAvailaible: data.listspotsavailable,
    newDraftHand: transformDraftHand(data.new_draft_hand),
    overallImpactDict: transformOverallImpactDict(data.overall_impact_dict),
    pickUsed: data.picksused,
    playerId: data.playerid,
    overallImpactVisual: transformOverallImpactVisualDict(data.overall_impact_visual),
    pointsRequired: data.points_required,
  };
};

export const transformPickListDropdown = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }

  return data.PicksList.map((item: any) => ({
    value: item.value,
    label: item.label,
    unique: item.unique,
    display: item?.display || '',
  }));
};

export const transformRoundDropdownOption = (data?: any): OptionType[] => {
  if (!data) {
    return [];
  }

  return data.roundslist.map((item: any) => ({
    value: item.id,
    label: item.round,
  }));
};

export const transformCompleteTrade = (data: any): CompleteTradeType[] => {
  if (!data) {
    return [];
  }

  return data.add_trade_popup_api.map((item: any) => ({
    '2023': item['2023'],
    '2024': item['2024'],
    teamName: item.teamName,
    teamId: item.teamId,
    id: item.teamId,
  }));
};

export const transformNgaBidLine = (data: any): NgaLineType | null => {
  if (!data) {
    return null;
  }
  return {
    sumLine1: data?.bid_summary || '',
    sumLine2: data?.bid_summary_2 || '',
    validity: data?.validity || false,
    bidPickNo: data.bid_pick_no,
    bidSummary: data.bid_summary,
    bidSummary2: data.bid_summary_2,
    bidTeam: data.bid_team,
    draftSurPlus: data.draftsurplus,
    initialDraftHand: transformDraftHand(data.initial_draft_hand),
    listSpotAvailaible: data.listspotsavailable,
    newDraftHand: transformDraftHand(data.new_draft_hand),
    overallImpactDict: transformOverallImpactDict(data.overall_impact_dict),
    pickUsed: data.picksused,
    overallImpactVisual: transformOverallImpactVisualDict(data.overall_impact_visual),
    pointsRequired: data.points_required,
    visualVersion: data.visual_version,
    fsTeam: data?.fs_team || '',
    playerId: data?.player_id || '',
  };
};

export const transformTransactionImpact = (data: any): TransactionImpactType | null => {
  if (!data) {
    return null;
  }
  return {
    summaryValidity: data.summary_validity,
    team1PtsOut: data.team1_pts_out,
    team2PtsOut: data.team2_pts_out,
    team1NetResult: data.team1_netresult,
    team2NetResult: data.team2_netresult,
    summaryDescription: data.summary_description,
    pickExistsValididty: {
      checkPickExists: data.pick_exists_valididty.check_pick_exists,
      description: data.pick_exists_valididty.description,
      picksNotValid: data.pick_exists_valididty.picks_not_valid,
    },
    pickMatchValidity: {
      checkPicksMatch: data.pick_match_validity.check_picks_match,
      picksDontMatch: data.pick_match_validity.picks_dont_match.map((item: any) => ({
        oldPick: item.old_pick,
        newPick: item.new_pick,
      })),
      description: data.pick_match_validity.description,
    },
    tradeBackRuleValidity: {
      cannotTradeBack: data.trade_back_rule_validity.cannot_trade_back,
      description: data.trade_back_rule_validity.description,
      tradeBackRule: data.trade_back_rule_validity.trade_back_rule,
    },
  };
};

const transformPickDict = (data: any): YearPickDict[] => {
  if (!data) {
    return [];
  }

  return data.map((item: any) => ({
    pick: item.pick,
    pickStatus: item.pick_status,
    pickDisplay: item?.pick_name || '',
  }));
};

export const transformImpactPriorityPick = (data: any): TransactionImpactPriorityPick | null => {
  if (!data) {
    return null;
  }
  return {
    currentYearPicksDict: transformPickDict(data.current_year_picks_dict),
    impactDescription: data.impact_description,
    nextYearPickDict: transformPickDict(data.next_year_picks_dict),
    teamDraftPointEnd: data.next_year_picks_pts,
    teamDraftPointsImpact: data.team_draft_points_impact,
    teamDraftPointsStart: data.current_year_picks_pts,
    teamId: data.teamid,
  };
};

export const trasnformTradeStatusOption = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        label: item.display_name,
        value: item.status,
      } as OptionType),
  );
};

export const transformTradeTypeOption = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        label: item.display_name,
        value: item.type,
      } as OptionType),
  );
};

export const transformDeficitImpactDataGrid = (
  data: DeficitImpactType,
): DeficitImpactDatagridType[] => {
  const keys = Object.keys(data) as (keyof DeficitImpactType)[];
  const length = data[keys[0]].length;
  const result: DeficitImpactDatagridType[] = [];

  for (let i = 0; i < length; i++) {
    const obj = { id: generateUniqueId() } as DeficitImpactDatagridType;
    keys.forEach((key) => {
      obj[key] = data[key][i];
    });
    result.push(obj);
  }

  return result;
};
