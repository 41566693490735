import React, { FC } from 'react';
import { Popover, Grid } from '@mui/material';
import { OptionButton } from '../style';
import { TransactionMenuOptionType } from '../type';

type Props = {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  handleTransactionSelect: (transactionId: string) => void;
  transactionOptionList: TransactionMenuOptionType[];
};

const TransactionModal: FC<Props> = ({
  anchorEl,
  handleClose,
  handleTransactionSelect,
  transactionOptionList,
}) => {
  return (
    <Popover
      id={'transaction-modal'}
      open
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          borderRadius: 20,
          width: 300,
        },
      }}
    >
      <Grid container p={'20px'}>
        {transactionOptionList.map((item) => (
          <Grid
            onClick={() => handleTransactionSelect(item.value)}
            item
            xs={12}
            mt={'10px'}
            key={item.id}
          >
            <OptionButton display={'flex'} gap={'10px'} alignItems={'center'}>
              {item.label}
            </OptionButton>
          </Grid>
        ))}
      </Grid>
    </Popover>
  );
};

export default TransactionModal;
