import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  addTradePopUpTransactionApi,
  approveTradeOfferApiUrl,
  BASE_URL,
  confirmTradeOfferApiUrl,
  createAcademyBidApi,
  createAddNewPlayerApi,
  createAddNewPlayerCsvApi,
  createAddNewTransactionApiUrl,
  createAddTradeApi,
  createDraftNightSelectionApi,
  createFatherSonBidApi,
  createFreeAgentCompensationApi,
  createManualInsertPickEditApi,
  createManualMovePickEditApi,
  createManualPickEditApi,
  createNewScenarioApiUrl,
  createNgaBidApi,
  createPriorityPickApi,
  createProjectApi,
  createTradeScenarioApiUrl,
  currentPickApi,
  deleteAllScenarioValidity,
  deleteProjectApi,
  deleteSingleScenarioApiUrl,
  deleteTradeOfferApiUrl,
  deleteTransactionApiUrl,
  deleteUnusablePicksApi,
  fetchRosterSpotsApiUrl,
  fetchScenarioValidity,
  fetchScenarioVisualisationApiUrl,
  fetchTradeOfferApiUrl,
  fetchTradePickScenarioApiUrl,
  getAcademyTransactions,
  getAddScenarioTradeToolTip,
  getAddTradeToolTip,
  getAllPicks,
  getCompletedTransactionImpactApiUrl,
  getDashboardApiUrl,
  getDraftOption,
  getFlagApiUrl,
  getNgaTransactionSumApiUrl,
  getPickType,
  getPlayer,
  getPlayerApi,
  getProjects,
  getRoundPickApiUrl,
  getRoundsApi,
  getShowTeam,
  getTradeAlogirhtmDataApi,
  getTradeAlogrithmApi,
  getTradeApi,
  getTransactionsSum,
  getUserInfo,
  registerUserApiUrl,
  updateRosterSpotsApiUrl,
  scenarioPlannerDraftModeApiUrl,
  transactionPriorityPick,
  getScenarioCompletedTransactionImpactApiUrl,
  createPriorityPickScenarioApiUrl,
  priorityPickScenarioImpactApiUrl,
  fecthScenarioPickListApiUrl,
  senarioFatherSonImpactApiUrl,
  createScenarioFatherSonApiUrl,
  createScenarioAcademyBidApiUrl,
  getScenarioAcademyBidImpactApiUrl,
  fetchTradeOfferScriptApiUrl,
  updateTradeOfferApiUrl,
  fetchScenarioAllDraftPickApiUrl,
  fetchTradeOfferStatusApiUrl,
  fetchTradeOfferTypeApiUrl,
  fetchMultiTeamTradeImpact,
  createMultiTradeTeam,
  declineTradeOfferApiUrl,
  freeAgentImpactCompensationApi,
  getFAPickTypeApiUrl,
  fetchPlayerPositionApiUrl,
  getPriorityPickTypeApiUrl,
} from '../../constant/ApiConstant';
import { TradingType } from '../../container/tradeanalysis/type';
import {
  AcademyBidMatchType,
  DraftNightSelectionType,
  FatherBidMatchType,
  ManualPickMoveType,
  NgaBidMatchType,
} from '../../container/transaction/type';
import {
  IReverseCreateProject,
  IReverseFreeAgentCompensation,
  IReverseManualPickEdit,
  IReverseManualPickInsert,
  IReversePriorityPick,
  ReverseAcademyTransactionType,
  ReverseCompletedTradeType,
  ReverseFamilyTransactionType,
} from './type';
import {
  ReverseNgaBidMatchType,
  reverseAcademyBidTransformData,
  reverseCreateNewPlayerCsvTransformData,
  reverseCreateNewPlayerTransformData,
  reverseCreateProjectTransformData,
  reverseDraftNightTransformData,
  reverseFatherBidTransformData,
  reverseFreeAgentTransformData,
  reverseManualInsertTransformData,
  reverseManualMoveTransformData,
  reverseManualTransformData,
  reverseNgaBidTransformData,
  reversePriorityPickTransformData,
  reverseRegsiterUserTransformData,
  reverseTransformTradeInfo,
  reverseTransformUpdateRoster,
} from './util';
import { AddPlayerType } from '../../container/draftboard/type';
import { RegisterType } from '../../container/register/type';
import { RootState } from '../store';
import { SHOW_AUTH0_LOGIN } from '../../util/SiteHelper';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RosterListType } from '../../container/project/type';
import { toast } from 'react-toastify';

export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: async (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token && SHOW_AUTH0_LOGIN) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const showErrorMessage = (data: any) => {
  if (data?.message) {
    toast.error(data.message);
    return;
  }
  if (Object.entries(data).length > 0) {
    Object.entries(data).forEach((item) => {
      toast.error(`${item[0]}: ${item[1]}`);
    });
    return;
  }
  toast.error('Something Went wrong');
};

export const baseQuesryWithErrorToast: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result: any = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.originalStatus === 500) {
    showErrorMessage({ message: 'Something went wrong' });
  } else if (result.error && result.error.originalStatus !== 200) {
    showErrorMessage(`${result?.error?.data}` || 'Something went wrong');
  }
  return result;
};

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 403) {
    document.getElementById('getToken')!.click();
  }
  return result;
};

export const smartDraftApis = createApi({
  reducerPath: 'smartDraftApis',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getFlags: builder.query({
      query: () => getFlagApiUrl,
    }),
    getProjectList: builder.query({
      query: (userId: number) => getProjects(userId),
    }),
    getFlagsToolTip: builder.query({
      query: (projectId: number) => getAddTradeToolTip(projectId),
    }),
    getScenarioTradeToolTip: builder.query({
      query: ({
        projectId,
        scenarioId,
        transactionNumber,
      }: {
        projectId: string;
        scenarioId: string;
        transactionNumber: number;
      }) => getAddScenarioTradeToolTip(projectId, scenarioId, transactionNumber.toString()),
    }),
    getDashboardData: builder.query({
      query: (projectId: number) => getDashboardApiUrl(projectId),
    }),
    getDraftPick: builder.query({
      query: (projectId: number) => getRoundPickApiUrl(projectId),
    }),
    getShowTeam: builder.query({
      query: () => getShowTeam(),
    }),
    getPlayer: builder.query({
      query: (projectId: string) => getPlayer(projectId),
    }),
    getAllPick: builder.query({
      query: (projectId: number) => getAllPicks(projectId),
    }),
    getAllScenarioPick: builder.query({
      query: ({
        projectId,
        scenarioId,
        transactionNo,
      }: {
        projectId: string;
        scenarioId: string;
        transactionNo: string;
      }) => fecthScenarioPickListApiUrl(projectId, scenarioId, transactionNo),
    }),
    getPickType: builder.query({
      query: () => getPickType(),
    }),
    getFAPickType: builder.query({
      query: () => getFAPickTypeApiUrl(),
    }),
    getPriorityPickType: builder.query({
      query: () => getPriorityPickTypeApiUrl(),
    }),
    getAcademyTransaction: builder.mutation({
      query: (data: ReverseAcademyTransactionType) => ({
        url: getAcademyTransactions(data.projectId),
        method: 'POST',
        body: {
          academy_team: data.academy_team.toString(),
          pickid: data.pickid.toString(),
          playerid: data.playerid.toString(),
        },
      }),
    }),
    getFamilyTransaction: builder.mutation({
      query: (data: ReverseFamilyTransactionType) => ({
        url: getTransactionsSum(data.projectId),
        method: 'POST',
        body: {
          fs_team: data.fs_team.toString(),
          pickid: data.pickid.toString(),
          playerid: data.playerid.toString(),
        },
      }),
    }),
    getFamilyScenarioImpactTransaction: builder.mutation({
      query: ({
        projectId,
        scenarioId,
        transactionNo,
        data,
      }: {
        projectId: string;
        scenarioId: string;
        transactionNo: string;
        data: ReverseFamilyTransactionType;
      }) => ({
        url: senarioFatherSonImpactApiUrl(projectId, scenarioId, transactionNo),
        method: 'POST',
        body: {
          fs_team: data.fs_team.toString(),
          pickid: data.pickid.toString(),
          playerid: data.playerid.toString(),
        },
      }),
    }),
    getAcademyScenarioImpactTransaction: builder.mutation({
      query: ({
        projectId,
        scenarioId,
        transactionNo,
        data,
      }: {
        projectId: string;
        scenarioId: string;
        transactionNo: string;
        data: ReverseAcademyTransactionType;
      }) => ({
        url: getScenarioAcademyBidImpactApiUrl(projectId, scenarioId, transactionNo),
        method: 'POST',
        body: {
          academy_team: data.academy_team.toString(),
          pickid: data.pickid.toString(),
          playerid: data.playerid.toString(),
        },
      }),
    }),
    getTrades: builder.query({
      query: (data: { teamId: string; projectId: number }) =>
        getTradeApi(data.teamId, data.projectId),
    }),
    fetchScenarioTradeDraft: builder.query({
      query: (data: { projectId: string; scenarioId: string; transactionNo: string }) =>
        fetchScenarioAllDraftPickApiUrl(data.projectId, data.scenarioId, data.transactionNo),
    }),
    getRounds: builder.query({
      query: () => getRoundsApi(),
    }),
    deleteUnusablePicks: builder.query({
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
      query: (projectId: string) => ({
        url: deleteUnusablePicksApi(projectId),
        method: 'POST',
        body: {},
      }),
    }),
    manualPickEditApi: builder.mutation({
      query: ({ data, projectId }: { data: IReverseManualPickEdit; projectId: string }) => ({
        url: createManualPickEditApi(projectId),
        method: 'POST',
        body: reverseManualTransformData(data),
      }),
    }),
    createDraftNight: builder.mutation({
      query: ({ data, projectId }: { data: DraftNightSelectionType; projectId: string }) => ({
        url: createDraftNightSelectionApi(projectId),
        method: 'POST',
        body: reverseDraftNightTransformData(data),
      }),
    }),
    createManualMove: builder.mutation({
      query: ({ data, projectId }: { data: ManualPickMoveType; projectId: string }) => ({
        url: createManualMovePickEditApi(projectId),
        method: 'POST',
        body: reverseManualMoveTransformData(data),
      }),
    }),
    createManualInsertMove: builder.mutation({
      query: ({ data, projectId }: { data: IReverseManualPickInsert; projectId: string }) => ({
        url: createManualInsertPickEditApi(projectId),
        method: 'POST',
        body: reverseManualInsertTransformData(data),
      }),
    }),
    createNgaBid: builder.mutation({
      query: ({ data, projectId }: { data: NgaBidMatchType; projectId: string }) => ({
        url: createNgaBidApi(projectId),
        method: 'POST',
        body: reverseNgaBidTransformData(data),
      }),
    }),
    createFatherSonBid: builder.mutation({
      query: ({ data, projectId }: { data: FatherBidMatchType; projectId: string }) => ({
        url: createFatherSonBidApi(projectId),
        method: 'POST',
        body: reverseFatherBidTransformData(data),
      }),
    }),
    scenarioCreateFatherSonBid: builder.mutation({
      query: ({
        data,
        projectId,
        scenarioId,
        transactionNo,
      }: {
        data: FatherBidMatchType;
        projectId: string;
        scenarioId: string;
        transactionNo: string;
      }) => ({
        url: createScenarioFatherSonApiUrl(projectId, scenarioId, transactionNo),
        method: 'POST',
        body: reverseFatherBidTransformData(data),
      }),
    }),
    createAcademyBid: builder.mutation({
      query: ({ data, projectId }: { data: AcademyBidMatchType; projectId: string }) => ({
        url: createAcademyBidApi(projectId),
        method: 'POST',
        body: reverseAcademyBidTransformData(data),
      }),
    }),
    createScenarioAcademyBid: builder.mutation({
      query: ({
        data,
        projectId,
        scenarioId,
        transactionId,
      }: {
        data: AcademyBidMatchType;
        projectId: string;
        scenarioId: string;
        transactionId: string;
      }) => ({
        url: createScenarioAcademyBidApiUrl(projectId, scenarioId, transactionId),
        method: 'POST',
        body: reverseAcademyBidTransformData(data),
      }),
    }),
    createFreeAgentCompensation: builder.mutation({
      query: ({ data, projectId }: { data: IReverseFreeAgentCompensation; projectId: string }) => ({
        url: createFreeAgentCompensationApi(projectId),
        method: 'POST',
        body: reverseFreeAgentTransformData(data),
      }),
    }),
    getTransactionImpactFreeAgent: builder.mutation({
      query: ({ data, projectId }: { data: IReverseFreeAgentCompensation; projectId: string }) => ({
        url: freeAgentImpactCompensationApi(projectId),
        method: 'POST',
        body: reverseFreeAgentTransformData(data),
      }),
    }),
    createPriorityPick: builder.mutation({
      query: ({ data, projectId }: { data: IReversePriorityPick; projectId: string }) => ({
        url: createPriorityPickApi(projectId),
        method: 'POST',
        body: reversePriorityPickTransformData(data, projectId),
      }),
    }),
    createScenarioPriorityPick: builder.mutation({
      query: ({
        data,
        projectId,
        scenarioId,
        transactionNo,
      }: {
        data: IReversePriorityPick;
        projectId: string;
        scenarioId: string;
        transactionNo: string;
      }) => ({
        url: createPriorityPickScenarioApiUrl(projectId, scenarioId, transactionNo),
        method: 'POST',
        body: reversePriorityPickTransformData(data, projectId),
      }),
    }),
    getTransactionImpactPriorityPick: builder.mutation({
      query: ({ data, projectId }: { data: IReversePriorityPick; projectId: string }) => ({
        url: transactionPriorityPick(projectId),
        method: 'POST',
        body: reversePriorityPickTransformData(data, projectId),
      }),
    }),
    getScenarioTransactionImpactPriorityPick: builder.mutation({
      query: ({
        data,
        projectId,
        scenarioId,
        transactionNo,
      }: {
        data: IReversePriorityPick;
        projectId: string;
        scenarioId: string;
        transactionNo: string;
      }) => ({
        url: priorityPickScenarioImpactApiUrl(projectId, scenarioId, transactionNo),
        method: 'POST',
        body: reversePriorityPickTransformData(data, projectId),
      }),
    }),
    createAddTrade: builder.mutation({
      query: ({ data, projectId }: { data: ReverseCompletedTradeType; projectId: string }) => ({
        url: createAddTradeApi(projectId),
        method: 'POST',
        body: data,
      }),
    }),
    createNewTransaction: builder.mutation({
      query: ({ data, projectId }: { data: ReverseCompletedTradeType; projectId: string }) => ({
        url: createAddNewTransactionApiUrl(projectId),
        method: 'POST',
        body: data,
      }),
    }),
    updateTradeOfferTransaction: builder.mutation({
      query: ({
        data,
        projectId,
        tradeId,
      }: {
        data: ReverseCompletedTradeType;
        projectId: string;
        tradeId: string;
      }) => ({
        url: updateTradeOfferApiUrl(projectId, tradeId),
        method: 'PUT',
        body: data,
      }),
    }),
    fetchTradeOfferList: builder.query({
      query: (projectId: string) => fetchTradeOfferApiUrl(projectId),
    }),
    getCurrentPick: builder.query({
      query: (projectId: string) => currentPickApi(projectId),
    }),
    createProject: builder.mutation({
      query: ({ data }: { data: IReverseCreateProject }) => ({
        url: createProjectApi(),
        method: 'POST',
        body: reverseCreateProjectTransformData(data),
      }),
    }),
    deleteProject: builder.mutation({
      query: (projectId: string) => ({
        url: deleteProjectApi(projectId),
        method: 'DELETE',
      }),
    }),
    getPlayers: builder.query({
      query: (projectId: string) => getPlayerApi(projectId),
    }),
    getAddTradePopupTransaction: builder.query({
      query: (projectId: string) => addTradePopUpTransactionApi(projectId),
    }),
    getTradeAlogrithm: builder.query({
      query: (data: { projectId: string; userId: number }) =>
        getTradeAlogrithmApi(data.projectId, data.userId),
    }),
    getNgaTransactionSum: builder.mutation({
      query: ({ projectId, data }: { projectId: string; data: NgaBidMatchType }) => ({
        url: getNgaTransactionSumApiUrl(projectId),
        method: 'POST',
        body: ReverseNgaBidMatchType(data),
      }),
    }),
    getTradeAlogrithmData: builder.mutation({
      query: ({
        data,
        userId,
        projectId,
        teamId,
      }: {
        data: TradingType;
        userId: number;
        projectId: string;
        teamId: string;
      }) => ({
        url: getTradeAlogirhtmDataApi(projectId, '2'),
        method: 'POST',
        body: reverseTransformTradeInfo(data, userId),
      }),
    }),
    createNewPlayer: builder.mutation({
      query: ({ data, projectId }: { data: AddPlayerType; projectId: string }) => ({
        url: createAddNewPlayerApi(projectId),
        method: 'POST',
        body: reverseCreateNewPlayerTransformData(data, projectId),
      }),
    }),
    getPlayerPosition: builder.query({
      query: () => fetchPlayerPositionApiUrl(),
    }),
    createNewPlayerCsv: builder.mutation({
      query: ({ data, projectId }: { data: AddPlayerType; projectId: string }) => ({
        url: createAddNewPlayerCsvApi(),
        method: 'POST',
        body: reverseCreateNewPlayerCsvTransformData(data, projectId),
      }),
    }),
    getUserDetail: builder.query({
      query: () => getUserInfo(),
    }),
    getDraftOption: builder.query({
      query: () => getDraftOption(),
    }),
    registeruser: builder.mutation({
      query: ({ data }: { data: RegisterType }) => ({
        url: registerUserApiUrl(),
        method: 'POST',
        body: reverseRegsiterUserTransformData(data),
      }),
    }),
    getCompleteTransactionImpactData: builder.mutation({
      query: ({ data, projectId }: { data: ReverseCompletedTradeType; projectId: string }) => ({
        url: getCompletedTransactionImpactApiUrl(projectId),
        method: 'POST',
        body: data,
      }),
    }),
    getScenarioCompleteTransactionImpactData: builder.mutation({
      query: ({
        data,
        projectId,
        scenarioId,
        transactionId,
      }: {
        data: ReverseCompletedTradeType;
        projectId: string;
        scenarioId: string;
        transactionId: string;
      }) => ({
        url: getScenarioCompletedTransactionImpactApiUrl(projectId, scenarioId, transactionId),
        method: 'POST',
        body: data,
      }),
    }),
    deleteTradeOffer: builder.mutation({
      query: ({ projectId, tradeOfferId }: { projectId: string; tradeOfferId: string }) => ({
        url: deleteTradeOfferApiUrl(projectId, tradeOfferId),
        method: 'DELETE',
      }),
    }),
    confirmTradeOffer: builder.mutation({
      query: ({ projectId, tradeOfferId }: { projectId: string; tradeOfferId: string }) => ({
        url: confirmTradeOfferApiUrl(projectId, tradeOfferId),
        method: 'POST',
        body: {},
      }),
    }),
    getScenarioValidity: builder.query({
      query: (projectId: string) => fetchScenarioValidity(projectId),
    }),
    deleteAllScenarioValidity: builder.mutation({
      query: (projectId: string) => ({
        url: deleteAllScenarioValidity(projectId),
        method: 'DELETE',
      }),
    }),
    getScenarioVisualisation: builder.query({
      query: ({ projectId, teamId }: { projectId: string; teamId: string }) =>
        fetchScenarioVisualisationApiUrl(projectId, teamId),
    }),
    createNewScenario: builder.mutation({
      query: (projectId: string) => ({
        url: createNewScenarioApiUrl(projectId),
        method: 'POST',
        body: {},
      }),
    }),
    deleteSingleScenarioValidity: builder.mutation({
      query: ({ projectId, scenarioId }: { projectId: string; scenarioId: string }) => ({
        url: deleteSingleScenarioApiUrl(projectId, scenarioId),
        method: 'DELETE',
      }),
    }),
    deleteTransaction: builder.mutation({
      query: ({
        projectId,
        scenarioId,
        transactionId,
      }: {
        projectId: string;
        scenarioId: string;
        transactionId: string;
      }) => ({
        url: deleteTransactionApiUrl(projectId, scenarioId, transactionId),
        method: 'DELETE',
      }),
    }),
    createTradeScenario: builder.mutation({
      query: ({
        data,
        projectId,
        scenarioId,
        transactionNumber,
      }: {
        data: ReverseCompletedTradeType;
        projectId: string;
        scenarioId: string;
        transactionNumber: string;
      }) => ({
        url: createTradeScenarioApiUrl(projectId, scenarioId, transactionNumber),
        method: 'POST',
        body: data,
      }),
    }),
    approveTradeOffer: builder.mutation({
      query: ({ projectId, tradeOfferId }: { projectId: string; tradeOfferId: string }) => ({
        url: approveTradeOfferApiUrl(projectId, tradeOfferId),
        method: 'POST',
        body: {},
      }),
    }),
    getScenarioPickTrades: builder.query({
      query: (data: {
        teamId: string;
        projectId: string;
        scenarioId: string;
        transactionNumber: string;
      }) =>
        fetchTradePickScenarioApiUrl(
          data.projectId,
          data.scenarioId,
          data.teamId,
          data.transactionNumber,
        ),
    }),
    fetchRosterSpots: builder.query({
      query: ({ projectId }: { projectId: string }) => fetchRosterSpotsApiUrl(projectId),
    }),
    updateRosterSpot: builder.mutation({
      query: ({ projectId, data }: { projectId: string; data: RosterListType[] }) => ({
        url: updateRosterSpotsApiUrl(projectId),
        method: 'PUT',
        body: reverseTransformUpdateRoster(data),
      }),
    }),
    deleteEnterDraftMode: builder.mutation({
      query: ({
        projectId,
        scenarioId,
        transactionId,
      }: {
        projectId: string;
        scenarioId: string;
        transactionId: string;
      }) => ({
        url: scenarioPlannerDraftModeApiUrl(projectId, scenarioId, transactionId),
        method: 'POST',
        body: {},
      }),
    }),
    fetchTradeOfferScript: builder.query({
      query: ({ projectId, tradeId }: { projectId: string; tradeId: string }) =>
        fetchTradeOfferScriptApiUrl(projectId, tradeId),
    }),
    fetchTradeOfferStatus: builder.query({
      query: () => fetchTradeOfferStatusApiUrl(),
    }),
    fetchTradeOfferType: builder.query({
      query: () => fetchTradeOfferTypeApiUrl(),
    }),
    getCompleteMultiTransactionImpactData: builder.mutation({
      query: ({ data, projectId }: { data: any; projectId: string }) => ({
        url: fetchMultiTeamTradeImpact(projectId),
        method: 'POST',
        body: data,
      }),
    }),
    createMultiTransactionImpactData: builder.mutation({
      query: ({ data, projectId }: { data: any; projectId: string }) => ({
        url: createMultiTradeTeam(projectId),
        method: 'POST',
        body: data,
      }),
    }),
    declineTradeOffer: builder.mutation({
      query: ({ projectId, tradeId }: { projectId: string; tradeId: string }) => ({
        url: declineTradeOfferApiUrl(projectId, tradeId),
        method: 'PUT',
        body: {},
      }),
    }),
  }),
});

export const {
  useGetFlagsQuery,
  useLazyGetFlagsQuery,
  useGetProjectListQuery,
  useLazyGetProjectListQuery,
  useLazyGetFlagsToolTipQuery,
  useLazyGetDashboardDataQuery,
  useLazyGetDraftPickQuery,
  useGetShowTeamQuery,
  useGetPlayerQuery,
  useRegisteruserMutation,
  useLazyGetUserDetailQuery,
  useGetAllPickQuery,
  useLazyGetAllPickQuery,
  useGetPickTypeQuery,
  useGetAcademyTransactionMutation,
  useGetFamilyTransactionMutation,
  useLazyGetTradesQuery,
  useGetRoundsQuery,
  useLazyDeleteUnusablePicksQuery,
  useManualPickEditApiMutation,
  useCreateDraftNightMutation,
  useCreateManualMoveMutation,
  useCreateManualInsertMoveMutation,
  useCreateNgaBidMutation,
  useCreateFatherSonBidMutation,
  useCreateAcademyBidMutation,
  useCreateFreeAgentCompensationMutation,
  useCreatePriorityPickMutation,
  useCreateAddTradeMutation,
  useCreateNewTransactionMutation,
  useGetCurrentPickQuery,
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetPlayersQuery,
  useLazyGetPlayersQuery,
  useGetAddTradePopupTransactionQuery,
  useGetTradeAlogrithmQuery,
  useGetTradeAlogrithmDataMutation,
  useCreateNewPlayerCsvMutation,
  useCreateNewPlayerMutation,
  useGetNgaTransactionSumMutation,
  useGetDraftOptionQuery,
  useGetCompleteTransactionImpactDataMutation,
  useFetchTradeOfferListQuery,
  useLazyFetchTradeOfferListQuery,
  useDeleteTradeOfferMutation,
  useConfirmTradeOfferMutation,
  useGetScenarioValidityQuery,
  useDeleteAllScenarioValidityMutation,
  useLazyGetScenarioVisualisationQuery,
  useCreateNewScenarioMutation,
  useDeleteSingleScenarioValidityMutation,
  useDeleteTransactionMutation,
  useCreateTradeScenarioMutation,
  useLazyGetScenarioPickTradesQuery,
  useGetScenarioTradeToolTipQuery,
  useDeleteEnterDraftModeMutation,
  useApproveTradeOfferMutation,
  useLazyFetchRosterSpotsQuery,
  useUpdateRosterSpotMutation,
  useGetTransactionImpactPriorityPickMutation,
  useGetScenarioCompleteTransactionImpactDataMutation,
  useCreateScenarioPriorityPickMutation,
  useGetScenarioTransactionImpactPriorityPickMutation,
  useGetAllScenarioPickQuery,
  useLazyGetAllScenarioPickQuery,
  useGetFamilyScenarioImpactTransactionMutation,
  useScenarioCreateFatherSonBidMutation,
  useGetAcademyScenarioImpactTransactionMutation,
  useCreateScenarioAcademyBidMutation,
  useFetchTradeOfferScriptQuery,
  useUpdateTradeOfferTransactionMutation,
  useFetchScenarioTradeDraftQuery,
  useLazyFetchTradeOfferStatusQuery,
  useLazyFetchTradeOfferTypeQuery,
  useGetCompleteMultiTransactionImpactDataMutation,
  useCreateMultiTransactionImpactDataMutation,
  useDeclineTradeOfferMutation,
  useGetTransactionImpactFreeAgentMutation,
  useGetFAPickTypeQuery,
  useGetPriorityPickTypeQuery,
  useGetPlayerPositionQuery,
} = smartDraftApis;
