import { Grid } from '@mui/material';
import Contract from '../../playerdatabase/component/Contract';

type PlayerContractHistoryProps = {
  playerId: string;
};

const PlayerContractHistory = ({ playerId }: PlayerContractHistoryProps) => {
  return (
    <Grid item xs={12}>
      {playerId && (
        <>
          <Contract playerId={playerId} />
        </>
      )}
    </Grid>
  );
};

export default PlayerContractHistory;
