import { useEffect, useMemo, useState } from 'react';
import { useCurrentProject } from '../../store/header/selector';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  useApproveTradeOfferMutation,
  useDeclineTradeOfferMutation,
  useDeleteTradeOfferMutation,
  useFetchTradeOfferScriptQuery,
  useLazyFetchTradeOfferListQuery,
} from '../../store/service';
import { transformEmailInfo, transformTradeData } from './util';
import { PlanningTradeOfferType, TradeStatus } from './type';
import { toast } from 'react-toastify';
import { setRefreshFlag } from '../../store/header';

export const usePlanningTradeOfferHook = () => {
  const currentProject = useAppSelector(useCurrentProject);
  const [fetchTradeOffer, { isLoading, data }] = useLazyFetchTradeOfferListQuery();
  const [openTransationModal, setOpenTransactionModal] = useState<PlanningTradeOfferType | boolean>(
    false,
  );
  const [tradeOfferList, setTradeOfferList] = useState<PlanningTradeOfferType[]>([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<PlanningTradeOfferType | null>(null);
  const [showDeclineOfferConfirmation, setShowDeclineOfferConfirmation] =
    useState<PlanningTradeOfferType | null>(null);

  const [showAcceptConfirmationModal, setShowAcceptConfirmationModal] =
    useState<PlanningTradeOfferType | null>(null);
  const [emailTrade, setEmailTrade] = useState<PlanningTradeOfferType | null>(null);
  const [, setEditTrade] = useState<PlanningTradeOfferType | null>(null);

  const dispatch = useAppDispatch();
  const [deleteTrade, { isLoading: deleteTradeOfferLoading }] = useDeleteTradeOfferMutation();

  const [confirmTrade, { isLoading: confirmTradeOfferLoading }] = useApproveTradeOfferMutation();
  const [declineTrade, { isLoading: declineTradeLoading }] = useDeclineTradeOfferMutation();

  const [selectedTradeStatus, setSelectedTradeStatus] = useState<TradeStatus>(TradeStatus.ALL);
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  useEffect(() => {
    if (data) {
      setTradeOfferList(transformTradeData(data));
    }
  }, [data]);

  const fetchData = () => {
    setOpenTransactionModal(false);
    fetchTradeOffer(currentProject).then(() => setOpenTransactionModal(false));
  };

  const deleteTradeSubmit = () => {
    showDeleteConfirmation &&
      deleteTrade({
        projectId: currentProject,
        tradeOfferId: showDeleteConfirmation.id.toString(),
      })
        .then((res: any) => {
          fetchData();
          toast.success(res.data.Success);
        })
        .finally(() => {
          setShowDeleteConfirmation(null);
        });
  };

  const declineTradeOfferSubmit = () => {
    showDeclineOfferConfirmation &&
      declineTrade({
        projectId: currentProject,
        tradeId: showDeclineOfferConfirmation.id.toString(),
      })
        .then((res: any) => {
          fetchData();
          toast.success(res.data.Success);
        })
        .finally(() => {
          setShowDeclineOfferConfirmation(null);
        });
  };

  const acceptTradeSubmit = () => {
    showAcceptConfirmationModal &&
      confirmTrade({
        projectId: currentProject,
        tradeOfferId: showAcceptConfirmationModal.id.toString(),
      })
        .then((res: any) => {
          fetchData();
          dispatch(setRefreshFlag(Math.random().toString()));
          setShowDeleteConfirmation(showAcceptConfirmationModal);
          toast.success(res.data.Success);
        })
        .finally(() => {
          setShowAcceptConfirmationModal(null);
        });
  };

  return {
    refreshData: fetchData,
    isLoading,
    openTransationModal,
    setOpenTransactionModal,
    tradeOfferList,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    deleteTradeSubmit,
    deleteTradeOfferLoading,
    setShowAcceptConfirmationModal,
    showAcceptConfirmationModal,
    acceptTradeSubmit,
    confirmTradeOfferLoading,
    setEmailTrade,
    emailTrade,
    currentProject,
    setEditTrade,
    selectedTradeStatus,
    setSelectedTradeStatus,
    showDeclineOfferConfirmation,
    setShowDeclineOfferConfirmation,
    declineTradeOfferSubmit,
    declineTradeLoading,
  };
};

export const useEmailTradeOffer = (tradeOfferId: string, projectId: string) => {
  const { data, isLoading } = useFetchTradeOfferScriptQuery({
    projectId: projectId,
    tradeId: tradeOfferId,
  });

  const emailInfo = useMemo(() => transformEmailInfo(data), [data]);
  return {
    emailInfo,
    isLoading,
  };
};
