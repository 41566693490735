import { Drawer, Grid } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header1, Header2 } from '../../common/component/Text';
import PlanningTradeOffer from './component/PlanningTradeOffer';

type PlannerType = {
  open: boolean;
  onClose: () => void;
};
const Planner: FC<PlannerType> = ({ onClose, open }) => {
  const navigate = useNavigate();
  return (
    <Drawer
      PaperProps={{
        sx: {
          width: '40%',
        },
      }}
      anchor={'right'}
      open={open}
      onClose={onClose}
    >
      <Grid container px={'40px'} py={'10px'}>
        <Grid item xs={12}>
          <Header1 style={{ textAlign: 'center' }}>Trade Planner</Header1>
        </Grid>
        <Grid item xs={12} mt={'6px'}>
          <Header2
            textsize='14px'
            fontcolor='#7D003B'
            style={{ textAlign: 'center', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              navigate('/tradeAnalysis');
              onClose();
            }}
          >
            Go to Trade Analysis
          </Header2>
        </Grid>
        <Grid item xs={12} mt={'12px'}>
          <PlanningTradeOffer />
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default Planner;
