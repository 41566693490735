import { OptionType } from '../../common/fields/type';
import { IProjectList } from '../../store/projects/type';
import { FlagTooltipType, YearPickDict } from './type';

export const transactionMenuOption = [
  {
    id: 'transaction_1',
    value: 'completed_trade',
    label: 'Completed Trade',
  },
  {
    id: 'transaction_completed_trade',
    value: 'multi_completed_trade',
    label: 'Multi Team Trade',
  },
  {
    id: 'transaction_2',
    value: 'priority_pick',
    label: 'Priority Pick',
  },
  {
    id: 'transaction_3',
    value: 'free_agent_compensation',
    label: 'Free Agent Compensation',
  },
  {
    id: 'transaction_4',
    value: 'academy_bid_match',
    label: 'Academy Bid Match',
  },
  {
    id: 'transaction_5',
    value: 'father_son_bid_match',
    label: 'Father Son Bid Match',
  },
  {
    id: 'transaction_6',
    value: 'nga_bid_match',
    label: 'NGA Bid Match',
  },
  // {
  //     id: 'transaction_7',
  //     value: 'manual_pick_insert',
  //     label: 'Manual Pick Insert'
  // },
  // {
  //     id: 'transaction_8',
  //     value: 'manual_pick_move',
  //     label: 'Manual Pick Move'
  // },
  {
    id: 'transaction_9',
    value: 'draft_night_selection',
    label: 'Draft Night Selection',
  },
  {
    id: 'transaction_10',
    value: 'manual_pick_edit',
    label: 'Manual Pick Edit',
  },
  {
    id: 'transaction_12',
    value: 'pass_picks',
    label: 'Pass Picks',
  },
  {
    id: 'transaction_11',
    value: 'delete_unusable_picks',
    label: 'Enter Draft Mode',
  },
];

export const transformProjectList = (data: any): IProjectList[] => {
  if (!data) {
    return [];
  }
  return data.map((item: Record<string, any>) => ({
    file: item.file,
    id: item.id,
    projectDescription: item.project_desc,
    projectName: item.project_name,
    teamId: item.teamid,
    year: item.year,
  }));
};

export const transformProjectDropdownOption = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }
  return data.map((item: Record<string, any>) => ({
    value: item.id,
    label: item.project_name,
    unique: item.year,
  }));
};

const transformPickDict = (data: any): YearPickDict[] => {
  if (!data) {
    return [];
  }

  return data.map((item: any) => ({
    pick: item.pick,
    pickStatus: item.pick_status,
    pickDisplay: item?.pick_display || '',
    clubPickNumber: item?.club_pick_number,
  }));
};
export const transformFlagTooltip = (data: any): FlagTooltipType[] => {
  if (!data) {
    return [];
  }

  return data.map(
    (item: any, index: number) =>
      ({
        currentYear: item.current_year,
        currentYearPicks: item.current_year_picks,
        currentYearsPts: item.current_year_pts,
        currentYearPickDict: transformPickDict(item.current_year_picks_dict),
        nextYear: item.next_year,
        nextYearPicks: item.next_year_picks,
        nextYearPicksDict: transformPickDict(item.next_year_picks_dict),
        nextYersPts: item.next_year_pts,
        picksUsed: item.picks_used,
        teamId: item.team_id,
        teamName: item.team_name,
        id: index + 1,
        currentYearPtsDiff: item.current_year_pts_diff,
        currentYearPtsRemaining: item.current_year_pts_remaining,
        nextYearPtsDiff: item.next_year_pts_diff,
        nextYearPtsRemaining: item?.next_year_pts_remaining || item.nex_year_pts_remaining,
        currentYearPickRemaining: item.current_year_picks_remaining,
        currentYearRosterSpots: item.roster_spots_current_year,
        transactionNetResult: item.transaction_net_result,
      } as FlagTooltipType),
  );
};

export const URL_WITH_NO_PADDING_HEADER = ['/draftpick'];
