import React, { FC } from 'react';
import { Grid } from '@mui/material';
import CustomModal from './CustomModal';
import { Header2 } from './Text';

type Props = {
  handleClose: () => void;
  description: string;
  modalTitle: string;
  handleSubmit: () => void;
  isLoading: boolean;
};
const ConfirmModal: FC<Props> = ({
  handleClose,
  description,
  modalTitle,
  handleSubmit,
  isLoading,
}) => (
  <CustomModal
    confirmButtonLabel='Confirm'
    handleSubmit={handleSubmit}
    saveLoading={isLoading}
    modalSize={'xs'}
    handleClose={handleClose}
    modalTitle={modalTitle}
  >
    <Grid>
      <Header2>{description}</Header2>
    </Grid>
  </CustomModal>
);

export default ConfirmModal;
