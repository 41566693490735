import React, { FC } from 'react';
import { Box, FormHelperText, TextField, TextFieldProps, Tooltip } from '@mui/material';
import { FieldLabel } from '../../component/Text';
import { StyledTextfieldContainer } from '../Style';
import { UseFormRegister, FieldErrors, FieldValues } from 'react-hook-form';
import { TEXT_COLOR_THEME } from '../../../theme/color';
import { InfoOutlined } from '@mui/icons-material';

type CustomTextInputProps = {
  name: string;
  label?: string;
  showError?: boolean;
  register: UseFormRegister<any>; //TODO: fix type
  errors?: FieldErrors<FieldValues>;
  isMandatory?: boolean;
  tooltipInfo?: string;
} & TextFieldProps;

const CustomTextInput: FC<CustomTextInputProps> = ({
  name,
  label,
  showError,
  register,
  errors,
  isMandatory,
  tooltipInfo,
  ...rest
}) => {
  return (
    <Box>
      <FieldLabel display={'flex'} alignItems={'center'}>
        {label}
        {isMandatory && <sup style={{ color: TEXT_COLOR_THEME.error }}>*</sup>}
        {tooltipInfo && (
          <Tooltip title={tooltipInfo}>
            <InfoOutlined style={{ marginLeft: 10 }} />
          </Tooltip>
        )}
      </FieldLabel>
      <StyledTextfieldContainer iserror={!!(errors && errors[name]?.message) ? 1 : 0}>
        <TextField
          {...register(name)}
          name={name}
          autoComplete={'off'}
          size={'small'}
          error={!!(errors && errors[name]?.message)}
          variant='standard'
          {...rest}
        />
        {errors
          ? [name] && (
              <FormHelperText style={{ color: TEXT_COLOR_THEME.error }}>
                {(errors[name]?.message as string) || ''}
              </FormHelperText>
            )
          : null}
      </StyledTextfieldContainer>
    </Box>
  );
};

export default CustomTextInput;
