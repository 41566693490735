import { IUserInfo } from '../store/auth/type';

export const LoginHelper = {
  getUser: () => {
    const userStr = localStorage.getItem('small_contract');
    return userStr ? JSON.parse(userStr) : null;
  },
  removeUserSession: () => localStorage.removeItem('small_contract'),
  setUserSession: (userDetail: IUserInfo) =>
    localStorage.setItem('small_contract', JSON.stringify(userDetail)),
};
