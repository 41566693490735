import { Grid } from '@mui/material';
import React, { FC, useState } from 'react';
import { FlagTooltipType } from '../../header/type';
import { StyledCustomTooltipContainer, StyledFlag } from '../style';
import { IFlagList } from '../type';
import CustomTooltip, { CustomStyleTooltip } from './CustomTooltip';
import { TeamFlagOptions } from '../../../constant/OptionConstant';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setHoveredTeamId } from '../../../store/header';
import { useCurrentProject } from '../../../store/header/selector';
import ProjectSettingModal from '../../project/component/ProjectSettingModal';

type Props = {
  flagList: IFlagList[];
  selectedFlag: string;
  setSelectedFlag: (flag: string) => void;
  flagTooltip: FlagTooltipType[];
  refreshTooltip?: () => void;
};

const FlagList: FC<Props> = ({
  flagList,
  selectedFlag,
  setSelectedFlag,
  flagTooltip,
  refreshTooltip,
}) => {
  const dispatch = useAppDispatch();
  const currentProject = useAppSelector(useCurrentProject);
  const [openRosterModal, setOpenRosterModal] = useState(false);

  return (
    <>
      <Grid display={'flex'} flexWrap={'wrap'} gap={'10px'}>
        {flagList.map((item, index) => (
          <StyledCustomTooltipContainer item key={item.id}>
            <CustomStyleTooltip
              arrow
              onMouseEnter={() => {
                dispatch(setHoveredTeamId(item.id));
              }}
              onMouseLeave={() => {
                dispatch(setHoveredTeamId(''));
              }}
              title={
                flagTooltip.find((flag) => flag.teamName === item.shortName) ? (
                  <CustomTooltip
                    setRosterModalStatus={() => setOpenRosterModal(true)}
                    data={flagTooltip.find((flag) => flag.teamId.toString() === item.id.toString())}
                  />
                ) : (
                  ''
                )
              }
            >
              <StyledFlag
                onClick={() => setSelectedFlag(item.id)}
                isactive={selectedFlag === item.id ? 1 : 0}
                src={TeamFlagOptions.find((flag) => flag.id === Number(item.id))?.flagIcon || ''}
                alt={item.shortName}
              />
            </CustomStyleTooltip>
          </StyledCustomTooltipContainer>
        ))}
        {openRosterModal && (
          <ProjectSettingModal
            handleClose={() => setOpenRosterModal(false)}
            projectId={currentProject}
            refreshList={refreshTooltip}
          />
        )}
      </Grid>
    </>
  );
};
export default FlagList;
