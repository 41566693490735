import Styled from '@emotion/styled';
import { Grid, Avatar } from '@mui/material';
import { Header2 } from '../../common/component/Text';
import { HEADER_COLOR } from '../../theme/color';

export const HeaderContainer = Styled(Grid)`
    background: ${HEADER_COLOR.background};
    border-radius: 10px;
`;

export const StyledAvatar = Styled(Avatar)`
    cursor: pointer;
    &:hover {
        box-shadow: 1px 1px 10px grey;
    }
`;

export const OptionButton = Styled(Header2)`
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }
`;
