import Grid from '@mui/material/Grid';
import { FC, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { setIsSideBarOpen, setSelectedTransactionModal } from '../../store/header';
import {
  useSelectedTransactionModal,
  useSideBarStatus,
  useUploadCsvTransactionType,
} from '../../store/header/selector';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { BACKGROUND_COLOR } from '../../theme/color';
import Header from '../header';
import TransactionModal from '../transaction';
import Sidebar from './component/Sidebar';

type Props = {
  children: JSX.Element;
};

const SideNavbar: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const isSideBarOpen = useAppSelector(useSideBarStatus);
  const dispatch = useAppDispatch();
  const selectedTransactionModal = useAppSelector(useSelectedTransactionModal);
  const uploadCsvTransactionType = useAppSelector(useUploadCsvTransactionType);

  return (
    <Grid height={'100vh'} flexWrap={'nowrap'} p={'10px'} bgcolor={BACKGROUND_COLOR} container>
      <Grid item width={isSideBarOpen ? '240px' : '60px'} zIndex={9}>
        <Sidebar
          navigate={(to) => navigate(to)}
          setIsSideBarOpen={(isOpen) => dispatch(setIsSideBarOpen(isOpen))}
          isSideBarOpen={isSideBarOpen}
        />
      </Grid>
      <Grid px={'20px'} item width={`calc(100% - ${isSideBarOpen ? 240 : 60}px)`}>
        <Header>
          <Suspense fallback={'..'}>{children}</Suspense>
        </Header>
      </Grid>
      <TransactionModal
        closeTransactionModal={() => dispatch(setSelectedTransactionModal(''))}
        selectedTransactionModal={selectedTransactionModal}
        uploadCsvTransactionType={uploadCsvTransactionType}
      />
    </Grid>
  );
};

export default SideNavbar;
