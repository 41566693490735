import { baseQuesryWithErrorToast } from '..';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  createOrganisationApiUrl,
  deleteOrganisationApiUrl,
  getOrganisationListApiUrl,
  linkOrganisationApiUrl,
  unlinkOrganisationApiUrl,
  updateOrganisationApiUrl,
} from '../../../constant/ApiConstant';
import { AddOrganisationType } from '../../../container/organisation/type';
import { reverseTransformOrganisationData } from './util';

export const organisationServiceApi = createApi({
  reducerPath: 'organisationservice',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQuesryWithErrorToast,
  endpoints: (builder) => ({
    getOrganisation: builder.query({
      query: () => getOrganisationListApiUrl(),
    }),
    createNewOrganisation: builder.mutation({
      query: ({ data }: { data: AddOrganisationType }) => ({
        url: createOrganisationApiUrl(),
        method: 'POST',
        body: reverseTransformOrganisationData(data),
      }),
    }),
    deleteOrganisation: builder.mutation({
      query: (orgId: string) => ({
        url: deleteOrganisationApiUrl(orgId),
        method: 'DELETE',
      }),
    }),
    updateOrganisation: builder.mutation({
      query: ({ data, orgId }: { data: AddOrganisationType; orgId: string }) => ({
        url: updateOrganisationApiUrl(orgId),
        method: 'PUT',
        body: reverseTransformOrganisationData(data),
      }),
    }),
    linkOrganisation: builder.mutation({
      query: ({ currentuserId, orgId }: { currentuserId: string; orgId: string }) => ({
        url: linkOrganisationApiUrl(orgId, currentuserId),
        method: 'POST',
        body: {
          roles: [],
        },
      }),
    }),
    unLinkOrganisation: builder.mutation({
      query: ({ currentuserId, orgId }: { currentuserId: string; orgId: string }) => ({
        url: unlinkOrganisationApiUrl(orgId, currentuserId),
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetOrganisationQuery,
  useCreateNewOrganisationMutation,
  useDeleteOrganisationMutation,
  useUpdateOrganisationMutation,
  useLinkOrganisationMutation,
  useUnLinkOrganisationMutation,
} = organisationServiceApi;
