import { StyledCard } from '../../../common/component/Card';
import { Header2, Header3 } from '../../../common/component/Text';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Box, Grid, TextField } from '@mui/material';
import { PlayerDatabaseType } from '../../playerdatabase/type';
import { BG_COLOR } from '../../../theme/color';
import { useDragPlayerList } from '../hook';
import CustomSelect from '../../../common/fields/reacthookfield/CustomSelect';
import { getYearOptions } from '../../../util/SiteHelper';
import { PrimaryTextButton } from '../../../common/component/Button';
import { filterPlayerBySearching } from '../util';

type DragPlayerListProps = {
  playerList: PlayerDatabaseType[];
};

const DragPlayerList = ({ playerList }: DragPlayerListProps) => {
  const { control, reset, register, group, year, searchedPlayer, setSearchedPlayer } =
    useDragPlayerList();
  return (
    <StyledCard container>
      <Grid item xs={12}>
        <CustomSelect
          label={'Select Group'}
          fullWidth
          control={control}
          id='group'
          register={register}
          name='group'
          options={[]}
          value={group}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomSelect
          label={'Select Year'}
          fullWidth
          control={control}
          id='year'
          name='year'
          value={year}
          register={register}
          options={getYearOptions()}
        />
      </Grid>

      <Grid item xs={12} mt={'5px'}>
        <PrimaryTextButton onClick={() => reset()} title='Clear' />
      </Grid>
      <Grid item xs={12} mt={'10px'}>
        <Header3>Player List</Header3>
        <Grid mt={'10px'} item xs={12}>
          <TextField
            fullWidth
            size='small'
            placeholder='Search Player'
            value={searchedPlayer}
            onChange={(e) => setSearchedPlayer(e.target.value)}
          />
        </Grid>
        <Droppable droppableId='playerList'>
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {filterPlayerBySearching(playerList, searchedPlayer).map((player, index) => (
                <Draggable draggableId={player.id.toString()} index={index} key={player.id}>
                  {({ dragHandleProps, draggableProps, innerRef }) => (
                    <Box
                      bgcolor={BG_COLOR.white}
                      ref={innerRef}
                      border={'1px #efefef solid'}
                      key={index}
                      display={'flex'}
                      gap={'20px'}
                      mt={'10px'}
                      px={'10px'}
                      alignItems={'center'}
                      {...draggableProps}
                      {...dragHandleProps}
                    >
                      <Header2
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {player.firstName} {player.lastName}
                      </Header2>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </Grid>
    </StyledCard>
  );
};

export default DragPlayerList;
