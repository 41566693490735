import { FC } from 'react';
import CustomModal from '../../../common/component/CustomModal';
import { Grid } from '@mui/material';
import { Formik } from 'formik';
import { ManualPickEditInitialValue } from '../util';
import FormikAutocomplete from '../../../common/fields/FormikAutocomplete';
import { useManualPickInsert } from '../hook';
import { PickEditOption } from '../../../constant/OptionConstant';

type Props = {
  closeTransactionModal: () => void;
};

const ManualPickEdit: FC<Props> = ({ closeTransactionModal }) => {
  const { allPickDataDropdown, teamDataDropdown, isLoading, submitData } = useManualPickInsert();
  return (
    <>
      <Formik
        initialValues={ManualPickEditInitialValue}
        enableReinitialize
        onSubmit={(values) => {
          submitData({
            ...values,
            selectPickEditOwner:
              allPickDataDropdown?.find((item) => item.value === values.selectPickEditOwner)
                ?.label || '',
          });
        }}
      >
        {({ handleSubmit }) => (
          <CustomModal
            saveLoading={isLoading}
            handleSubmit={() => handleSubmit()}
            modalSize={'sm'}
            handleClose={closeTransactionModal}
            modalTitle='Manual Pick Edit'
          >
            <Grid container spacing={'10px'}>
              <Grid item xs={12}>
                <FormikAutocomplete
                  name='selectPickEditOwner'
                  id='selectPickEditOwner'
                  options={allPickDataDropdown}
                  label={'Select Pick to Edit Owner'}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikAutocomplete
                  name='newOwnerPick'
                  id='newOwnerPick'
                  options={teamDataDropdown}
                  label={'New Owner of Pick'}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikAutocomplete
                  name='mostRecentPick'
                  id='mostRecentPick'
                  options={teamDataDropdown}
                  label={'Most Recent Pick Owner'}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikAutocomplete
                  name='selectReasonPickEdit'
                  id='selectReasonPickEdit'
                  options={PickEditOption}
                  label={'Select Reason for Pick Edit'}
                />
              </Grid>
            </Grid>
          </CustomModal>
        )}
      </Formik>
    </>
  );
};

export default ManualPickEdit;
