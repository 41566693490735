import { Box, Grid, Menu, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { PicksTradingType } from '../../type';
import { ColumnHeaderText, CompactTag, Header2 } from '../../../../common/component/Text';
import { TeamFlagOptions } from '../../../../constant/OptionConstant';

type TradingOptionModalProps = {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  pickOptions: PicksTradingType[];
  value: string[];
  setValue: (value: string) => void;
};
const TradingOptionModal: FC<TradingOptionModalProps> = ({
  anchorEl,
  handleClose,
  pickOptions,
  setValue,
  value,
}) => {
  return (
    <Menu
      id='basic-menu'
      anchorEl={anchorEl}
      open
      PaperProps={{
        style: {
          width: 900,
        },
      }}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <Grid container height={'500px'} overflow={'scroll'}>
        <Grid item xs={12}>
          <Box
            p={'10px'}
            border={'1px solid rgba(224, 224, 224, 1)'}
            bgcolor={'#efefef'}
            py={'20px'}
            borderRadius={'10px'}
            style={{ borderRadius: '10px 10px 0px 0px' }}
          >
            <ColumnHeaderText>CURRENT PICKS</ColumnHeaderText>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {pickOptions.map((item) => (
            <Box
              key={item.teamName}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              padding={'20px'}
              borderTop={0}
              gap={'30px'}
              border={'1px rgba(224, 224, 224, 1) solid'}
            >
              <Header2 width={'60px'}>
                {TeamFlagOptions.find((flag) => flag.id === Number(item.teamName))?.shortName || ''}
              </Header2>
              <Grid>
                <Typography gap={'5px'} display={'flex'} flexWrap={'wrap'}>
                  {item.currentYear}
                  {item.picksOption
                    .filter((pick) => pick.year.toString() === item.currentYear)
                    .map((option) => (
                      <Tooltip key={option.unique} title={option.label}>
                        <CompactTag
                          gap={'5px'}
                          display={'flex'}
                          onClick={() => setValue(option.value)}
                          alignItems={'center'}
                          key={option.unique}
                          textsize={'12px'}
                          textAlign={'center'}
                          bgcolor={value.includes(option.value) ? '#189a18' : 'transparent'}
                          fontcolor={value.includes(option.value) ? '#fff' : '#189a18'}
                        >
                          {option.display}
                        </CompactTag>
                      </Tooltip>
                    ))}
                </Typography>
                <Header2 gap={'5px'} display={'flex'} flexWrap={'wrap'} mt={1}>
                  {item.nextYear}
                  {item.picksOption
                    .filter((pick) => pick.year.toString() === item.nextYear)
                    .map((option) => (
                      <Tooltip key={option.unique} title={option.label}>
                        <CompactTag
                          gap={'5px'}
                          display={'flex'}
                          onClick={() => setValue(option.value)}
                          alignItems={'center'}
                          key={option.unique}
                          textsize={'12px'}
                          textAlign={'center'}
                          bgcolor={value.includes(option.value) ? '#737373' : 'transparent'}
                          fontcolor={value.includes(option.value) ? '#fff' : '#737373'}
                        >
                          {option.display}
                        </CompactTag>
                      </Tooltip>
                    ))}
                </Header2>
              </Grid>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Menu>
  );
};

export default TradingOptionModal;
