import { Box, Checkbox, CheckboxProps } from '@mui/material';
import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { FieldLabel } from '../../component/Text';

type CustomCheckboxProps = {
  name: string;
  label: string;
  control: Control<any, any>;
} & CheckboxProps;

const CustomCheckbox: FC<CustomCheckboxProps> = ({ control, label, name }) => {
  return (
    <Controller
      control={control}
      name={`${name}`}
      render={({ field }) => (
        <Box display={'flex'} alignItems={'center'}>
          <Checkbox checked={field.value} onChange={() => field.onChange(!field.value)} />
          <FieldLabel>{label}</FieldLabel>
        </Box>
      )}
    />
  );
};

export default CustomCheckbox;