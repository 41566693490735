/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from '@auth0/auth0-react';
import React, { createContext, useContext, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { clearUser, updateAccessToken } from '../store/auth';
import { setCurrentProject } from '../store/header';
import { useGetAccessToken } from '../store/auth/selector';
import { removeAccessToken } from '../store/localstorage/storage';

type AuthContextType = {
  getAccessToken: () => void;
  logoutUser: () => void;
};

const AuthContext = createContext<AuthContextType>({
  getAccessToken: () => {},
  logoutUser: () => {},
});
const AuthProvider = ({ children }: any) => {
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(useGetAccessToken);
  useEffect(() => {
    if (isAuthenticated) {
      !accessToken && getToken();
    }
  }, [isAuthenticated]);

  const getToken = (refreshToken?: boolean) => {
    getAccessTokenSilently().then((accessToken) => {
      dispatch(updateAccessToken(accessToken));
      if (refreshToken) {
        dispatch(setCurrentProject(''));
        return;
      }
    });
  };

  const logoutUser = () => {
    dispatch(setCurrentProject(''));
    dispatch(clearUser());
    removeAccessToken();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const contextValue = {
    getAccessToken: getToken,
    logoutUser,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      <button
        style={{ position: 'absolute', visibility: 'hidden' }}
        id={'getToken'}
        onClick={() => logoutUser()}
      >
        Get Token
      </button>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const AuthContexts = () => useContext(AuthContext);
