import { Edit } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import React, { FC } from 'react';
import { FaRegHandshake } from 'react-icons/fa';
import { MdDeleteOutline, MdEmail, MdThumbDown } from 'react-icons/md';
import { BUTTON_COLOR_THEME, TEXT_COLOR_INFO_TYPE } from '../../../theme/color';
import { CiMenuKebab } from 'react-icons/ci';

type ColumnMenuProps = {
  approveTrade: () => void;
  editTrade: () => void;
  disapproveTrade: () => void;
  emailTrade: () => void;
  disableSummaryValidity: boolean;
  declineOffer: () => void;
  disableDecline: boolean;
};
const ColumnMenu: FC<ColumnMenuProps> = ({
  approveTrade,
  disapproveTrade,
  editTrade,
  emailTrade,
  disableSummaryValidity,
  declineOffer,
  disableDecline,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <CiMenuKebab />
      </IconButton>
      <Menu id='basic-menu' anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          disabled={disableSummaryValidity}
          onClick={() => {
            approveTrade();
            handleClose();
          }}
        >
          <IconButton disabled={disableSummaryValidity}>
            <FaRegHandshake style={{ color: TEXT_COLOR_INFO_TYPE.success }} />
          </IconButton>
          Accept
        </MenuItem>

        <MenuItem
          onClick={() => {
            editTrade();
            handleClose();
          }}
          disabled={disableSummaryValidity}
        >
          <IconButton>
            <Edit />
          </IconButton>
          Update
        </MenuItem>

        <MenuItem
          onClick={() => {
            disapproveTrade();
            handleClose();
          }}
        >
          <IconButton>
            <MdDeleteOutline color={BUTTON_COLOR_THEME.danger} />
          </IconButton>
          Delete Offer
        </MenuItem>
        <MenuItem
          onClick={() => {
            declineOffer();
            handleClose();
          }}
          disabled={disableDecline}
        >
          <IconButton>
            <MdThumbDown color={TEXT_COLOR_INFO_TYPE.error} />
          </IconButton>
          Decline Offer
        </MenuItem>
        <MenuItem
          onClick={() => {
            emailTrade();
            handleClose();
          }}
        >
          <IconButton>
            <MdEmail color={BUTTON_COLOR_THEME.primary} />
          </IconButton>
          Email
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ColumnMenu;
