import React, { FC } from 'react';
import { Grid } from '@mui/material';
import CustomModal from './CustomModal';
import { Header2 } from './Text';

type Props = {
  handleClose: () => void;
  description: string;
  modalTitle: string;
  handleSubmit: () => void;
  isLoading: boolean;
  hideClose?: boolean;
  submitButtonLabel?: string;
  infoList?: JSX.Element;
  modalSize?: 'sm' | 'md' | 'lg' | 'xl' | 'xs';
};
const DeleteModal: FC<Props> = ({
  handleClose,
  description,
  modalTitle,
  handleSubmit,
  isLoading,
  submitButtonLabel,
  hideClose = false,
  infoList,
  modalSize,
}) => (
  <CustomModal
    hideClose={hideClose}
    confirmButtonLabel={submitButtonLabel || 'Delete'}
    handleSubmit={handleSubmit}
    saveLoading={isLoading}
    modalSize={modalSize || 'xs'}
    handleClose={handleClose}
    modalTitle={modalTitle}
  >
    <>
      <Grid>
        <Header2>{description}</Header2>
      </Grid>
      {infoList && <Grid mt={'20px'}>{infoList}</Grid>}
    </>
  </CustomModal>
);

export default DeleteModal;
