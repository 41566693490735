import { Grid } from '@mui/material';
import React, { FC } from 'react';
import { Header2 } from './Text';
import { TEXT_COLOR_THEME } from '../../theme/color';

type BannerProps = {
  text: string;
};
const Banner: FC<BannerProps> = ({ text }) => {
  return (
    <Grid
      container
      borderRadius={'10px'}
      p={'10px'}
      style={{
        border: `1px solid ${TEXT_COLOR_THEME.primary}`,
      }}
    >
      <Header2 fontcolor={TEXT_COLOR_THEME.primary}>{text}</Header2>
    </Grid>
  );
};

export default Banner;
