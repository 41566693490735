export type PlanningNotesType = {
  note: string;
  id: string;
};

export enum TradeStatus {
  PENDING = 'PENDING',
  APPROVED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  ALL = 'ALL',
}
export type PlanningTradeOfferType = {
  index: number;
  id: number;
  notes: string;
  payload: PayloadTradeType;
  pickExistsValidity: PickValidityType;
  pickMatchValidity: PickMatchValidityType;
  summaryValidity: string;
  summaryDescription: string;
  team1NetResult: number;
  team1PtsOut: number;
  team1Name: string;
  team1PicksOut: string[];
  team1Playersout: string[];
  team2NetResult: number;
  team2PtsOut: number;
  team2Name: string;
  team2PicksOut: string[];
  team2PlayersOut: string[];
  teamId1: number;
  teamId2: number;
  tradeBackRuleValidity: TradeBackRuleValidityType;
  transactionDescription: string;
  transactionType: string;
  conditionalDetails: string;
  tradeOfferType: string;
  tradeStatus: string;
  tradeCreated: string;
  tradeLastModified: string;
};

export type PayloadTradeType = {
  pick1: PayloadPickType[];
  pick2: PayloadPickType[];
  player1: string[];
  player2: string[];
  teamid1: number;
  teamid2: number;
};

export type PayloadPickType = {
  value: string;
  label: string;
  unqiue: string;
  display: string;
};

export type PickValidityType = {
  checkPickExists: string;
  description: string;
  picksNotValid: string[];
};

export type PickMatchValidityType = {
  checkPickMatch: string;
  description: string;
  picksNotValid: string[];
};

export type TradeBackRuleValidityType = {
  cannotTradeBack: string[];
  description: string;
  tradeBackRule: string;
};

export type TradeTransactionEmailType = {
  team1Name: string;
  team1PlayersOut: string[];
  team1PicksOut: string[];
  team2Name: string;
  team2PlayersOut: string[];
  team2PicksOut: string[];
};
