import { date, object, ref, string } from 'yup';
import { TabOptionType } from '../../common/component/type';
import {
  PlayerAgencyAddUpdateType,
  PlayerManagerAgencyAddUpdateType,
} from '../../store/service/playermanagerservice/type';
import {
  PLAYER_MANAGER_TAB,
  PlayerAgencyType,
  PlayerAgentType,
  PlayerAllocationFilterType,
  PlayerAllocationInfoType,
  PlayerManagerListType,
} from './type';
import { OptionType } from '../../common/fields/type';
import { PlayerAllocationType } from './type';
import { WEBSITE_REG_MATCH } from '../../constant/SiteConstant';

export const playerManagerTabOption: TabOptionType[] = [
  {
    label: 'Current Player Managers',
    value: PLAYER_MANAGER_TAB.PLAYER_MANAGER_SUMMARY,
  },
  {
    label: 'Player Agencies',
    value: PLAYER_MANAGER_TAB.PLAYER_AGENCIES,
  },
  {
    label: 'Player Manager',
    value: PLAYER_MANAGER_TAB.PLAYER_MANAGER,
  },
];

export const transformPlayerAgencyData = (data: any): PlayerAgencyType[] => {
  if (!data) {
    return [];
  }
  return data.items.map(
    (item: any) =>
      ({
        created: item.created,
        email: item.email,
        id: item.id,
        name: item.name,
        phone: item.phone,
        updated: item.updated,
        website: item.website,
      } as PlayerAgencyType),
  );
};

export const playerAgencyInitialValue = (
  playerAgencyInfo?: PlayerAgencyType,
): PlayerAgencyAddUpdateType => ({
  email: playerAgencyInfo?.email || '',
  name: playerAgencyInfo?.name || '',
  phone: playerAgencyInfo?.phone || '',
  website: playerAgencyInfo?.email || '',
});

export const playerAgencyValidationSchema = object().shape({
  email: string().email('Please enter valid email').required('Please enter email'),
  name: string().required('Please enter name'),
  phone: string().required('Please enter phone number'),
  website: string()
    .matches(WEBSITE_REG_MATCH, 'Website should be a valid URL')
    .required('Please enter website'),
});

export const playerManagerInitialValue = (
  playerAgencyInfo?: PlayerManagerListType,
): PlayerManagerAgencyAddUpdateType => ({
  email: playerAgencyInfo?.email || '',
  companyId: playerAgencyInfo?.company.id.toString() || '',
  endDate: playerAgencyInfo?.endDate || '',
  firstName: playerAgencyInfo?.firstName || '',
  lastName: playerAgencyInfo?.lastName || '',
  position: playerAgencyInfo?.position || '',
  phone: playerAgencyInfo?.phone || '',
  startDate: playerAgencyInfo?.startDate || '',
});

export const playerManagerValidationSchema = object().shape({
  email: string().email('Please enter valid email').required('Please enter email'),
  firstName: string().required('Please enter first name'),
  lastName: string().nullable(),
  position: string().required('Please enter position'),
  endDate: date()
    .typeError('Please select date')
    .min(ref('startDate'), "End date can't be before start date")
    .required('Please select end date'),
  startDate: date().typeError('Please select date').required('Please select start date'),
  phone: string().required('Please enter phone number'),
  companyId: string().required('Please select company id'),
});

export const transformPlayerManagerAgencyData = (data: any): PlayerManagerListType[] => {
  if (!data) {
    return [];
  }
  return data.items.map(
    (item: any) =>
      ({
        created: item.created,
        updated: item.updated,
        id: item.id,
        company: item.company,
        email: item.email,
        endDate: item.end_date,
        firstName: item.first_name,
        lastName: item.last_name,
        phone: item.phone,
        position: item.position,
        startDate: item.start_date,
      } as PlayerManagerListType),
  );
};

export const transformPlayerAgencyDropdownOptionData = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }
  return data.items.map(
    (item: any) =>
      ({
        label: item.name,
        value: item.id,
      } as OptionType),
  );
};

const transformPlayerData = (data: any): PlayerAllocationInfoType => {
  return {
    id: data.id,
    organisationId: data.organisation_id,
    firstName: data.first_name,
    preferredFirstName: data.preferred_first_name,
    lastName: data.last_name,
    preferredLastName: data.preferred_last_name,
    juniorTeam: data.junior_team,
    dateOfBirth: data.date_of_birth,
    height: data.height,
    weight: data.weight,
    position: data.position,
    secondaryPosition: data.secondary_position,
    state: data.state,
    preferredFoot: data.preferred_foot,
    currentAflActive: data.current_afl_active,
    eligibility: data.eligibility,
    currentRosterAllocation: {
      id: data.current_roster_allocation?.id,
      teamId: data.current_roster_allocation?.team_id,
      startSeason: data.current_roster_allocation?.start_season,
      endSeason: data.current_roster_allocation?.end_season,
      contractType: data.current_roster_allocation?.contract_type,
      contractTypeDescription: data.current_roster_allocation?.contract_type_description,
    },
    currentManagementAllocation: data.current_management_allocation,
    created: data.created,
    updated: data.updated,
  };
};

const transformAgentAllocationData = (data: any): PlayerAgentType => {
  return {
    id: data.id,
    company: {
      id: data.company.id,
      name: data.company.name,
      website: data.company.website,
      phone: data.company.phone,
      email: data.company.email,
      created: data.company.created,
      updated: data.company.updated,
    },
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
    phone: data.phone,
    position: data.position,
    startDate: data.start_date,
    endDate: data.end_date,
    created: data.created,
    updated: data.updated,
  };
};
export const transformPlayerAllocationData = (data: any): PlayerAllocationType[] => {
  if (!data) {
    return [];
  }

  return data.items.map((item: any) => ({
    id: item.id,
    player: transformPlayerData(item.player),
    agent: transformAgentAllocationData(item.agent),
    startDate: item.start_date,
    endDate: item.end_date,
    created: item.created,
    updated: item.updated,
  }));
};

export const transformAgencyOptions = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }
  return data.items.map(
    (item: any) =>
      ({
        value: item.id,
        label: `${item.name}`,
      } as OptionType),
  );
};

export const transformManagerOptions = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }
  return data.items.map(
    (item: any) =>
      ({
        value: item.id,
        label: `${item.first_name} ${item.last_name}`,
      } as OptionType),
  );
};

export const transformClubOptions = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        value: item.id,
        label: item.shortName,
      } as OptionType),
  );
};

export const transformPlayerOptions = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        value: item.id.toString(),
        label: `${item.first_name} ${item.last_name}`,
      } as OptionType),
  );
};

export const filteredPlayerSummaryList = (
  list: PlayerAllocationType[],
  selectedFilter: PlayerAllocationFilterType,
): PlayerAllocationType[] => {
  return list.filter((item) => {
    const matchesAgency =
      !selectedFilter.agency || item.agent.company.id === parseInt(selectedFilter.agency);

    const matchesManager =
      !selectedFilter.manager || item.agent.id === parseInt(selectedFilter.manager);

    const matchesClub =
      !selectedFilter.club ||
      item.player.currentRosterAllocation.teamId === parseInt(selectedFilter.club);

    const matchesPlayer =
      !selectedFilter.player || item.player.id === parseInt(selectedFilter.player);

    // TODO add for elegibiltyGroup  matchesEligibilityGroups =

    return matchesAgency && matchesManager && matchesClub && matchesPlayer;
  });
};
