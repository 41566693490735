import { Box, Grid } from '@mui/material';
import SimpleAutocomplete from '../../common/fields/SimpleAutocomplete';
import { usePlayerProfile } from './hook';
import PlayerInfo from '../playerinfo/PlayerInfo';
import { PrimaryButton } from '../../common/component/Button';
import AddUpdatePlayer from '../playerdatabase/component/AddUpdatePlayer';

const PlayerProfile = () => {
  const {
    selectedPlayer,
    refreshRandom,
    setRefreshListRandom,
    setSelectedPlayer,
    playerOptionList,
    handleEditClick,
    selectedPlayerForEdit,
    setSelectedPlayerForEdit,
    refetch,
  } = usePlayerProfile();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          mt={'10px'}
          display={'flex'}
          alignItems={'center'}
          gap={'20px'}
          justifyContent={'flex-end'}
        >
          <Box width={'200px'}>
            <SimpleAutocomplete
              handleOptionChange={setSelectedPlayer}
              label='Player'
              options={playerOptionList}
              value={selectedPlayer}
              key={selectedPlayer}
            />
          </Box>
          <Box width={'100px'}>
            <PrimaryButton
              onClick={handleEditClick}
              style={{ borderRadius: 5, height: 40 }}
              title='Edit'
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} mt={'10px'}>
        <PlayerInfo key={`${selectedPlayer}-${refreshRandom}`} playerInfoId={selectedPlayer} />
      </Grid>
      {selectedPlayerForEdit && (
        <AddUpdatePlayer
          refreshList={() => {
            refetch();
            setRefreshListRandom(Math.random().toString());
          }}
          handleClose={() => {
            setSelectedPlayerForEdit(undefined);
          }}
          playerData={selectedPlayerForEdit}
        />
      )}
    </Grid>
  );
};

export default PlayerProfile;
