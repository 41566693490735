/* eslint-disable react-hooks/rules-of-hooks */
import { Grid } from '@mui/material';
import { Formik } from 'formik';
import React, { FC } from 'react';
import CustomModal from '../../../common/component/CustomModal';
import FormikAutocomplete from '../../../common/fields/FormikAutocomplete';
import { useAcademyBidMatch, useFamilyFormBidMatch, useFatherSonBidMatch } from '../hook';
import { FatherBidMatchInitialValue } from '../util';
import DraftHandInfo from './fatherSonInfo/DraftHandInfo';
import CalculationInfo from './fatherSonInfo/CalculationInfo';

type Props = {
  closeTransactionModal: () => void;
};

const FatherBidMatch: FC<Props> = ({ closeTransactionModal }) => {
  const { teamDataDropdown, allPickDataDropdown, playerDataDropdown, currentProject } =
    useAcademyBidMatch();

  const { fatherBidLoading, submitData } = useFatherSonBidMatch();
  return (
    <>
      <Formik initialValues={FatherBidMatchInitialValue} enableReinitialize onSubmit={submitData}>
        {({ values, handleSubmit }) => {
          const { transactionInfo } = useFamilyFormBidMatch(values, currentProject);
          return (
            <CustomModal
              saveLoading={fatherBidLoading}
              handleSubmit={() => handleSubmit()}
              modalSize={'md'}
              handleClose={closeTransactionModal}
              modalTitle='Father Son Bid Match'
            >
              <Grid container columnSpacing={'50px'}>
                <Grid item xs={6}>
                  <Grid container rowSpacing={'10px'}>
                    <Grid item xs={12}>
                      <FormikAutocomplete
                        name='fatherSonBelongTo'
                        id='fatherSonBelongTo'
                        options={teamDataDropdown}
                        label={'Select Team Father/Son Player Belongs To'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikAutocomplete
                        name='fatherSonPlayers'
                        id='fatherSonPlayers'
                        options={playerDataDropdown}
                        label={'Select Father/Son Player'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikAutocomplete
                        name='pickOfBid'
                        id='pickOfBid'
                        options={allPickDataDropdown}
                        label={'Select Pick Of Bid'}
                      />
                    </Grid>
                    {transactionInfo && (
                      <Grid item xs={12}>
                        <DraftHandInfo
                          title='Starting Draft Hand'
                          draftHand={transactionInfo?.initialDraftHand}
                        />
                        <DraftHandInfo
                          title='Draft Hand if Bid Matched:'
                          draftHand={transactionInfo?.newDraftHand}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <CalculationInfo draftInfo={transactionInfo} />
                </Grid>
              </Grid>
            </CustomModal>
          );
        }}
      </Formik>
    </>
  );
};

export default FatherBidMatch;
