import React, { FC } from 'react';
import { PassImpactDataType } from './type';
import { Box, Grid, Typography } from '@mui/material';
import { CompactTag } from '../../../../common/component/Text';

type PassPickImpactDataProps = {
  impactData: PassImpactDataType;
};
const PassPickImpactData: FC<PassPickImpactDataProps> = ({ impactData }) => {
  return (
    <Grid container mt={'30px'}>
      <Grid item xs={12}>
        <Typography textAlign={'center'}>The Following picks will be passed</Typography>
      </Grid>
      <Grid item xs={12} mt={'10px'}>
        <Box justifyContent={'center'} display={'flex'} gap={'5px'} flexWrap={'wrap'}>
          {impactData.picksPassed.map((item) => (
            <CompactTag
              gap={'5px'}
              key={item}
              display={'flex'}
              alignItems={'center'}
              textsize={'12px'}
              textAlign={'center'}
              bgcolor={'transparent'}
              fontcolor={'#189a18'}
              minWidth={15}
            >
              {item}
            </CompactTag>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default PassPickImpactData;