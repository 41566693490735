import { Autocomplete, Box, TextField } from '@mui/material';
import { useField } from 'formik';
import React, { FC } from 'react';
import { ErrorText, FieldLabel } from '../component/Text';
import { OptionType } from './type';

type Props = {
  id: string;
  name: string;
  options: OptionType[];
  label: string;
  ismultiselect?: boolean;
};

const FormikAutocomplete: FC<Props> = ({ id, name, options, label, ismultiselect = false }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { error, touched, value }, { setValue, setTouched }] = useField(name);

  const getValue = () => {
    if (ismultiselect) {
      return options.filter((item) => value.includes(item.value));
    }
    return options.find((item) => item.value === value);
  };
  return (
    <Box>
      <FieldLabel>{label}</FieldLabel>
      <Autocomplete
        id={id}
        fullWidth
        multiple={ismultiselect}
        size={'small'}
        value={getValue()}
        onChange={(_: any, newValue: any) => {
          if (!ismultiselect) {
            setValue(newValue?.value);
            return;
          }
          setValue(newValue.map((item: OptionType) => item.value));
        }}
        options={options}
        renderInput={(params) => <TextField {...params} onBlur={() => setTouched(true)} />}
      />
      {!!error && !!touched && <ErrorText>{error}</ErrorText>}
    </Box>
  );
};

export default FormikAutocomplete;
