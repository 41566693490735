import Styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const StyledLogo = Styled.img`
    height: 24px;
    width: 24px;
    border-radius: 50%;
    box-shadow: 1px 1px 10px white;
    border: 1px black solid;
`;

export const DraftOrderDataGridContainer = Styled(Grid)`
& .inactive {
        opacity: 0.2;
    };
    & .active {
        opacity: 1;
    }
`;
