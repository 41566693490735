import { TabOptionType } from '../../common/component/type';
import { OptionType } from '../../common/fields/type';
import {
  FieldInfoType,
  FieldsType,
  FilterOptionType,
  PLAYER_INFO_TAB,
  PlayerInfoType,
  TimeLineFieldType,
  TimelineOfEntryType,
} from './type';

export const playerInfoTabOption: TabOptionType[] = [
  {
    label: 'Timeline of entries',
    value: PLAYER_INFO_TAB.TIMELINE,
  },
  {
    label: 'Contract History',
    value: PLAYER_INFO_TAB.CONTRACT_HISTORY,
  },
  {
    label: 'Awards',
    value: PLAYER_INFO_TAB.AWARDS,
  },
];

export const transformBioDetails = (data: any): PlayerInfoType | null => {
  if (!data) {
    return null;
  }

  return {
    id: data.id,
    organisationId: data.organisation_id,
    firstName: data.first_name,
    preferredFirstName: data.preferred_first_name,
    lastName: data.last_name,
    preferredLastName: data.preferred_last_name,
    juniorTeam: data.junior_team,
    dateOfBirth: data.date_of_birth,
    height: data.height,
    weight: data.weight,
    position: data.position,
    secondaryPosition: data.secondary_position,
    state: data.state,
    preferredFoot: data.preferred_foot,
    created: data.created,
    updated: data.updated,
    currentAflActive: data.current_afl_active,
    currentRosterAllocation: {
      contractType: data?.current_roster_allocation?.contract_type || '',
      contractTypeDescription: data?.current_roster_allocation?.contract_type_description || '',
      endSeason: data?.current_roster_allocation?.end_season || '',
      id: data?.current_roster_allocation?.id || '',
      startSeason: data?.current_roster_allocation?.start_season || '',
      teamId: data?.current_roster_allocation?.team_id || '',
    },
    currentManagementAllocation: {
      agentName: data.current_management_allocation?.agent_name || '',
      companyName: data.current_management_allocation?.company_name || '',
      startDate: data.current_management_allocation?.start_date || '',
    },
    elegibility: data.eligibility.map((item: any) => ({
      elegibilityStatus: item.eligibility_status,
      elegibilityType: item.eligibility_type,
      id: item.id,
      season: item.season,
    })),
  };
};

const transformFieldInfo = (
  data: any,
  selectedTimelineForEdit?: TimelineOfEntryType,
): FieldInfoType[] => {
  if (!data) {
    return [];
  }
  return data.map((item: any) => ({
    fieldId: item.field_id,
    fieldLabel: item.field_label,
    fieldName: item.field_name,
    fieldTooltip: item.field_tooltip,
    fieldType: item.field_type,
    maxLength: item.max_length,
    minLength: item.min_length,
    maxValue: item.max_value,
    minValue: item.min_value,
    nullable: item.nullable,
    value:
      selectedTimelineForEdit?.fields.find((field) => item.field_id === field.id)?.value ||
      item.value,
  }));
};

export const transformFieldDetails = (
  data: any,
  selectedTimelineForEdit?: TimelineOfEntryType,
): FieldsType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (opt: any) =>
      ({
        active: opt.active,
        name: opt.name,
        fields: transformFieldInfo(opt.fields, selectedTimelineForEdit),
      } as FieldsType),
  );
};

export const transformTimelineData = (data: any): TimelineOfEntryType[] => {
  if (!data) {
    return [];
  }

  return data.map(
    (item: any) =>
      ({
        fields: item.fields.map(
          (field: any) =>
            ({
              id: field.id,
              value: field.value,
              schema: {
                fieldId: field?.schema?.field_id || '',
                fieldLabel: field?.schema?.field_label || '',
                fieldName: field?.schema?.field_name || '',
                fieldTooltip: field?.schema?.field_tooltip || '',
                fieldType: field?.schema?.field_type || '',
                nullable: field?.schema?.nullable || '',
              },
            } as TimeLineFieldType),
        ),
        id: item.id,
        name: item.name,
        orgId: item.organisation_id,
        playerId: item.player_id,
        createdAt: item.created_at,
        updatedAt: item.updated_at,
        createdBy: {
          email: item.created_by?.email || '',
          firstName: item.created_by?.first_name || '',
          id: item.created_by?.id || '',
          lastName: item.created_by?.last_name || '',
        },
      } as TimelineOfEntryType),
  );
};

export const filterInitialValue = (data: FilterOptionType): FilterOptionType => {
  return {
    createdAt: data.createdAt,
    formType: data.formType,
    open: data.open,
    updatedAt: data.updatedAt,
  };
};

export const transformSelectFormOption = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        label: item.name,
        value: item.name,
      } as OptionType),
  );
};

export const filterTimelineData = {
  formType: {
    filterFn: (formType: string) => {
      if (formType) {
        return (timeLineData: TimelineOfEntryType) => {
          if (timeLineData.name === formType) {
            return true;
          }
          return false;
        };
      }
      return () => true;
    },
  },
  createdAt: {
    filterFn: (createdAt: { startDate: string | null; endDate: string | null }) => {
      if (!(createdAt.startDate && createdAt.endDate)) {
        return () => true;
      }
      return (timeLineData: TimelineOfEntryType) => {
        if (
          new Date(timeLineData.createdAt) > new Date(createdAt.startDate as string) &&
          new Date(timeLineData.createdAt) < new Date(createdAt.endDate as string)
        ) {
          return true;
        }
        return false;
      };
    },
  },
  updatedAt: {
    filterFn: (updatedAt: { startDate: string | null; endDate: string | null }) => {
      if (!(updatedAt.startDate && updatedAt.endDate)) {
        return () => true;
      }
      return (timeLineData: TimelineOfEntryType) => {
        if (
          new Date(timeLineData.updatedAt) > new Date(updatedAt.startDate as string) &&
          new Date(timeLineData.updatedAt) < new Date(updatedAt.endDate as string)
        ) {
          return true;
        }
        return false;
      };
    },
  },
};

export const filterTimeLineData = (
  data: TimelineOfEntryType[],
  selectedFilter: FilterOptionType,
): TimelineOfEntryType[] => {
  if (!data) {
    return [];
  }
  let filterTimeLineData = data;
  filterTimeLineData = filterTimeLineData.filter(
    filterTimelineData.formType.filterFn(selectedFilter.formType),
  );
  filterTimeLineData = filterTimeLineData.filter(
    filterTimelineData.createdAt.filterFn(selectedFilter.createdAt),
  );
  filterTimeLineData = filterTimeLineData.filter(
    filterTimelineData.updatedAt.filterFn(selectedFilter.updatedAt),
  );
  return filterTimeLineData;
};
