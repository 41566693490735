import { useNavigate } from 'react-router-dom';
import { useGetShowTeamQuery, useRegisteruserMutation } from '../../store/service';
import { RegisterType } from './type';
import { transformTeamNameDropdownOption } from './util';

export const useRegister = () => {
  const { data: teamList } = useGetShowTeamQuery('');
  const [createUser, { isLoading }] = useRegisteruserMutation();
  const navigate = useNavigate();

  const registerUser = (value: RegisterType) => {
    createUser({
      data: value,
    }).then((res: any) => {
      if (!res.error) {
        navigate('/');
      }
    });
  };
  return {
    teamList: transformTeamNameDropdownOption(teamList?.data),
    registerUser,
    isLoading,
  };
};
