import { useEffect } from 'react';
import { setFlagList, setSelectedFlag } from '../../store/header';
import { useFlagList, useSelectedFlag } from '../../store/header/selector';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useLazyGetFlagsQuery } from '../../store/service';
import { transformData } from './util';
import { useGetAccessToken } from '../../store/auth/selector';

export const useFlag = () => {
  const selectedFlag = useAppSelector(useSelectedFlag);
  const flagList = useAppSelector(useFlagList);
  const accessToken = useAppSelector(useGetAccessToken);
  const dispatch = useAppDispatch();
  const [fetchFlags, { data, error, isLoading }] = useLazyGetFlagsQuery();

  useEffect(() => {
    data && dispatch(setFlagList(transformData(data)));
  }, [data, dispatch]);

  useEffect(() => {
    accessToken && fetchFlagsList();
  }, [accessToken]);

  const fetchFlagsList = () => {
    fetchFlags('');
  };
  const updateSelectedFlag = (selectedFlagId: string) => {
    if (selectedFlagId === selectedFlag) {
      dispatch(setSelectedFlag(''));
      return;
    }
    dispatch(setSelectedFlag(selectedFlagId));
  };

  return {
    flagsList: flagList,
    error,
    isLoading,
    selectedFlag,
    updateSelectedFlag,
    fetchFlagsList,
  };
};
