import { Grid } from '@mui/material';
import { FC } from 'react';
import { useProjectSettingModal } from '../hook';
import CustomModal from '../../../common/component/CustomModal';
import RosterList from './RosterList';

type ProjectSettingModalProps = {
  handleClose: () => void;
  projectId: string;
  refreshList?: () => void;
};
const ProjectSettingModal: FC<ProjectSettingModalProps> = ({
  handleClose,
  projectId,
  refreshList,
}) => {
  const { rosterList, isLoading, setRosterAmount, handleSubmit, updateRosterLoading } =
    useProjectSettingModal(projectId, handleClose, refreshList);
  return (
    <CustomModal
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      modalSize={'md'}
      saveLoading={updateRosterLoading}
      modalTitle='Roster List'
    >
      <Grid container>
        <Grid item xs={12} mt={'10px'}>
          <RosterList
            isLoading={isLoading}
            rosterList={rosterList}
            setRosterAmount={setRosterAmount}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default ProjectSettingModal;
