import { GridCellParams } from '@mui/x-data-grid';
import { RankingListType } from '../type';
import { Header2 } from '../../../common/component/Text';
import { Box, IconButton } from '@mui/material';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { BUTTON_COLOR_THEME } from '../../../theme/color';

export const RankingListColumn = (
  handleEdit: (data: RankingListType) => void,
  handleDelete: (data: RankingListType) => void,
) => [
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, RankingListType>) => (
      <Header2>{params.row.name}</Header2>
    ),
  },
  {
    field: 'draftYear',
    headerName: 'Draft Year',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, RankingListType>) => (
      <Header2>{params.row.draftYear}</Header2>
    ),
  },
  {
    field: 'id',
    headerName: '',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, RankingListType>) => (
      <Box display={'flex'}>
        <IconButton title='Edit' onClick={() => handleEdit(params.row)}>
          <EditOutlined sx={{ color: BUTTON_COLOR_THEME.primary }} />
        </IconButton>
        <IconButton title={'Delete'} onClick={() => handleDelete(params.row)}>
          <DeleteOutline color={'error'} />
        </IconButton>
      </Box>
    ),
  },
];
