import { FC } from 'react';
import { PlanningTradeOfferType } from '../type';
import CustomModal from '../../../common/component/CustomModal';
import { useEmailTradeOffer } from '../hook';
import LoadingWrapper from '../../../common/component/LoadingWrapper';
import { IconButton, Typography } from '@mui/material';
import { CopyAll } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { copyTradeBody } from '../util';

type EmailTradeOfferModalProps = {
  tradeOffer: PlanningTradeOfferType;
  handleClose: () => void;
  projectId: string;
};
const EmailTradeOfferModal: FC<EmailTradeOfferModalProps> = ({
  tradeOffer,
  handleClose,
  projectId,
}) => {
  const { emailInfo, isLoading } = useEmailTradeOffer(tradeOffer.id.toString(), projectId);
  return (
    <CustomModal
      confirmButtonLabel={'Copy'}
      handleSubmit={copyTradeBody}
      modalSize={'lg'}
      handleClose={handleClose}
      modalTitle={'Email'}
    >
      <LoadingWrapper isLoading={isLoading}>
        <Typography>
          "Trade: {emailInfo?.team1Name} and {emailInfo?.team2Name}"
          <IconButton
            onClick={() =>
              navigator.clipboard
                .writeText(`Trade: ${emailInfo?.team1Name} and ${emailInfo?.team2Name}`)
                .then(() => toast.success('Subject copied to clipboard'))
            }
          >
            <CopyAll />
          </IconButton>
        </Typography>
        <Typography id={'email_body'}>
          Hi, <br />
          This email is confirmation that a trade has been agreed upon between{' '}
          {emailInfo?.team1Name} and {emailInfo?.team2Name}. <br />
          The following players and picks have been traded: <br />
          <Typography mt={'10px'}>{emailInfo?.team1Name}:</Typography>
          <ul>
            {emailInfo?.team1PlayersOut && emailInfo?.team1PlayersOut.length > 0 && (
              <>
                <li>Players</li>
                <ul>
                  {emailInfo?.team1PlayersOut.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </>
            )}
            {emailInfo?.team1PicksOut && emailInfo?.team1PlayersOut.length > 0 && (
              <>
                <li>Picks</li>
                <ul>
                  <li>{emailInfo?.team1PicksOut.join(', ')}</li>
                </ul>
              </>
            )}
          </ul>
          <Typography>{emailInfo?.team2Name}:</Typography>
          <ul>
            {emailInfo?.team2PlayersOut && emailInfo?.team2PlayersOut.length > 0 && (
              <>
                <li>Players</li>
                <ul>
                  {emailInfo?.team2PlayersOut.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </>
            )}
            {emailInfo?.team2PicksOut && emailInfo?.team2PlayersOut.length > 0 && (
              <>
                <li>Picks</li>
                <ul>
                  <li>{emailInfo?.team2PicksOut.join(', ')}</li>
                </ul>
              </>
            )}
          </ul>
        </Typography>
      </LoadingWrapper>
    </CustomModal>
  );
};

export default EmailTradeOfferModal;
