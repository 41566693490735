/* eslint-disable react-hooks/exhaustive-deps */
import { useForm, useWatch } from 'react-hook-form';
import {
  useGetCsvUploadListQuery,
  useRederCsvFileMutation,
} from '../../../../store/service/uploadcsvservice';
import { useMemo } from 'react';
import { transformUploadCsvOption, uploadCsvValidation } from './util';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setUploadCsvSelectedTransactionTypeModal } from '../../../../store/header';
import { yupResolver } from '@hookform/resolvers/yup';
import { UploadCsvTransactionType } from '../../../../store/header/type';

export const useUploadCsvModal = (uploadCsvTransactionType: UploadCsvTransactionType) => {
  const { data, isLoading } = useGetCsvUploadListQuery('');
  const [submitCsv, { isLoading: submitLoading }] = useRederCsvFileMutation();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      csv: '',
    },
    resolver: yupResolver(uploadCsvValidation),
  });

  const selectedCsvOption = useWatch({
    control,
    name: 'csv',
  });

  const fileListOption = useMemo(() => transformUploadCsvOption(data), [data]);

  const onSubmit = (value: { csv: string }) => {
    submitCsv({
      fileName: value.csv,
      reverseTransformData: {
        transaction_type: uploadCsvTransactionType.transactionType,
        payload: uploadCsvTransactionType.payload,
      },
    }).then((res: any) => {
      if (res.error.originalStatus === 200) {
        toast.success('Updated successfully');
        handleClose();
        return;
      }
    });
  };

  const handleClose = () => {
    dispatch(setUploadCsvSelectedTransactionTypeModal(null));
  };

  return {
    data,
    isLoading,
    fileListOption,
    register,
    errors,
    handleSubmit,
    selectedCsvOption,
    submitLoading,
    onSubmit,
    handleClose,
  };
};
