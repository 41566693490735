import { GridCellParams } from '@mui/x-data-grid';
import { AccoladeType } from '../../playerdatabase/type';
import { Header2 } from '../../../common/component/Text';

export const awardListColumn = () => [
  {
    field: 'accoladeName',
    headerName: 'Name',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, AccoladeType>) => (
      <Header2>{params.row.accoladeName}</Header2>
    ),
  },
  {
    field: 'season',
    headerName: 'Season',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, AccoladeType>) => (
      <Header2>{params.row.season}</Header2>
    ),
  },
  {
    field: 'accoladeCategory',
    headerName: 'Category',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<any, AccoladeType>) => (
      <Header2>{params.row.accoladeCategory}</Header2>
    ),
  },
];
