import { Box, Button, Grid } from '@mui/material';
import CustomSelect from '../../../common/fields/reacthookfield/CustomSelect';
import CustomTextInput from '../../../common/fields/reacthookfield/CustomTextInput';
import { PlayerDatabaseType } from '../type';
import { Control, FieldErrors, UseFormRegister, UseFormSetValue, useWatch } from 'react-hook-form';
import CustomeDatepicker from '../../../common/fields/reacthookfield/CustomeDatepicker';
import { PlayerPositionOptions } from '../../../constant/OptionConstant';
import CustomSlider from '../../../common/fields/reacthookfield/CustomSlider';
import { FieldLabel } from '../../../common/component/Text';
import { BUTTON_COLOR_THEME } from '../../../theme/color';
import { useBioUpdate } from '../hook';

type BioUpdateProps = {
  register: UseFormRegister<PlayerDatabaseType>;
  errors: FieldErrors<PlayerDatabaseType>;
  control: Control<PlayerDatabaseType, any>;
  setValue: UseFormSetValue<PlayerDatabaseType>;
};
const BioUpdate = ({ register, errors, control, setValue }: BioUpdateProps) => {
  const { stateOptions } = useBioUpdate();
  const position = useWatch({
    control,
    name: `position`,
  });
  const secondaryPosition = useWatch({
    control,
    name: `secondaryPosition`,
  });

  const preferredFoot = useWatch({
    control,
    name: `preferredFoot`,
  });

  const state = useWatch({
    control,
    name: `state`,
  });
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <CustomTextInput
          name={'firstName'}
          register={register}
          isMandatory
          errors={errors}
          fullWidth
          variant='outlined'
          label={'First Name'}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTextInput
          name={'preferredFirstName'}
          register={register}
          errors={errors}
          fullWidth
          isMandatory
          variant='outlined'
          label={'Preferred First Name'}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTextInput
          name={'lastName'}
          register={register}
          isMandatory
          errors={errors}
          fullWidth
          variant='outlined'
          label={'Last Name'}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTextInput
          name={'preferredLastName'}
          register={register}
          errors={errors}
          fullWidth
          isMandatory
          variant='outlined'
          label={'Preferred Last Name'}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTextInput
          name={'juniorTeam'}
          register={register}
          errors={errors}
          fullWidth
          variant='outlined'
          label={'Junior Team'}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomeDatepicker control={control} fieldLabel={'Date of birth'} name={'dateOfBirth'} />
      </Grid>
      <Grid item xs={6}>
        <CustomSlider
          label='Height'
          name={'height'}
          control={control}
          min={150}
          sliderMeasurment='cm'
          max={230}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomSlider
          label='Weight'
          name={'weight'}
          control={control}
          min={60}
          sliderMeasurment='kg'
          max={130}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomSelect
          id={'position'}
          name={'position'}
          register={register}
          options={PlayerPositionOptions || []}
          size='small'
          errors={errors}
          value={position}
          fullWidth
          variant='outlined'
          label={'Position'}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomSelect
          id={'secondaryPosition'}
          name={'secondaryPosition'}
          register={register}
          options={PlayerPositionOptions || []}
          size='small'
          errors={errors}
          value={secondaryPosition}
          fullWidth
          variant='outlined'
          label={'Secondary Position'}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomSelect
          id={'state'}
          name={'state'}
          register={register}
          options={stateOptions || []}
          size='small'
          errors={errors}
          value={state}
          fullWidth
          variant='outlined'
          label={'State'}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldLabel>Preferred Foot</FieldLabel>
        <Box display={'flex'} gap={'10px'}>
          <Button
            sx={{
              background: preferredFoot === 'left' ? BUTTON_COLOR_THEME.primary : undefined,
              borderColor: preferredFoot !== 'left' ? BUTTON_COLOR_THEME.primary : undefined,
              color: preferredFoot !== 'left' ? BUTTON_COLOR_THEME.primary : undefined,
              '&:hover': {
                background: preferredFoot === 'left' ? BUTTON_COLOR_THEME.primary : undefined,
                borderColor: preferredFoot !== 'left' ? BUTTON_COLOR_THEME.primary : undefined,
                color: preferredFoot !== 'left' ? BUTTON_COLOR_THEME.primary : undefined,
              },
            }}
            onClick={() => setValue('preferredFoot', 'left')}
            variant={preferredFoot === 'left' ? 'contained' : 'outlined'}
          >
            Left
          </Button>
          <Button
            onClick={() => setValue('preferredFoot', 'right')}
            sx={{
              background: preferredFoot === 'right' ? BUTTON_COLOR_THEME.primary : undefined,
              borderColor: preferredFoot !== 'right' ? BUTTON_COLOR_THEME.primary : undefined,
              color: preferredFoot !== 'right' ? BUTTON_COLOR_THEME.primary : undefined,
              '&:hover': {
                background: preferredFoot === 'right' ? BUTTON_COLOR_THEME.primary : undefined,
                borderColor: preferredFoot !== 'right' ? BUTTON_COLOR_THEME.primary : undefined,
                color: preferredFoot !== 'right' ? BUTTON_COLOR_THEME.primary : undefined,
              },
            }}
            variant={preferredFoot === 'right' ? 'contained' : 'outlined'}
          >
            Right
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default BioUpdate;
