import { Grid, IconButton } from '@mui/material';
import { FC } from 'react';
import CustomTextInput from '../../../../common/fields/reacthookfield/CustomTextInput';
import { Control, UseFormRegister } from 'react-hook-form';
import { MultiTradeType } from './type';
import { OptionType } from '../../../../common/fields/type';
import CustomAutocomplete from '../../../../common/fields/reacthookfield/CustomAutocomplete';
import { DeleteOutline } from '@mui/icons-material';

type PlayerInfoFormProps = {
  name: string;
  control: Control<MultiTradeType>;
  register: UseFormRegister<MultiTradeType>;
  teamDropdown: OptionType[];
  deletePlayer: () => void;
  index: number;
};
const PlayerInfoForm: FC<PlayerInfoFormProps> = ({
  control,
  name,
  register,
  teamDropdown,
  deletePlayer,
  index,
}) => {
  return (
    <Grid container flexWrap={'nowrap'} columnSpacing={'10px'}>
      <Grid item width={'50%'}>
        <CustomTextInput
          name={`${name}.playerName`}
          register={register}
          placeholder='Player Name'
          variant='outlined'
        />
      </Grid>
      <Grid item width={'50%'}>
        <CustomAutocomplete
          name={`${name}.clubId`}
          id={`${name}.clubId`}
          control={control}
          options={teamDropdown}
          label=''
        />
      </Grid>
      <Grid item width={'13%'}>
        <IconButton style={{ visibility: index >= 1 ? 'initial' : 'hidden' }}>
          <DeleteOutline color='error' onClick={deletePlayer} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default PlayerInfoForm;
