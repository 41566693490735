import { GridCellParams } from '@mui/x-data-grid';
import { CompactTag, Header2, Tag } from '../../../common/component/Text';
import { Box, Grid, Tooltip } from '@mui/material';
import { StyledLogo } from '../../dashboard/style';
import { TeamFlagOptions } from '../../../constant/OptionConstant';
import { MdInfoOutline } from 'react-icons/md';
import { PayloadPickType, PlanningTradeOfferType, TradeStatus } from '../type';
import { TEXT_COLOR_INFO_TYPE } from '../../../theme/color';
import { CheckCircleOutline, CloseOutlined } from '@mui/icons-material';
import { HtmlTooltip } from '../style';
import { getInitials } from '../../../util/SiteHelper';
import { format } from 'date-fns';
import ColumnMenu from './ColumnMenu';

const getTradeStautsColor = (status: string) => {
  if (status === TradeStatus.PENDING) {
    return TEXT_COLOR_INFO_TYPE.info;
  }
  if (status === TradeStatus.DECLINED) {
    return TEXT_COLOR_INFO_TYPE.error;
  }
  return TEXT_COLOR_INFO_TYPE.success;
};
const renderValidity = (planningTrade: PlanningTradeOfferType) => {
  if (planningTrade.summaryValidity === 'Valid') {
    return (
      <HtmlTooltip
        title={
          <Header2
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
            fontcolor={TEXT_COLOR_INFO_TYPE.success}
            textsize='12px'
            style={{ wordWrap: 'break-word', whiteSpace: 'break-spaces' }}
            align='center'
          >
            <CheckCircleOutline style={{ color: TEXT_COLOR_INFO_TYPE.success, fontSize: 12 }} />{' '}
            This Trade is valid
          </Header2>
        }
      >
        <CheckCircleOutline style={{ color: TEXT_COLOR_INFO_TYPE.success }} />
      </HtmlTooltip>
    );
  }
  if (planningTrade.summaryValidity === 'Warning') {
    return (
      <HtmlTooltip
        title={
          <Header2
            style={{ wordWrap: 'break-word', whiteSpace: 'break-spaces' }}
            fontcolor={TEXT_COLOR_INFO_TYPE.info}
            textsize='12px'
            alignItems={'center'}
            align='center'
          >
            <MdInfoOutline style={{ color: TEXT_COLOR_INFO_TYPE.info, fontSize: 12 }} /> One or more
            picks have moved positions Trade can stil process but with updated pick numbers
          </Header2>
        }
      >
        <span>
          <MdInfoOutline style={{ color: TEXT_COLOR_INFO_TYPE.info, fontSize: 24 }} />
        </span>
      </HtmlTooltip>
    );
  }
  return (
    <Header2
      display={'flex'}
      justifyContent={'center'}
      fontcolor={TEXT_COLOR_INFO_TYPE.error}
      textsize='18px'
      gap={'10px'}
      alignItems={'center'}
      style={{ wordWrap: 'break-word', whiteSpace: 'break-spaces' }}
    >
      <Tooltip
        title={
          planningTrade.tradeBackRuleValidity.tradeBackRule === 'Invalid'
            ? planningTrade.tradeBackRuleValidity.description ||
              planningTrade.tradeBackRuleValidity.cannotTradeBack
            : planningTrade.pickExistsValidity.checkPickExists === 'Invalid'
            ? planningTrade.pickExistsValidity.description ||
              planningTrade.pickExistsValidity.picksNotValid
            : ''
        }
      >
        <CloseOutlined style={{ color: TEXT_COLOR_INFO_TYPE.error }} />
      </Tooltip>
    </Header2>
  );
};
export const tradeListColumn = (
  approveTrade: (tradeInfo: PlanningTradeOfferType) => void,
  declineOffer: (tradeInfo: PlanningTradeOfferType) => void,
  editTrade: (tradeInfo: PlanningTradeOfferType) => void,
  disapproveTrade: (tradeInfo: PlanningTradeOfferType) => void,
  emailTrade: (tradeInfo: PlanningTradeOfferType) => void,
) => [
  {
    field: 'transactionType',
    headerName: '',
    minWidth: 10,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <ColumnMenu
        disableDecline={params.row.tradeStatus === TradeStatus.DECLINED}
        declineOffer={() => declineOffer(params.row)}
        approveTrade={() => approveTrade(params.row)}
        disapproveTrade={() => disapproveTrade(params.row)}
        editTrade={() => editTrade(params.row)}
        emailTrade={() => emailTrade(params.row)}
        disableSummaryValidity={
          params.row.tradeStatus === TradeStatus.APPROVED ||
          params.row.tradeStatus === TradeStatus.DECLINED
        }
      />
    ),
  },
  {
    field: 'id',
    headerName: '#',
    width: 50,
    renderCell: (params: GridCellParams) => <Header2>{params.row.index}</Header2>,
  },
  {
    field: 'team1Name',
    headerName: 'Team 1',
    flex: 1,
    minWidth: 200,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <Grid container>
        <Grid item xs={12}>
          <Box display={'flex'} alignItems={'center'} gap={'5px'}>
            <Box mt={'5px'}>
              <StyledLogo
                src={
                  TeamFlagOptions.find((flag) => flag.id === params.row.payload.teamid1)
                    ?.flagIcon || ''
                }
                style={{ height: 36, width: 36 }}
                alt={params.row.currentOwnerShort}
              />
            </Box>
            {params.row.payload.pick1.map((item: PayloadPickType) => (
              <HtmlTooltip
                title={
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    height={'100%'}
                    textAlign={'center'}
                    width={'100%'}
                  >
                    <Box>
                      <Box mb={'5px'}>
                        <StyledLogo
                          src={
                            TeamFlagOptions.find((flag) => flag.id === params.row.payload.teamid1)
                              ?.flagIcon || ''
                          }
                          style={{ height: 30, width: 30 }}
                          alt={params.row.currentOwnerShort}
                        />
                      </Box>
                      <Header2>
                        {params.row.payload.player1
                          .map((item: any) => item?.player_id || item)
                          .join(', ')}
                      </Header2>
                      <Header2 style={{ wordWrap: 'break-word', whiteSpace: 'break-spaces' }}>
                        {item.label}
                      </Header2>
                    </Box>
                  </Box>
                }
              >
                <Box>
                  <CompactTag
                    style={{ padding: '3px' }}
                    gap={'5px'}
                    key={item.display}
                    display={'flex'}
                    alignItems={'center'}
                    textsize={'16px'}
                    textAlign={'center'}
                    bgcolor={'transparent'}
                    fontcolor={'#189a18'}
                    minWidth={30}
                    minHeight={30}
                  >
                    {item.display}
                  </CompactTag>
                </Box>
              </HtmlTooltip>
            ))}
            {params.row.payload.player1.map((item: any) => (
              <Tooltip title={item.player_id}>
                <CompactTag
                  style={{ padding: '3px' }}
                  gap={'5px'}
                  key={item.display}
                  display={'flex'}
                  alignItems={'center'}
                  textsize={'16px'}
                  textAlign={'center'}
                  bgcolor={'transparent'}
                  fontcolor={'#2a93d5'}
                  minWidth={30}
                  minHeight={30}
                >
                  {getInitials(item.player_id)}
                </CompactTag>
              </Tooltip>
            ))}
          </Box>
        </Grid>
      </Grid>
    ),
  },
  {
    field: 'team2Name',
    headerName: 'Team 2',
    flex: 1,
    minWidth: 200,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <Grid container>
        <Grid item xs={12}>
          <Box display={'flex'} alignItems={'center'} gap={'5px'}>
            <Box mt={'5px'}>
              <StyledLogo
                src={
                  TeamFlagOptions.find((flag) => flag.id === params.row.payload.teamid2)
                    ?.flagIcon || ''
                }
                style={{ height: 36, width: 36 }}
                alt={params.row.currentOwnerShort}
              />
            </Box>
            {params.row.payload.pick2.map((item: PayloadPickType) => (
              <HtmlTooltip
                title={
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    height={'100%'}
                    textAlign={'center'}
                    width={'100%'}
                  >
                    <Box>
                      <Box mb={'5px'}>
                        <StyledLogo
                          src={
                            TeamFlagOptions.find((flag) => flag.id === params.row.payload.teamid2)
                              ?.flagIcon || ''
                          }
                          style={{ height: 30, width: 30 }}
                          alt={params.row.currentOwnerShort}
                        />
                      </Box>
                      <Header2>
                        {params.row.payload.player2
                          .map((item: any) => item?.player_id || item)
                          .join(', ')}
                      </Header2>
                      <Header2 style={{ wordWrap: 'break-word', whiteSpace: 'break-spaces' }}>
                        {item.label}
                      </Header2>
                    </Box>
                  </Box>
                }
              >
                <Box>
                  <CompactTag
                    style={{ padding: '3px' }}
                    gap={'5px'}
                    key={item.display}
                    display={'flex'}
                    alignItems={'center'}
                    textsize={'16px'}
                    textAlign={'center'}
                    bgcolor={'transparent'}
                    fontcolor={'#189a18'}
                    minWidth={30}
                    minHeight={30}
                  >
                    {item.display}
                  </CompactTag>
                </Box>
              </HtmlTooltip>
            ))}
            {params.row.payload.player2.map((item: any) => (
              <Tooltip title={item.player_id}>
                <CompactTag
                  style={{ padding: '3px' }}
                  gap={'5px'}
                  key={item.display}
                  display={'flex'}
                  alignItems={'center'}
                  textsize={'16px'}
                  textAlign={'center'}
                  bgcolor={'transparent'}
                  fontcolor={'#2a93d5'}
                  minWidth={30}
                  minHeight={30}
                >
                  {getInitials(item.player_id)}
                </CompactTag>
              </Tooltip>
            ))}
          </Box>
        </Grid>
      </Grid>
    ),
  },
  {
    field: 'team1PtsOut',
    headerName: 'Points In/Out',
    flex: 1,
    minWidth: 120,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <Box>
        <Header2 display={'flex'} alignItems={'center'}>
          Out: {params.row.team1PtsOut}
        </Header2>
        <Header2 display={'flex'} alignItems={'center'} fontcolor='grey'>
          In: {params.row.team2PtsOut}
        </Header2>
      </Box>
    ),
  },
  {
    field: 'tradeStatus',
    headerName: 'Status',
    flex: 1,
    minWidth: 150,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <Tag bgcolor={getTradeStautsColor(params.row.tradeStatus)}>{params.row.tradeStatus}</Tag>
    ),
  },
  {
    field: 'tradeOfferType',
    headerName: 'Offer Type',
    flex: 1,
    minWidth: 120,
    sortable: false,
    renderCell: (params: GridCellParams) => <Header2>{params.row.tradeOfferType}</Header2>,
  },
  {
    field: 'team1NetResult',
    headerName: 'Difference',
    flex: 1,
    minWidth: 85,
    sortable: true,
    renderCell: (params: GridCellParams) => (
      <Header2
        fontcolor={
          params.row.team1NetResult >= 0 ? TEXT_COLOR_INFO_TYPE.success : TEXT_COLOR_INFO_TYPE.error
        }
      >
        {params.row.team1NetResult}
      </Header2>
    ),
  },
  {
    field: 'summaryValidity',
    headerName: 'Validity',
    minWidth: 50,
    sortable: false,
    renderCell: (params: GridCellParams) => renderValidity(params.row),
  },
  {
    field: 'notes',
    headerName: 'Notes',
    flex: 3,
    minWidth: 300,
    sortable: false,
    renderCell: (params: GridCellParams) => <Header2>{params.row.notes}</Header2>,
  },
  {
    field: 'tradeCreated',
    headerName: 'Created/Updated',
    flex: 1,
    minWidth: 160,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <Box>
        <Header2>{format(new Date(params.row.tradeCreated), 'dd MMM, yyyy')}/</Header2>
        <Header2 fontcolor='grey'>
          {format(new Date(params.row.tradeLastModified), 'dd MMM, yyyy')}
        </Header2>
      </Box>
    ),
  },
];
