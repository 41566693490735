import { TimelineOfEntryType } from '../../playerinfo/type';
import { Grid, Typography } from '@mui/material';
import { format } from 'date-fns';

type TimeLineHistoryListProps = {
  timelineHistory: TimelineOfEntryType[];
};
const TimeLineHistoryList = ({ timelineHistory }: TimeLineHistoryListProps) => {
  return (
    <Grid container>
      {timelineHistory.map((info) => (
        <Grid
          mt={'5px'}
          borderRadius={'5px'}
          bgcolor={'#efefef'}
          p={'5px'}
          item
          xs={12}
          key={info.id}
        >
          <Typography variant='body2' mb={'5px'}>
            Created At:{' '}
            <span style={{ fontWeight: 'bold' }}>
              {format(new Date(info.createdAt), 'do MMM yyyy')}
            </span>{' '}
            | Updated At:{' '}
            <span style={{ fontWeight: 'bold' }}>
              {format(new Date(info.updatedAt), 'do MMM yyyy')}
            </span>{' '}
            | Created By:{' '}
            <span style={{ fontWeight: 'bold' }}>
              {info.createdBy.firstName
                ? `${info.createdBy.firstName} ${info.createdBy.lastName}`
                : '-------'}
            </span>
          </Typography>
          {info.fields.map((item, index) => (
            <Grid item xs={12} borderBottom={'1px #efefef solid'} key={`${item.id}-${index}`}>
              <Grid container spacing={'10px'} alignItems={'center'}>
                <Grid item xs={12}>
                  <Typography fontWeight={'bold'} color={'#333'}>
                    {item.value}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

export default TimeLineHistoryList;
