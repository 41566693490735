import React, { FC } from 'react';
import FlagList from './component/FlagList';
import { useFlag } from './hook';
import { Grid } from '@mui/material';
import { FlagTooltipType } from '../header/type';

type Props = {
  flagTooltip: FlagTooltipType[];
  scenarioFlag?: boolean;
  refreshFlagTooltip?: () => void;
};
const Flags: FC<Props> = ({ flagTooltip, scenarioFlag, refreshFlagTooltip }) => {
  const { flagsList, selectedFlag, updateSelectedFlag } = useFlag();

  return (
    <Grid item xs={12}>
      <Grid
        id={'flaglist'}
        style={
          scenarioFlag
            ? {
                display: 'flex',
                justifyContent: 'center',
              }
            : {}
        }
      >
        <FlagList
          flagTooltip={flagTooltip}
          setSelectedFlag={updateSelectedFlag}
          selectedFlag={selectedFlag}
          flagList={flagsList}
          refreshTooltip={refreshFlagTooltip}
        />
      </Grid>
    </Grid>
  );
};

export default Flags;
