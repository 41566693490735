import { FC } from 'react';
import { FatherSonDataSummaryType, NgaLineType } from '../../type';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { CompactTag, Header3, Tag } from '../../../../common/component/Text';
import { StyledLogo } from '../../../dashboard/style';
import { TeamFlagOptions } from '../../../../constant/OptionConstant';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { TEXT_COLOR_INFO_TYPE } from '../../../../theme/color';
import DraftOverallImpactInfo from './DraftOverallImpactInfo';

type CalculationInfoProps = {
  draftInfo: FatherSonDataSummaryType | NgaLineType | null;
  isFilterByPointDeficit?: boolean;
};
const CalculationInfo: FC<CalculationInfoProps> = ({
  draftInfo,
  isFilterByPointDeficit = false,
}) => {
  const leftFlagSrc = draftInfo
    ? TeamFlagOptions.find((item) => item.id === Number(draftInfo.bidTeam))?.flagIcon
    : null;
  const rightFlagSrc = draftInfo
    ? TeamFlagOptions.find((item) => item.id === Number(draftInfo.fsTeam))?.flagIcon
    : null;

  return (
    <Grid container justifyContent={'center'}>
      <Grid item xs={12}>
        <Header3 align='center'>Calculations:</Header3>
      </Grid>
      {draftInfo && (
        <Grid item xs={12} mt={'30px'}>
          <Box>
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={'30px'}
              flexWrap={'nowrap'}
            >
              {leftFlagSrc && (
                <StyledLogo src={leftFlagSrc} style={{ height: 30, width: 30 }} alt={'flag'} />
              )}

              <ArrowForwardIcon />
              {rightFlagSrc && (
                <StyledLogo src={rightFlagSrc} style={{ height: 30, width: 30 }} alt={'flag'} />
              )}

              <Tag bgcolor={'#d48482'}>{draftInfo.playerId}</Tag>
            </Box>
          </Box>
          <Box>
            <Typography mt={'30px'} textAlign={'center'}>
              {draftInfo.bidSummary}
            </Typography>
            <Typography mt={'30px'} textAlign={'center'}>
              {draftInfo.bidSummary2}
            </Typography>
          </Box>
          <Box display={'flex'} mt={'30px'}>
            <Box width={'50%'}>
              <Header3 textAlign={'center'}>{draftInfo.listSpotAvailaible}</Header3>
              <Typography mt={'10px'} textAlign={'center'}>
                List Spots Availaible
              </Typography>
            </Box>
            <Box width={'50%'}>
              <Header3 textAlign={'center'}>{draftInfo.pickUsed}</Header3>
              <Typography mt={'10px'} textAlign={'center'}>
                Picks Used
              </Typography>
            </Box>
          </Box>
          <Box mt={'20px'}>
            <Header3 textAlign={'center'}>Impact of matching Bid</Header3>
            <Typography mb={'30px'} mt={'10px'} textAlign={'center'}>
              Total Pts owed: {draftInfo.pointsRequired} pts
            </Typography>
            <Box width={'fit-content'} m={'auto'}>
              {draftInfo.overallImpactVisual
                .filter((item) =>
                  isFilterByPointDeficit
                    ? item.summary === 'Points Deficit'
                    : item.summary !== 'Points Deficit',
                )
                .map((item) => (
                  <Box
                    width={'100%'}
                    my={'10px'}
                    alignItems={'center'}
                    display={'flex'}
                    gap={'30px'}
                  >
                    <Typography width={'40px'}>{item.prefix}</Typography>

                    <Tooltip title={item.firstValueTooltip}>
                      <CompactTag
                        gap={'5px'}
                        display={'flex'}
                        alignItems={'center'}
                        textsize={'12px'}
                        textAlign={'center'}
                        bgcolor={'transparent'}
                        fontcolor={'#189a18'}
                        minWidth={15}
                      >
                        {item.firstVisual}
                      </CompactTag>
                    </Tooltip>

                    <ArrowForwardIcon />
                    <Tooltip title={item.summary}>
                      {item.secondValue ? (
                        <CompactTag
                          gap={'5px'}
                          display={'flex'}
                          alignItems={'center'}
                          textsize={'12px'}
                          textAlign={'center'}
                          bgcolor={'transparent'}
                          fontcolor={'#189a18'}
                          minWidth={15}
                        >
                          {item.secondValue}
                        </CompactTag>
                      ) : (
                        <DoDisturbIcon style={{ color: TEXT_COLOR_INFO_TYPE.error }} />
                      )}
                    </Tooltip>
                    <Typography
                      style={{
                        color:
                          Number(item.pointsRemaining) < 0 ? TEXT_COLOR_INFO_TYPE.error : '#000',
                      }}
                    >
                      {item.pointsRemaining} pts
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Box>
          <DraftOverallImpactInfo
            impactDictInfo={draftInfo.overallImpactDict[draftInfo.overallImpactDict.length - 1]}
            impactVisualInfo={
              draftInfo.overallImpactVisual[draftInfo.overallImpactVisual.length - 1]
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CalculationInfo;
