import { IFlagList } from '../../container/flags/type';

export const SITE_STATUS = 'SITE_STATUS';

export interface ISiteStatus {
  currentProject: string;
  selectedFlag: string;
  currentOrganisation: string;
  selectedTransactionModal: string;
  isSideBarOpen: boolean;
  flagList: IFlagList[];
  hoveredTeamId: string;
  refreshFlagData: string;
  uploadCsvTransactionTypeModal: UploadCsvTransactionType | null;
}

export type UploadCsvTransactionType = {
  transactionType: string;
  payload: any;
};
