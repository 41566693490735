import React, { FC } from 'react';
import AcademyBidMatch from './component/AcademyBidMatch';
import CompletedTrade from './component/CompletedTrade';
import DeleteUnusablePick from './component/DeleteUnusablePick';
import DraftNightSection from './component/DraftNightSection';
import FatherBidMatch from './component/FatherSonBidMatch';
import FreeAgentCompensation from './component/freeagentcompensation/FreeAgentCompensation';
import ManualPickEdit from './component/ManualPickEdit';
import ManualPickInsert from './component/ManualPickInsert';
import ManualPickMove from './component/ManualPickMove';
import NgaBidMatch from './component/NgaBidMatch';
import PriorityPick from './component/PriorityPick';
import MultipleCompletedTrade from './component/multipletrade/MultipleCompletedTrade';
import UploadCsvModal from './component/uploadcsvmodal/UploadCsvModal';
import { UploadCsvTransactionType } from '../../store/header/type';
import PassPicks from './component/passpicks/PassPicks';

type Props = {
  selectedTransactionModal: string;
  closeTransactionModal: () => void;
  uploadCsvTransactionType: UploadCsvTransactionType | null;
};
const TransactionModal: FC<Props> = ({
  selectedTransactionModal,
  closeTransactionModal,
  uploadCsvTransactionType,
}) => {
  if (uploadCsvTransactionType && uploadCsvTransactionType.transactionType !== '') {
    return <UploadCsvModal uploadCsvTransactionType={uploadCsvTransactionType} />;
  }
  if (selectedTransactionModal === 'delete_unusable_picks') {
    return <DeleteUnusablePick closeTransactionModal={closeTransactionModal} />;
  }

  if (selectedTransactionModal === 'manual_pick_edit') {
    return <ManualPickEdit closeTransactionModal={closeTransactionModal} />;
  }

  if (selectedTransactionModal === 'draft_night_selection') {
    return <DraftNightSection closeTransactionModal={closeTransactionModal} />;
  }
  
  if (selectedTransactionModal === 'pass_picks') {
    return <PassPicks closeTransactionModal={closeTransactionModal} />;
  }

  if (selectedTransactionModal === 'manual_pick_move') {
    return <ManualPickMove closeTransactionModal={closeTransactionModal} />;
  }

  if (selectedTransactionModal === 'manual_pick_insert') {
    return <ManualPickInsert closeTransactionModal={closeTransactionModal} />;
  }

  if (selectedTransactionModal === 'nga_bid_match') {
    return <NgaBidMatch closeTransactionModal={closeTransactionModal} />;
  }

  if (selectedTransactionModal === 'free_agent_compensation') {
    return <FreeAgentCompensation closeTransactionModal={closeTransactionModal} />;
  }

  if (selectedTransactionModal === 'priority_pick') {
    return <PriorityPick closeTransactionModal={closeTransactionModal} />;
  }

  if (selectedTransactionModal === 'completed_trade') {
    return <CompletedTrade closeTransactionModal={closeTransactionModal} />;
  }

  if (selectedTransactionModal === 'multi_completed_trade') {
    return <MultipleCompletedTrade closeTransactionModal={closeTransactionModal} />;
  }

  if (selectedTransactionModal === 'academy_bid_match') {
    return <AcademyBidMatch closeTransactionModal={closeTransactionModal} />;
  }

  if (selectedTransactionModal === 'father_son_bid_match') {
    return <FatherBidMatch closeTransactionModal={closeTransactionModal} />;
  }

  return null;
};

export default TransactionModal;
