import { object, string } from 'yup';
import { TabOptionType } from '../../common/component/type';
import { AddPlayerType, AdditionalFilterType, PlayerDraftBoardType } from './type';

export const draftBoardTabOption: TabOptionType[] = [
  {
    label: 'Draft Tiers',
    value: 'tiers',
  },
  {
    label: 'Player Database',
    value: 'database',
  },
];

export const transformDraftList = (data: any): PlayerDraftBoardType[] => {
  if (!data) {
    return [];
  }
  return data.map((item: any) => ({
    firstName: item.FirstName,
    fullName: item.Full_Name,
    height: item.Height,
    notes: item.Notes,
    position1: item.Position_1,
    position2: item.Position_2,
    rank: Number(item.Rank),
    state: item.State,
    tier: item.Tier,
    weight: item.Weight,
    club: item.club,
    id: item.id,
    projectId: item.projectId,
    selected: item.Selected,
  }));
};

export const addPlayerInitialValue: AddPlayerType = {
  firstName: '',
  lastName: '',
  position1: '',
  file: null,
};

export const AddPlayerValidationSchema = object({
  firstName: string().when('file', (file: any) => {
    if (file[0] !== null) {
      return string();
    }
    return string().required('Please enter first name');
  }),
  lastName: string().when('file', (file: any) => {
    if (file[0] !== null) {
      return string();
    }
    return string().required('Please enter last name');
  }),
});

const filterMap = {
  searchText: {
    filterFn: (text: string) => {
      if (text === '') {
        return () => true;
      }
      return (player: PlayerDraftBoardType) =>
        player.firstName.toLowerCase().includes(text.toLowerCase()) ||
        player.fullName.toLowerCase().includes(text.toLowerCase()) ||
        player.position1.toLowerCase().includes(text.toLowerCase()) ||
        player.position2.toLowerCase().includes(text.toLowerCase()) ||
        player.club.toLowerCase().includes(text.toLowerCase());
    },
  },
  height: {
    filterFn: (height: number[]) => {
      if (height[0] === 0 || height[1] === 0) {
        return () => true;
      }
      return (player: PlayerDraftBoardType) =>
        Number(player.height) >= height[0] && Number(player.height) <= height[1];
    },
  },
  weight: {
    filterFn: (weight: number[]) => {
      if (weight[0] === 0 || weight[1] === 0) {
        return () => true;
      }
      return (player: PlayerDraftBoardType) =>
        Number(player.weight) >= weight[0] && Number(player.weight) <= weight[1];
    },
  },
  tier: {
    filterFn: (tier: number[]) => {
      if (tier[0] === 0 || tier[1] === 0) {
        return () => true;
      }
      return (player: PlayerDraftBoardType) =>
        Number(player.tier) >= tier[0] && Number(player.tier) <= tier[1];
    },
  },
  rank: {
    filterFn: (rank: number[]) => {
      if (rank[0] === 0 || rank[1] === 0) {
        return () => true;
      }
      return (player: PlayerDraftBoardType) =>
        Number(player.rank) >= rank[0] && Number(player.rank) <= rank[1];
    },
  },
  state: {
    filterFn: (state: string[]) => {
      if (state.length === 0) {
        return () => true;
      }
      return (player: PlayerDraftBoardType) => state.includes(player.state);
    },
  },
  position1: {
    filterFn: (position: string[]) => {
      if (position.length === 0) {
        return () => true;
      }
      const lowercasePosition = position.map((item) => item.toLowerCase());
      return (player: PlayerDraftBoardType) =>
        lowercasePosition.includes(player.position1.toLowerCase()) ||
        lowercasePosition.includes(player.position2.toLowerCase());
    },
  },
};

export const filterPlayerDatabaseList = (
  list: PlayerDraftBoardType[],
  text: string,
  additionalFilter: AdditionalFilterType,
) => {
  if (!list) {
    return [];
  }
  let filteredSearchResults = list;
  filteredSearchResults = filteredSearchResults.filter(filterMap.searchText.filterFn(text));
  filteredSearchResults = filteredSearchResults.filter(
    filterMap.height.filterFn(additionalFilter.height),
  );
  filteredSearchResults = filteredSearchResults.filter(
    filterMap.weight.filterFn(additionalFilter.weight),
  );
  filteredSearchResults = filteredSearchResults.filter(
    filterMap.tier.filterFn(additionalFilter.tier),
  );
  filteredSearchResults = filteredSearchResults.filter(
    filterMap.state.filterFn(additionalFilter.state),
  );
  filteredSearchResults = filteredSearchResults.filter(
    filterMap.position1.filterFn(additionalFilter.position),
  );
  filteredSearchResults = filteredSearchResults.filter(
    filterMap.rank.filterFn(additionalFilter.rank),
  );
  return filteredSearchResults;
};

export const initialAdditionalFilter = {
  height: [0, 0],
  weight: [0, 0],
  state: [],
  position: [],
  rank: [0, 0],
  tier: [0, 0],
};

export const moveElementToPosition = (
  arr: PlayerDraftBoardType[],
  element: PlayerDraftBoardType,
  newPosition: number,
) => {
  let copyArr = arr;
  if (newPosition < 0) {
    newPosition = 0;
  } else if (newPosition > arr.length) {
    newPosition = copyArr.length;
  }
  const findItem = arr.find((item) => item.id === element.id);
  if (findItem) {
    copyArr = copyArr.filter((el) => el !== findItem);
  }

  copyArr.splice(newPosition, 0, element);
  return copyArr;
};

export const sortPlayerListByRank = (
  playerList: PlayerDraftBoardType[],
): PlayerDraftBoardType[] => {
  return playerList.sort((a, b) => Number(a.rank) - Number(b.rank));
};

export const getTierNumber: Record<string, number> = {
  tier1: 1,
  tier2: 2,
  tier3: 3,
  tier4: 4,
  tier5: 5,
};
