import { baseQuesryWithErrorToast } from '..';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createPassPickApiUrl, passPickImpactApiUrl } from '../../../constant/ApiConstant';
import { PassPickType } from '../../../container/transaction/component/passpicks/type';
import { reverseTransformPassPickData } from './util';

export const passPickServiceApis = createApi({
  reducerPath: 'passpickservice',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQuesryWithErrorToast,
  endpoints: (builder) => ({
    getpassPickImpact: builder.mutation({
      query: ({ data, projectId }: { data: PassPickType; projectId: string }) => ({
        url: passPickImpactApiUrl(projectId),
        method: 'POST',
        body: reverseTransformPassPickData(data),
      }),
    }),
    createPassPickImpact: builder.mutation({
      query: ({ data, projectId }: { data: PassPickType; projectId: string }) => ({
        url: createPassPickApiUrl(projectId),
        method: 'POST',
        body: reverseTransformPassPickData(data),
      }),
    }),
  }),
});

export const { useGetpassPickImpactMutation, useCreatePassPickImpactMutation } =
  passPickServiceApis;
