/* eslint-disable react-hooks/rules-of-hooks */
import { Grid } from '@mui/material';
import { Formik } from 'formik';
import React, { FC } from 'react';
import CustomModal from '../../../common/component/CustomModal';
import FormikAutocomplete from '../../../common/fields/FormikAutocomplete';
import { useAcademyBidMatch, useAcademyFormBidMatch, useAcademyUpdateBidMatch } from '../hook';
import { AcademyBidMatchInitialValue, AcademyBidValidationSchema } from '../util';
import CalculationInfo from './fatherSonInfo/CalculationInfo';
import DraftHandInfo from './fatherSonInfo/DraftHandInfo';

type Props = {
  closeTransactionModal: () => void;
};

const AcademyBidMatch: FC<Props> = ({ closeTransactionModal }) => {
  const { teamDataDropdown, allPickDataDropdown, playerDataDropdown, currentProject } =
    useAcademyBidMatch();

  const { academyBidLoading, submitData } = useAcademyUpdateBidMatch();

  return (
    <>
      <Formik
        initialValues={AcademyBidMatchInitialValue}
        enableReinitialize
        validationSchema={AcademyBidValidationSchema}
        onSubmit={submitData}
      >
        {({ values, handleSubmit }) => {
          const { transactionInfo } = useAcademyFormBidMatch(values, currentProject);
          return (
            <CustomModal
              saveLoading={academyBidLoading}
              handleSubmit={() => handleSubmit()}
              modalSize={'md'}
              handleClose={closeTransactionModal}
              modalTitle='Academy Bid Match'
            >
              <Grid container columnSpacing={'50px'}>
                <Grid item xs={6}>
                  <Grid container rowSpacing={'20px'}>
                    <Grid item xs={12}>
                      <FormikAutocomplete
                        name='playerBelongsTo'
                        id='playerBelongsTo'
                        options={teamDataDropdown}
                        label={'Select Team Academy Player Belongs To'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikAutocomplete
                        name='academyPlayer'
                        id='academyPlayer'
                        options={playerDataDropdown}
                        label={'Select Academy Player'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikAutocomplete
                        name='bidPick'
                        id='bidPick'
                        options={allPickDataDropdown}
                        label={'Select Pick Of Bid'}
                      />
                    </Grid>
                    {transactionInfo && (
                      <Grid item xs={12}>
                        <DraftHandInfo
                          title='Starting Draft Hand'
                          draftHand={transactionInfo?.initialDraftHand}
                        />
                        <DraftHandInfo
                          title='Draft Hand if Bid Matched:'
                          draftHand={transactionInfo?.newDraftHand}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <CalculationInfo draftInfo={transactionInfo} />
                </Grid>
              </Grid>
            </CustomModal>
          );
        }}
      </Formik>
    </>
  );
};

export default AcademyBidMatch;