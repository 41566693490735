/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useGetPlayerDatabaseQuery } from '../../store/service/playerdatabaseservice';
import { useCurrentOrganisation } from '../../store/header/selector';
import { useAppSelector } from '../../store/hooks';
import { transformPlayerOptions } from '../playermanager/util';
import { PlayerDatabaseType } from '../playerdatabase/type';
import { transformPlayerList } from '../playerdatabase/util';
import { useNavigate, useParams } from 'react-router-dom';

export const usePlayerProfile = () => {
  const { playerId } = useParams();

  const [selectedPlayer, setSelectedPlayer] = useState<string>(playerId || '');
  const currentOrgId = useAppSelector(useCurrentOrganisation);
  const { data: playerData, refetch } = useGetPlayerDatabaseQuery(currentOrgId);
  const [selectedPlayerForEdit, setSelectedPlayerForEdit] = useState<
    PlayerDatabaseType | undefined
  >(undefined);
  const [refreshRandom, setRefreshListRandom] = useState(Math.random().toString());
  const playerList = useMemo(() => transformPlayerList(playerData), [playerData]);
  const navigate = useNavigate();

  useEffect(() => {
    if (playerId) {
      navigate(`/playerhome/${selectedPlayer}`);
    }
  }, [selectedPlayer, playerId]);

  const playerOptionList = useMemo(() => {
    const data = transformPlayerOptions(playerData);
    setSelectedPlayer(playerId || data?.[0]?.value || '');
    return data;
  }, [playerData, playerId]);

  const handleEditClick = () => {
    const findItem = playerList.find((item) => item.id.toString() === selectedPlayer.toString());
    if (findItem) {
      setSelectedPlayerForEdit(findItem);
    }
  };

  return {
    selectedPlayer,
    setSelectedPlayer,
    playerOptionList,
    handleEditClick,
    selectedPlayerForEdit,
    setSelectedPlayerForEdit,
    setRefreshListRandom,
    refreshRandom,
    refetch,
  };
};
