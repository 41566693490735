import { useEffect, useMemo } from 'react';
import { useCurrentProject } from '../../store/header/selector';
import { useAppSelector } from '../../store/hooks';
import { useLazyGetPlayersQuery } from '../../store/service';
import { transformDraftList } from '../draftboard/util';

export const usePlayerList = () => {
  const currentProject = useAppSelector(useCurrentProject);
  const [getPlayer, { data, isLoading }] = useLazyGetPlayersQuery();

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    getPlayer(currentProject);
  };

  const playerList = useMemo(() => transformDraftList(data), [data]);
  return {
    playerList,
    isLoading,
    refreshList: fetchList,
  };
};