import { usePlayerManager } from '../hook';
import { Grid } from '@mui/material';
import DataGridWrapper from '../../../common/component/DataGridWrapper';
import { PlayerManagerListColumn } from './ListColumn';
import { PrimaryButton } from '../../../common/component/Button';
import AddUpdatePlayerManager from './AddUpdatePlayerManager';
import DeleteModal from '../../../common/component/DeleteModal';

type PlayerManagerProps = {
  playerId: string;
};

const PlayerManager = ({ playerId }: PlayerManagerProps) => {
  const {
    data,
    loading,
    openAddModal,
    setOpenAddModal,
    refresh,
    selectedManagerForEdit,
    setSelectedManagerForEdit,
    deleteLoading,
    handelManagerDelete,
    selectedManagerForDelete,
    setSelectedManagerForDelete,
  } = usePlayerManager(playerId);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent={'flex-end'}>
          <Grid item xs={4}>
            <PrimaryButton onClick={() => setOpenAddModal(true)} title='Create Player Manager' />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={'10px'}>
        <DataGridWrapper
          columns={PlayerManagerListColumn(setSelectedManagerForEdit, setSelectedManagerForDelete)}
          rows={data}
          loading={loading}
        />
      </Grid>
      {(openAddModal || selectedManagerForEdit) && (
        <AddUpdatePlayerManager
          handleClose={() => {
            setSelectedManagerForEdit(undefined);
            setOpenAddModal(false);
          }}
          playerId={playerId}
          refreshList={() => {
            refresh();
            setSelectedManagerForEdit(undefined);
            setOpenAddModal(false);
          }}
          selectedManager={selectedManagerForEdit}
        />
      )}
      {selectedManagerForDelete && (
        <DeleteModal
          isLoading={deleteLoading}
          description='Are you sure you want to delete?'
          modalTitle='Delete Manager'
          handleClose={() => setSelectedManagerForDelete(undefined)}
          handleSubmit={handelManagerDelete}
        />
      )}
    </Grid>
  );
};

export default PlayerManager;
