export interface IProjectList {
  file: string;
  id: string;
  projectDescription: string;
  projectName: string;
  year: string;
  teamId: string;
}

export const PROJECT_LIST = 'PROJECT_LIST';
