import React, { FC } from 'react';
import { useField } from 'formik';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { StyledTextfieldContainer } from './Style';
import { Box } from '@mui/material';
import { FieldLabel } from '../component/Text';

type Props = {
  name: string;
  label?: string;
} & TextFieldProps;

const FormikTextInput: FC<Props> = ({ name, label, ...rest }) => {
  const [field, { error, touched }, { setTouched }] = useField(name);

  return (
    <Box>
      <FieldLabel>{label}</FieldLabel>
      <StyledTextfieldContainer iserror={!!error && !!touched ? 1 : 0}>
        <TextField
          {...field}
          name={name}
          onBlur={() => setTouched(true)}
          autoComplete={'off'}
          size={'small'}
          error={!!error && !!touched}
          helperText={!!error && !!touched && error}
          variant='standard'
          {...rest}
        />
      </StyledTextfieldContainer>
    </Box>
  );
};

export default FormikTextInput;
