import { ProjectListType, ProjectType, RosterListType } from './type';
import { OptionType } from '../../common/fields/type';
import { uniqBy } from 'lodash';
import { object, string } from 'yup';

export const transformProjectList = (data: any): ProjectListType[] => {
  if (!data) {
    return [];
  }

  return data.map(
    (item: any, index: number) =>
      ({
        file: item.file,
        id: index + 1,
        projectId: item.id,
        projectDesc: item.project_desc,
        projectName: item.project_name,
        teamid: item.teamid,
        userId: item.user_id,
        year: item.year,
        bidDiscounts: item?.bidding_discount_type || '',
        draftValueIndex: item?.draft_value_index_type || '',
        draftType: item.draft_type,
      } as ProjectListType),
  );
};

export const createProjectInitialValue = (projectInfo?: ProjectListType): ProjectType => ({
  projectName: projectInfo?.projectName || '',
  projectDescription: projectInfo?.projectDesc || '',
  draftYear: projectInfo?.year || '',
  team: parseInt(projectInfo?.teamid || '0') || '',
  bidDiscounts: projectInfo?.bidDiscounts || '',
  draftValueIndex: projectInfo?.draftValueIndex || '',
  draftType: projectInfo?.draftType || '',
});

export const transformShowDraftOption = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }

  return data.map((item: any) => ({
    value: item.draft_type,
    label: item.display_name,
    unique: item.year,
  }));
};

const transformData = (originalData: any): RosterListType[] => {
  const transformedData: RosterListType[] = [];

  originalData.forEach((item: any, index: number) => {
    const transformedItem = {
      teamId: item.team_id,
      currentYearAmount: item.roster_spots_available,
      nextYearAmount:
        index + 1 < originalData.length ? originalData[index + 1].roster_spots_available : null,
      id: index + 1,
      currentYear: item.year,
      nextYear: index + 1 < originalData.length ? originalData[index + 1].year : null,
    };

    transformedData.push(transformedItem);
  });

  return uniqBy(transformedData, 'teamId');
};

export const transformRosterData = (data: any): RosterListType[] => {
  if (!data) {
    return [];
  }

  return transformData(data);
};

export const projectValidationSchema = object().shape({
  projectDescription: string().required('Please enter project description'),
  draftType: string().required('Please select draft type'),
  projectName: string().required('Please enter project name'),
  team: string().required('Please select team'),
  bidDiscounts: string(),
  draftValueIndex: string(),
});
