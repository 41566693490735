/* eslint-disable react-hooks/rules-of-hooks */
import { FC } from 'react';
import CustomModal from '../../../common/component/CustomModal';
import { CompletedTradeInitialValue, CompletionTradeValidationSchema } from '../util';
import { Grid } from '@mui/material';
import { Header2 } from '../../../common/component/Text';
import { useCompleteFormTrade, useCompleteTrade } from '../hook';
import CompletedTradeImpact from './CompletedTradeImpact';
import { PlanningTradeOfferType } from '../../planner/type';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomAutocomplete from '../../../common/fields/reacthookfield/CustomAutocomplete';
import CustomTextInput from '../../../common/fields/reacthookfield/CustomTextInput';
import CustomSelect from '../../../common/fields/reacthookfield/CustomSelect';
import CustomeDatepicker from '../../../common/fields/reacthookfield/CustomeDatepicker';
import CompletedTradeDataGrid from './CompletedTradeDataGrid';

type Props = {
  closeTransactionModal: () => void;
  isTradeAnalysis?: boolean;
  refreshTrade?: () => void;
  editTrade?: PlanningTradeOfferType;
};

const CompletedTrade: FC<Props> = ({
  closeTransactionModal,
  isTradeAnalysis,
  refreshTrade,
  editTrade,
}) => {
  const {
    currentProject,
    teamDataDropdown,
    isLoading,
    submitData,
    setTeam1PickDropdown,
    setTeam2PickDropdown,
    team1PickDropdown,
    team2PickDropdown,
    addTradeData,
    createNewTransactionLoading,
    updateTradeLoading,
    tradeStatusOption,
    tradeTypeOption,
  } = useCompleteTrade(isTradeAnalysis, refreshTrade, editTrade);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: CompletedTradeInitialValue(editTrade, isTradeAnalysis),
    resolver: yupResolver(CompletionTradeValidationSchema),
  });

  const team1Watch = useWatch({
    control,
    name: 'team1',
  });
  const team2Watch = useWatch({
    control,
    name: 'team2',
  });
  const tradeStatusWatch = useWatch({
    control,
    name: 'offerStatus',
  });
  const tradeOfferWatch = useWatch({
    control,
    name: 'offerType',
  });
  const noteWatch = useWatch({
    control,
    name: 'note',
  });
  const { transactionImpact } = useCompleteFormTrade(
    {
      team1: team1Watch,
      team2: team2Watch,
      note: noteWatch,
    },
    currentProject,
    setTeam1PickDropdown,
    setTeam2PickDropdown,
    team1PickDropdown,
    team2PickDropdown,
  );

  const getCompleteTradeTitle = () => {
    if (typeof editTrade === 'object') {
      return 'Update Trade Offer';
    }
    if (isTradeAnalysis) {
      return 'Add Trade Offer';
    }
    return 'Add Trade';
  };

  return (
    <CustomModal
      saveLoading={isLoading || createNewTransactionLoading || updateTradeLoading}
      handleSubmit={handleSubmit(submitData)}
      modalSize={'lg'}
      handleClose={closeTransactionModal}
      modalTitle={getCompleteTradeTitle()}
    >
      <Grid container spacing={'30px'}>
        <Grid item xs={6}>
          <Grid container spacing={'30px'}>
            <Grid item xs={6}>
              <Grid container spacing={'10px'}>
                <Grid item xs={12}>
                  <Header2 fontweight={700}>Team 1</Header2>
                </Grid>
                <Grid item xs={12}>
                  <CustomAutocomplete
                    name='team1.name'
                    id='team1.name'
                    control={control}
                    errorMessage={errors.team1?.name?.message}
                    errors={errors}
                    key={teamDataDropdown.length}
                    options={teamDataDropdown}
                    label={'Select Team 1'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomAutocomplete
                    name='team1.tradingOut'
                    id='team1.tradingOut'
                    control={control}
                    errors={errors}
                    ismultiselect
                    options={team1PickDropdown}
                    label={'Select Picks Trading Out:'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextInput
                    name={'team1.playerName'}
                    id={'team1.playerName'}
                    register={register}
                    fullWidth
                    variant='outlined'
                    placeholder='Use comma to separate multiple players'
                    label={'Enter players trading out:'}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={'10px'}>
                <Grid item xs={12}>
                  <Header2 fontweight={700}>Team 2</Header2>
                </Grid>
                <Grid item xs={12}>
                  <CustomAutocomplete
                    name='team2.name'
                    id='team2.name'
                    control={control}
                    errorMessage={errors.team2?.name?.message}
                    errors={errors}
                    key={teamDataDropdown.length}
                    options={teamDataDropdown}
                    label={'Select Team 2'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomAutocomplete
                    name='team2.tradingOut'
                    id='team2.tradingOut'
                    control={control}
                    errors={errors}
                    ismultiselect
                    options={team2PickDropdown}
                    label={'Select Picks Trading Out:'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextInput
                    name={'team2.playerName'}
                    id={'team2.playerName'}
                    fullWidth
                    register={register}
                    placeholder='Use comma to separate multiple players'
                    variant='outlined'
                    label={'Enter players trading out:'}
                  />
                </Grid>
              </Grid>
            </Grid>
            {isTradeAnalysis && (
              <Grid item xs={12}>
                <CustomTextInput
                  name={'note'}
                  id={'note'}
                  register={register}
                  multiline
                  rows={4}
                  fullWidth
                  placeholder='Enter Note'
                  variant='outlined'
                  label={'Enter Note'}
                />
              </Grid>
            )}
            {isTradeAnalysis && (
              <Grid item xs={12}>
                <Grid container rowSpacing={'20px'} columnSpacing={'30px'}>
                  <Grid item xs={6}>
                    <CustomSelect
                      label={'Trade Status'}
                      register={register}
                      options={tradeStatusOption}
                      name={'offerStatus'}
                      value={tradeStatusWatch || ''}
                      key={tradeStatusOption.length}
                      id='offerStatus'
                      disabled={typeof editTrade === 'object'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomSelect
                      label={'Trade Option'}
                      register={register}
                      key={tradeTypeOption.length}
                      options={tradeTypeOption}
                      name={'offerType'}
                      value={tradeOfferWatch}
                      id='offerType'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomeDatepicker
                      control={control}
                      fieldLabel={'Date Offer Created'}
                      name={'created'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomeDatepicker
                      fieldLabel={'Date Offer Updated'}
                      name={'lastModified'}
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {transactionImpact && (
              <Grid item xs={12}>
                <CompletedTradeImpact
                  team1={Number(team1Watch.name)}
                  team2={Number(team2Watch.name)}
                  tradeImpactInfo={transactionImpact}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12} minHeight={'400px'} height={'100%'}>
            <CompletedTradeDataGrid data={addTradeData} />
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default CompletedTrade;
