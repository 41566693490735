import { OptionType } from '../../common/fields/type';
import { PlayerDraftBoardType } from '../draftboard/type';

export const filterPlayerList = (
  playerList: PlayerDraftBoardType[],
  showSelectedPlayer: boolean,
) => {
  if (!showSelectedPlayer) {
    return playerList;
  }
  return playerList.filter((item) => !item.selected);
};

export const transformPlayerPosition = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        label: item.display,
        value: item.value,
      } as OptionType),
  );
};