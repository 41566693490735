import { FC } from 'react';
import { Popover, Grid } from '@mui/material';
import { FiLogOut, FiSettings } from 'react-icons/fi';
import { OptionButton } from '../style';
import { useNavigate } from 'react-router-dom';
import { SHOW_AUTH0_LOGIN } from '../../../util/SiteHelper';
import { clearUser } from '../../../store/auth';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useGetUserDetail } from '../../../store/auth/selector';
import { MdOutlineCreate, MdOutlineCreateNewFolder } from 'react-icons/md';
import { AuthContexts } from '../../../context/AuthProvider';
import { BsFiletypeCsv } from 'react-icons/bs';
import { GoOrganization } from 'react-icons/go';

type Props = {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  setShowCsvModal: () => void;
  setShowCreateProject: () => void;
};
const ProfileModal: FC<Props> = ({
  anchorEl,
  handleClose,
  setShowCsvModal,
  setShowCreateProject,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(useGetUserDetail);
  const { logoutUser } = AuthContexts();

  const onLogout = () => {
    if (SHOW_AUTH0_LOGIN) {
      logoutUser();
      return;
    }
    dispatch(clearUser());
  };

  return (
    <Popover
      id={'profile-modal'}
      open
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          borderRadius: 20,
          width: 180,
        },
      }}
    >
      <Grid container p={'20px'}>
        <Grid item xs={12}>
          <OptionButton display={'flex'} gap={'10px'} alignItems={'center'}>
            <FiSettings />
            Settings
          </OptionButton>
        </Grid>
        {userInfo?.isStaff && (
          <Grid item xs={12} mt={'10px'}>
            <OptionButton
              onClick={() => navigate('/register')}
              display={'flex'}
              gap={'10px'}
              alignItems={'center'}
            >
              <MdOutlineCreate />
              Register
            </OptionButton>
          </Grid>
        )}
        {userInfo?.isStaff && (
          <Grid item xs={12} mt={'10px'}>
            <OptionButton
              onClick={() => navigate('/organisation')}
              display={'flex'}
              gap={'10px'}
              alignItems={'center'}
            >
              <GoOrganization />
              Organisation
            </OptionButton>
          </Grid>
        )}
        <Grid item xs={12} mt={'10px'}>
          <OptionButton
            onClick={setShowCreateProject}
            display={'flex'}
            gap={'10px'}
            alignItems={'center'}
          >
            <MdOutlineCreateNewFolder />
            Create Project
          </OptionButton>
        </Grid>
        {userInfo?.isStaff && (
          <Grid item xs={12} mt={'10px'}>
            <OptionButton
              onClick={() => setShowCsvModal()}
              display={'flex'}
              gap={'10px'}
              alignItems={'center'}
            >
              <BsFiletypeCsv />
              View CSV
            </OptionButton>
          </Grid>
        )}
        <Grid item xs={12} mt={'10px'}>
          <OptionButton onClick={onLogout} display={'flex'} gap={'10px'} alignItems={'center'}>
            <FiLogOut />
            Signout
          </OptionButton>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default ProfileModal;
