import { CsvListType } from './type';

export const transformCsvList = (data: any): CsvListType[] => {
  if (!data) {
    return [];
  }

  return data.rows.map(
    (item: any, index: number) =>
      ({
        apiEndPoints: item['API End Points'],
        apiLink: item['API Link'],
        notes: item.Notes,
        order: item.Order,
        transaction: item.Transaction,
        id: index + 1,
      } as CsvListType),
  );
};
