import { FC } from 'react';
import { Avatar, Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { HeaderContainer, StyledAvatar } from './style';
import { useHeader } from './hook';
import { stringAvatar } from '../../util/SiteHelper';
import ProfileModal from './component/ProfileModal';
import Flags from '../flags';
import TransactionModal from './component/TransactionModal';
import { StyledOptionButton } from '../../common/component/Style';
import { MdAdd, MdKeyboardArrowDown } from 'react-icons/md';
import CurrentProjectModal from './component/CurrentProjectModal';
import { setSelectedTransactionModal } from '../../store/header';
import { useAuth0 } from '@auth0/auth0-react';
import { URL_WITH_NO_PADDING_HEADER, transactionMenuOption } from './util';
import { useLocation } from 'react-router-dom';
import Planner from '../planner/Planner';
import { Header2 } from '../../common/component/Text';
import PlayersList from '../players/PlayersList';
import CsvView from '../csv/CsvView';
import AddProject from '../project/component/AddProject';

type Props = {
  children: JSX.Element;
};
const Header: FC<Props> = ({ children }) => {
  const {
    transactionAnchorEl,
    anchorEl,
    handleCloseProfilePopup,
    handleOpenProfilePopup,
    handleCloseTransactionPopup,
    handleOpenTransactionPopup,
    updateProjectValue,
    currentProject,
    projectDropdownOption,
    flagTooltip,
    currentProjectAnchorEl,
    setCurrentProjectAnchorEl,
    isSideBarOpen,
    dispatch,
    openPlannerDrawer,
    setOpenPlannerDrawer,
    openPlayerDrawer,
    setOpenPlayerDrawer,
    fetchTooltipData,
    showCsvModal,
    setShowCsvModal,
    openCreateProject,
    setOpenCreateProject,
    refreshtProjectList,
  } = useHeader();
  const { pathname } = useLocation();
  const { user } = useAuth0();

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderContainer
          py={'0px'}
          px={'5px'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Grid>
            <Flags refreshFlagTooltip={fetchTooltipData} flagTooltip={flagTooltip} />
          </Grid>
          <Grid py={'5px'} alignItems={'center'} display={'flex'} gap={'5px'}>
            <StyledOptionButton
              onClick={() => {
                setOpenPlayerDrawer(true);
              }}
            >
              Players
            </StyledOptionButton>
            <StyledOptionButton
              onClick={() => {
                setOpenPlannerDrawer(true);
              }}
            >
              Offers
            </StyledOptionButton>
            <StyledOptionButton
              onClick={handleOpenTransactionPopup}
              startIcon={<MdAdd color={'#fff'} />}
            >
              Transaction
            </StyledOptionButton>
            <StyledOptionButton
              buttontype={'text'}
              style={{
                padding: '5px 10px 10px 5px',
              }}
              onClick={({ currentTarget }) => setCurrentProjectAnchorEl(currentTarget)}
              endIcon={<MdKeyboardArrowDown />}
            >
              <Box maxWidth={'120px'}>
                <Tooltip
                  title={
                    projectDropdownOption.find((item) => item.value === currentProject)?.label ||
                    'CURRENT PROJECT'
                  }
                >
                  <Typography textOverflow={'ellipsis'} overflow={'hidden'} noWrap>
                    {projectDropdownOption.find((item) => item.value === currentProject)?.label ||
                      'CURRENT PROJECT'}
                  </Typography>
                </Tooltip>
                <Header2 style={{ marginTop: 5, lineHeight: 0 }} textsize='12px'>
                  Draft Year:
                  {projectDropdownOption.find((item) => item.value === currentProject)?.unique}{' '}
                </Header2>
              </Box>
            </StyledOptionButton>
            <IconButton onClick={handleOpenProfilePopup}>
              {user?.picture ? (
                <Avatar src={user?.picture} />
              ) : (
                <StyledAvatar {...stringAvatar(user?.name || user?.nickname || '')} />
              )}
            </IconButton>
          </Grid>
        </HeaderContainer>
        {anchorEl && (
          <ProfileModal
            setShowCsvModal={() => setShowCsvModal(true)}
            anchorEl={anchorEl}
            handleClose={handleCloseProfilePopup}
            setShowCreateProject={() => setOpenCreateProject(true)}
          />
        )}
        {transactionAnchorEl && (
          <TransactionModal
            transactionOptionList={transactionMenuOption}
            anchorEl={transactionAnchorEl}
            handleClose={handleCloseTransactionPopup}
            handleTransactionSelect={(transactionId: string) => {
              dispatch(setSelectedTransactionModal(transactionId));
              handleCloseTransactionPopup();
            }}
          />
        )}
        {openCreateProject && (
          <AddProject
            refreshList={() => {
              setOpenCreateProject(false);
              refreshtProjectList();
            }}
            handleClose={() => setOpenCreateProject(false)}
          />
        )}
        {showCsvModal && <CsvView handleClose={() => setShowCsvModal(false)} />}
        {currentProjectAnchorEl && (
          <CurrentProjectModal
            anchorEl={currentProjectAnchorEl}
            handleClose={() => setCurrentProjectAnchorEl(null)}
            options={projectDropdownOption}
            onSelectOption={updateProjectValue}
          />
        )}
        <Planner open={openPlannerDrawer} onClose={() => setOpenPlannerDrawer(false)} />
        {openPlayerDrawer && (
          <PlayersList open={openPlayerDrawer} onClose={() => setOpenPlayerDrawer(false)} />
        )}
      </Grid>
      <Grid
        mt={URL_WITH_NO_PADDING_HEADER.includes(pathname) ? '0px' : '10px'}
        className={'content'}
        pr={'10px'}
        height={`calc(100vh - ${isSideBarOpen ? 100 : 90}px)`}
        overflow={'auto'}
        item
        xs={12}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default Header;