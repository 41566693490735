import { baseQuesryWithErrorToast } from '..';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  csvUploadReaderApiUrl,
  deleteLastRowApiUrl,
  getCsvPayload,
  getcsvListFiles,
} from '../../../constant/ApiConstant';

export const uploadCsvService = createApi({
  reducerPath: 'uploadCsvService',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQuesryWithErrorToast,
  endpoints: (builder) => ({
    getCsvUploadList: builder.query({
      query: () => getcsvListFiles(),
    }),
    getCsvPayload: builder.query({
      query: (fileName: string) => getCsvPayload(fileName),
    }),
    rederCsvFile: builder.mutation({
      query: ({
        fileName,
        reverseTransformData,
      }: {
        fileName: string;
        reverseTransformData: any;
      }) => ({
        url: csvUploadReaderApiUrl(fileName),
        method: 'POST',
        body: reverseTransformData,
      }),
    }),
    deleteCsvLastRow: builder.mutation({
      query: ({ fileName }: { fileName: string }) => ({
        url: deleteLastRowApiUrl(fileName),
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useDeleteCsvLastRowMutation,
  useGetCsvUploadListQuery,
  useLazyGetCsvPayloadQuery,
  useRederCsvFileMutation,
} = uploadCsvService;
