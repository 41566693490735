import { useEffect, useMemo, useState } from 'react';
import { useCurrentProject } from '../../store/header/selector';
import { useAppSelector } from '../../store/hooks';
import {
  useCreateNewPlayerCsvMutation,
  useCreateNewPlayerMutation,
  useGetPlayerPositionQuery,
  useLazyGetPlayersQuery,
} from '../../store/service';
import { getTierNumber, transformDraftList } from './util';
import { AddPlayerType, PlayerDraftBoardType } from './type';
import { useUpdatePlayerPositionMutation } from '../../store/service/draftboardservice';
import { transformPlayerPosition } from '../players/util';

export const useDraftBoard = () => {
  const [selectedDraft, setSelectedDraft] = useState('tiers');
  const currentProject = useAppSelector(useCurrentProject);
  const [hideSelectedPlayer, setHideSelectedPlayer] = useState(false);

  const [getPlayer, { data, isFetching }] = useLazyGetPlayersQuery();
  const [updatePlayerPosition] = useUpdatePlayerPositionMutation();
  const [refreshList, setRefreshList] = useState(Math.random().toString());

  useEffect(() => {
    fetchPlayerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  const fetchPlayerList = () => {
    getPlayer(currentProject).then(() => {
      setRefreshList(Math.random().toString());
    });
  };

  const updatePlayerPos = (playerInfo: PlayerDraftBoardType) => {
    updatePlayerPosition({
      data: {
        Full_Name: playerInfo.fullName,
        Rank: playerInfo.rank,
        Tier: getTierNumber[playerInfo.tier],
      },
      projectId: currentProject,
    }).then((res: any) => {
      if (!res.error) {
        fetchPlayerList();
      }
    });
  };

  const playerList = useMemo(() => {
    return transformDraftList(data).filter((item) => (hideSelectedPlayer ? !item.selected : true));
  }, [data, hideSelectedPlayer]);

  return {
    selectedDraft,
    setSelectedDraft,
    draftList: playerList,
    refreshPlayerList: () => fetchPlayerList(),
    isLoading: isFetching,
    updatePlayerPos,
    refreshList,
    hideSelectedPlayer,
    setHideSelectedPlayer,
  };
};

export const useAddPlayer = (handleClose: () => void, refreshList: () => void) => {
  const [createAddNewPlayerCsv, { isLoading: playerAddLoadingCsv }] =
    useCreateNewPlayerCsvMutation();

  const { data: playerPosition } = useGetPlayerPositionQuery('');

  const playerPositionDropdownOption = useMemo(
    () => transformPlayerPosition(playerPosition),
    [playerPosition],
  );

  const currentProject = useAppSelector(useCurrentProject);

  const [createAddNewPlayer, { isLoading: playerAddLoading }] = useCreateNewPlayerMutation();

  const submitData = (values: AddPlayerType) => {
    if (values.file !== null) {
      createAddNewPlayerCsv({
        data: values,
        projectId: currentProject,
      }).then((res: any) => {
        if (!res.error) {
          refreshList();
          handleClose();
        }
      });
      return;
    }
    createAddNewPlayer({
      data: values,
      projectId: currentProject,
    }).then((res: any) => {
      if (!res.error) {
        refreshList();
        handleClose();
      }
    });
  };

  return {
    isLoading: playerAddLoadingCsv || playerAddLoading,
    submitData,
    playerPositionDropdownOption,
  };
};
