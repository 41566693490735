import { baseQuesryWithErrorToast } from '..';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  createPlayerAgencyApiUrl,
  createPlayerManagerAgencyApiUrl,
  deletePlayerAgencyApiUrl,
  deletePlayerManagerAgencyApiUrl,
  getPlayerAllocationApiUrl,
  getPlayerAgencyApiUrl,
  getPlayerManagerAgencyApiUrl,
  updatePlayerAgencyApiUrl,
  updatePlayerManagerAgencyApiUrl,
} from '../../../constant/ApiConstant';
import { PlayerAgencyAddUpdateType, PlayerManagerAgencyAddUpdateType } from './type';
import { reverseTransformPlayerAgency, reverseTransformPlayerManagerAgency } from './util';

export const playerManagerServiceApi = createApi({
  reducerPath: 'playerManagerapi',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQuesryWithErrorToast,
  endpoints: (builder) => ({
    getPlayerAgency: builder.query({
      query: (orgId: string) => getPlayerAgencyApiUrl(orgId),
    }),
    createPlayerAgency: builder.mutation({
      query: ({ data, orgId }: { data: PlayerAgencyAddUpdateType; orgId: string }) => ({
        url: createPlayerAgencyApiUrl(orgId),
        method: 'POST',
        body: reverseTransformPlayerAgency(data),
      }),
    }),
    updatePlayerAgency: builder.mutation({
      query: ({
        data,
        orgId,
        companyId,
      }: {
        data: PlayerAgencyAddUpdateType;
        orgId: string;
        companyId: string;
      }) => ({
        url: updatePlayerAgencyApiUrl(orgId, companyId),
        method: 'PUT',
        body: reverseTransformPlayerAgency(data),
      }),
    }),
    deleteAgency: builder.mutation({
      query: ({ companyId, orgId }: { companyId: string; orgId: string }) => ({
        url: deletePlayerAgencyApiUrl(orgId, companyId),
        method: 'DELETE',
      }),
    }),
    getPlayerManagerAgency: builder.query({
      query: (orgId: string) => getPlayerManagerAgencyApiUrl(orgId),
    }),
    createPlayerManagerAgency: builder.mutation({
      query: ({ data, orgId }: { data: PlayerManagerAgencyAddUpdateType; orgId: string }) => ({
        url: createPlayerManagerAgencyApiUrl(orgId),
        method: 'POST',
        body: reverseTransformPlayerManagerAgency(data),
      }),
    }),
    updatePlayerManagerAgency: builder.mutation({
      query: ({
        data,
        orgId,
        agentId,
      }: {
        data: PlayerManagerAgencyAddUpdateType;
        orgId: string;
        agentId: string;
      }) => ({
        url: updatePlayerManagerAgencyApiUrl(orgId, agentId),
        method: 'PUT',
        body: reverseTransformPlayerManagerAgency(data),
      }),
    }),
    deletePlayerManagerAgency: builder.mutation({
      query: ({ agencyId, orgId }: { agencyId: string; orgId: string }) => ({
        url: deletePlayerManagerAgencyApiUrl(orgId, agencyId),
        method: 'DELETE',
      }),
    }),
    getPlayerAllocationAgency: builder.query({
      query: (orgId: string) => getPlayerAllocationApiUrl(orgId),
    }),
  }),
});

export const {
  useCreatePlayerAgencyMutation,
  useGetPlayerAgencyQuery,
  useDeleteAgencyMutation,
  useUpdatePlayerAgencyMutation,
  useCreatePlayerManagerAgencyMutation,
  useGetPlayerManagerAgencyQuery,
  useDeletePlayerManagerAgencyMutation,
  useUpdatePlayerManagerAgencyMutation,
  useGetPlayerAllocationAgencyQuery,
} = playerManagerServiceApi;
