import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { FieldLabel } from '../../component/Text';
import { Box, FormHelperText, Tooltip } from '@mui/material';
import { PrettoSlider } from './Style';
import { SliderProps } from '@mui/material';
import { TEXT_COLOR_THEME } from '../../../theme/color';
import { InfoOutlined } from '@mui/icons-material';

type CustomFileInputProps = {
  name: string;
  label: string;
  control: Control<any, any>;
  sliderMeasurment?: string;
  isMandatory?: boolean;
  tooltipInfo?: string;
} & SliderProps;

const CustomSlider: FC<CustomFileInputProps> = ({
  control,
  label,
  name,
  sliderMeasurment,
  isMandatory,
  tooltipInfo,
  ...rest
}) => {
  return (
    <Box>
      <FieldLabel mb={'5px'} display={'flex'} alignItems={'center'}>
        {label}
        {isMandatory && <sup style={{ color: TEXT_COLOR_THEME.error }}>*</sup>}
        {tooltipInfo && (
          <Tooltip title={tooltipInfo}>
            <InfoOutlined style={{ marginLeft: 10 }} />
          </Tooltip>
        )}
      </FieldLabel>
      <Controller
        control={control}
        name={`${name}`}
        render={({ field, fieldState }) => (
          <Box px={'10px'}>
            <PrettoSlider
              valueLabelDisplay='auto'
              aria-label='pretto slider'
              defaultValue={field.value}
              {...rest}
              onChange={(_: Event, newValue: number | number[]) => {
                field.onChange(newValue);
              }}
            />
            <Box display={'flex'} justifyContent={'space-between'}>
              <FieldLabel style={{ fontSize: 12 }}>
                Min: {rest.min} {sliderMeasurment && sliderMeasurment}
              </FieldLabel>
              <FieldLabel style={{ fontSize: 12 }}>
                Max: {rest.max} {sliderMeasurment && sliderMeasurment}
              </FieldLabel>
            </Box>
            {fieldState.error && (
              <FormHelperText style={{ color: TEXT_COLOR_THEME.error }}>
                {(fieldState.error?.message as string) || ''}
              </FormHelperText>
            )}
          </Box>
        )}
      />
    </Box>
  );
};

export default CustomSlider;
