import { useState } from 'react';
import { useGetUserDetail } from '../../store/auth/selector';
import { IUserData } from '../../store/auth/type';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export const useLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { loading, error } = useAppSelector(useGetUserDetail) as IUserData;

  const dispatch = useAppDispatch();

  return {
    showPassword,
    setShowPassword,
    loading,
    error,
    dispatch,
  };
};
