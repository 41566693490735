import { Grid, Popover } from '@mui/material';
import { useGetUserOrganisation } from '../../../store/auth/selector';
import { useAppSelector } from '../../../store/hooks';
import { OptionButton } from '../style';

type Props = {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  handleOrgSelect: (orgId: string) => void;
};

const CurrentUserOrg = ({ anchorEl, handleClose, handleOrgSelect }: Props) => {
  const organisation = useAppSelector(useGetUserOrganisation);

  return (
    <Popover
      id={'Current Organisation info'}
      open
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          borderRadius: 20,
          width: 180,
        },
      }}
    >
      <Grid container p={'20px'}>
        {organisation?.map((org: any) => (
          <Grid item key={org.organisationId} xs={12}>
            <OptionButton
              onClick={() => handleOrgSelect(org.organisationId.toString())}
              display={'flex'}
              gap={'10px'}
              alignItems={'center'}
            >
              {org.organisationTitle}
            </OptionButton>
          </Grid>
        ))}
      </Grid>
    </Popover>
  );
};

export default CurrentUserOrg;
