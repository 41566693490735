import { RootState } from '../store';

export const useCurrentProject = (state: RootState) => state.siteStatus.currentProject;
export const useCurrentOrganisation = (state: RootState) => state.siteStatus.currentOrganisation;
export const useSideBarStatus = (state: RootState) => state.siteStatus.isSideBarOpen;
export const useSelectedFlag = (state: RootState) => state.siteStatus.selectedFlag;
export const useSelectedTransactionModal = (state: RootState) =>
  state.siteStatus.selectedTransactionModal;
export const useFlagList = (state: RootState) => state.siteStatus.flagList;
export const useHoveredTeamId = (state: RootState) => state.siteStatus.hoveredTeamId;
export const useRefreshFlag = (state: RootState) => state.siteStatus.refreshFlagData;
export const useUploadCsvTransactionType = (state: RootState) =>
  state.siteStatus.uploadCsvTransactionTypeModal;
