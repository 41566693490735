import React from 'react';
import { useElegibility } from '../hook';
import { Grid } from '@mui/material';
import DataGridWrapper from '../../../common/component/DataGridWrapper';
import { ElegibilityListColumn } from './ListColumn';
import { PrimaryButton } from '../../../common/component/Button';
import AddUpdateElegibility from './AddUpdateElegibility';
import DeleteModal from '../../../common/component/DeleteModal';

type ElegibilityProps = {
  playerId: string;
};
const Elegibility = ({ playerId }: ElegibilityProps) => {
  const {
    data,
    loading,
    openAddModal,
    selectedElegibilityForEdit,
    setOpenAddModal,
    setSelectedElegibilityForEdit,
    refreshList,
    deleteLoading,
    handelElegibilityDelete,
    selectedElegibilityForDelete,
    setSelectedElegibilityForDelete,
  } = useElegibility(playerId);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent={'flex-end'}>
          <Grid item xs={4}>
            <PrimaryButton onClick={() => setOpenAddModal(true)} title='Create Eligibility' />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={'10px'}>
        <DataGridWrapper
          columns={ElegibilityListColumn(
            setSelectedElegibilityForEdit,
            setSelectedElegibilityForDelete,
          )}
          rows={data}
          loading={loading}
        />
      </Grid>
      {(openAddModal || selectedElegibilityForEdit) && (
        <AddUpdateElegibility
          handleClose={() => {
            setSelectedElegibilityForEdit(undefined);
            setOpenAddModal(false);
          }}
          playerId={playerId}
          refreshList={refreshList}
          selectedElegibility={selectedElegibilityForEdit}
        />
      )}
      {selectedElegibilityForDelete && (
        <DeleteModal
          isLoading={deleteLoading}
          description='Are you sure you want to delete?'
          modalTitle='Delete Eligibility'
          handleClose={() => setSelectedElegibilityForDelete(undefined)}
          handleSubmit={handelElegibilityDelete}
        />
      )}
    </Grid>
  );
};

export default Elegibility;
