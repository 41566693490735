import CustomModal from '../../../common/component/CustomModal';
import { Box, Divider, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FieldLabel } from '../../../common/component/Text';
import { useAddTimelineEntry } from '../hook';
import { PrimaryButton } from '../../../common/component/Button';
import TimelineForm from './TimelineForm';
import { TimelineOfEntryType } from '../type';

type AddTimelineEntryModalProps = {
  handleClose: () => void;
  refreshList: () => void;
  playerId: string;
  selectedTimelineForEdit?: TimelineOfEntryType;
};
const AddTimelineEntryModal = ({
  handleClose,
  refreshList,
  playerId,
  selectedTimelineForEdit,
}: AddTimelineEntryModalProps) => {
  const {
    data,
    errors,
    register,
    selectedFormFieldInfo,
    loadForm,
    selectedForm,
    setSelectedForm,
    handleSubmit,
    onSubmit,
    isLoading,
    control,
  } = useAddTimelineEntry(playerId, refreshList, selectedTimelineForEdit);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedForm(event.target.value as string);
  };
  return (
    <CustomModal
      handleSubmit={handleSubmit(onSubmit)}
      modalSize={'md'}
      handleClose={handleClose}
      saveLoading={isLoading}
      modalTitle={selectedTimelineForEdit ? 'Update Entry' : 'Add Entry'}
      disableButton={!selectedFormFieldInfo}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box display={'flex'} alignItems={'end'} gap={'10px'}>
            <Box flex={3}>
              <FieldLabel>Select Form</FieldLabel>
              <Select
                disabled={!!selectedTimelineForEdit}
                size='small'
                fullWidth
                value={selectedForm}
                onChange={handleChange}
              >
                {data.map((item) => (
                  <MenuItem value={item.name} key={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box flex={1}>
              <PrimaryButton
                disabled={selectedForm === '' || !!selectedTimelineForEdit}
                onClick={loadForm}
                title='Load form'
              />
            </Box>
          </Box>
        </Grid>
        <Grid item mt={'30px'} xs={12}>
          <Divider />
        </Grid>
        {selectedFormFieldInfo && (
          <Grid item xs={12}>
            <TimelineForm
              control={control}
              errors={errors}
              register={register}
              fieldInfo={selectedFormFieldInfo}
            />
          </Grid>
        )}
      </Grid>
    </CustomModal>
  );
};

export default AddTimelineEntryModal;
