import { useState, useEffect, useMemo } from 'react';
import { useGetUserDetail } from '../../store/auth/selector';
import { useCurrentProject } from '../../store/header/selector';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetDraftOptionQuery,
  useGetShowTeamQuery,
  useLazyFetchRosterSpotsQuery,
  useLazyGetProjectListQuery,
  useUpdateRosterSpotMutation,
} from '../../store/service';
import { transformAcademyTeamNameDropdownOption } from '../transaction/util';
import { ProjectListType, ProjectType, RosterListType } from './type';
import {
  createProjectInitialValue,
  projectValidationSchema,
  transformProjectList,
  transformRosterData,
  transformShowDraftOption,
} from './util';
import { setCurrentProject, setUploadCsvSelectedTransactionTypeModal } from '../../store/header';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { reverseTransformUpdateRoster } from '../../store/service/util';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const useProject = () => {
  const currentProject = useAppSelector(useCurrentProject);
  const [openCreateProject, setOpenCreateProject] = useState(false);
  const [updateProjectModal, setUpdateProjectModal] = useState<ProjectListType | null>(null);
  const [selectedDeleteProject, setSelectedDeleteProject] = useState<ProjectListType | null>(null);

  const [getProjectData, { isLoading, data }] = useLazyGetProjectListQuery();
  const { userInfo } = useAppSelector(useGetUserDetail);
  const [deleteProject, { isLoading: deleteProjectLoading }] = useDeleteProjectMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openProjectSettingId, setOpenProjectSettingId] = useState<string | null>(null);
  const { data: showDraftOptionQuery } = useGetDraftOptionQuery('');

  useEffect(() => {
    currentProject && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  const fetchData = () => {
    getProjectData(userInfo?.userId || 0);
    setOpenCreateProject(false);
    setUpdateProjectModal(null);
  };

  const handleDeleteProject = () => {
    selectedDeleteProject &&
      deleteProject(selectedDeleteProject.projectId.toString()).then((res: any) => {
        if (!res.error) {
          setSelectedDeleteProject(null);
          fetchData();
        }
      });
  };

  const openProjectLink = (projectId: string) => {
    dispatch(setCurrentProject(projectId));
    navigate('/dashboard');
  };

  return {
    isLoading,
    list: transformProjectList(data),
    setOpenCreateProject,
    openCreateProject,
    updateProjectModal,
    setUpdateProjectModal,
    refreshList: fetchData,
    setSelectedDeleteProject,
    selectedDeleteProject,
    handleDeleteProject,
    deleteProjectLoading,
    openProjectLink,
    showDraftOption: transformShowDraftOption(showDraftOptionQuery),
    setOpenProjectSettingId,
    openProjectSettingId,
  };
};

export const useCreateProject = (refreshList: () => void, selectedProject?: ProjectListType) => {
  const [createProject, { isLoading, data }] = useCreateProjectMutation();
  const { data: showDraftOptionQuery } = useGetDraftOptionQuery('');

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: createProjectInitialValue(selectedProject),
    resolver: yupResolver(projectValidationSchema),
  });

  useEffect(() => {
    data && refreshList();
  }, [data, refreshList]);

  const showDraftOption = useMemo(
    () => transformShowDraftOption(showDraftOptionQuery),
    [showDraftOptionQuery],
  );
  const submitData = (values: ProjectType) => {
    if (selectedProject) {
      return;
    }
    const draftYear = showDraftOption.find((item) => item.value === values.draftType)?.unique || '';
    createProject({
      data: {
        ...values,
        draftYear,
      },
    });
  };
  return {
    isLoading,
    submitData,
    showDraftOption,
    handleSubmit,
    register,
    control,
    errors,
  };
};

export const useProjectForm = () => {
  const { data: showTeamData } = useGetShowTeamQuery('');
  return {
    teamDataDropdown: transformAcademyTeamNameDropdownOption(showTeamData?.data),
  };
};

export const useProjectSettingModal = (
  projectId: string,
  handleClose: () => void,
  refreshTooltips?: () => void,
) => {
  const [fetchRoster, { data, isLoading }] = useLazyFetchRosterSpotsQuery();
  const [rosterList, setRosterList] = useState<RosterListType[]>([]);
  const { userInfo } = useAppSelector(useGetUserDetail);
  const dispatch = useAppDispatch();

  const [updateRoster, { isLoading: updateRosterLoading }] = useUpdateRosterSpotMutation();
  useEffect(() => {
    fetchRoster({
      projectId: projectId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setRosterAmount = (rosterId: number, rosterYear: string, amount: number) => {
    setRosterList((prev) =>
      prev.map((item) =>
        item.id === rosterId
          ? {
              ...item,
              currentYearAmount: item.currentYear === rosterYear ? amount : item.currentYearAmount,
              nextYearAmount: item.nextYear === rosterYear ? amount : item.nextYearAmount,
            }
          : { ...item },
      ),
    );
  };

  const handleSubmit = () => {
    updateRoster({
      data: rosterList,
      projectId: projectId,
    }).then((res: any) => {
      if (!res.error) {
        if (refreshTooltips) {
          userInfo?.isStaff &&
            dispatch(
              setUploadCsvSelectedTransactionTypeModal({
                transactionType: 'Update Roster Spots',
                payload: reverseTransformUpdateRoster(rosterList),
              }),
            );
          refreshTooltips();
        }
        handleClose();
        toast.success('Roster Updated Successfully');
      }
    });
  };
  useEffect(() => {
    data && setRosterList(transformRosterData(data));
  }, [data]);

  return {
    rosterList,
    isLoading,
    setRosterAmount,
    handleSubmit,
    updateRosterLoading,
  };
};
