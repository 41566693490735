import { Box, Dialog, Grid } from '@mui/material';
import { Header3 } from '../../../common/component/Text';
import { PrimaryButton } from '../../../common/component/Button';
import RankingCreateUpdateForm from './RankingCreateUpdateForm';
import DataGridWrapper from '../../../common/component/DataGridWrapper';
import { RankingListType } from '../type';
import { RankingListColumn } from './ListColumn';
import { useRankingListModal } from '../hook';
import DeleteModal from '../../../common/component/DeleteModal';

type RankingListModalProps = {
  handleClose: () => void;
  refreshList: () => void;
  rankingList: RankingListType[];
};

const RankingListModal = ({ handleClose, refreshList, rankingList }: RankingListModalProps) => {
  const {
    openUpdateModal,
    selectedRankingItem,
    selectedRankingItemForDelete,
    setSelectRankingItem,
    setSelectRankingItemForDelete,
    setUpdateModal,
    deleteRankingList,
    isLoading,
  } = useRankingListModal({
    refreshList,
  });
  return (
    <Dialog fullWidth maxWidth={'md'} open onClose={handleClose}>
      <Grid container p={'30px'}>
        <Grid item xs={12}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box width={'100%'}>
              <Header3>Ranking Lists</Header3>
            </Box>
            <Box minWidth={'150px'}>
              <PrimaryButton
                onClick={() => setUpdateModal(true)}
                fullWidth
                sx={{ width: 100 }}
                title='Add'
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} mt={'10px'}>
          <DataGridWrapper
            columns={RankingListColumn(setSelectRankingItem, setSelectRankingItemForDelete)}
            rows={rankingList}
          />
        </Grid>
        {(openUpdateModal || selectedRankingItem) && (
          <RankingCreateUpdateForm
            refreshList={refreshList}
            handleClose={() => {
              setUpdateModal(false);
              setSelectRankingItem(null);
            }}
            selectedRankingItem={selectedRankingItem}
          />
        )}
        {selectedRankingItemForDelete && (
          <DeleteModal
            description={`Are you sure you want to delete?`}
            modalTitle='Delete Ranking'
            handleClose={() => setSelectRankingItemForDelete(null)}
            handleSubmit={deleteRankingList}
            isLoading={isLoading}
          />
        )}
      </Grid>
    </Dialog>
  );
};

export default RankingListModal;
