import Tab from '@mui/material/Tab/Tab';
import Tabs, { TabsProps } from '@mui/material/Tabs/Tabs';
import React, { FC } from 'react';
import { StyledTabContainer } from './Style';
import { TabOptionType } from './type';

type Props = {
  tabOption: TabOptionType[];
  value: string;
  setTabSelected: (value: string) => void;
  orientation?: 'horizontal' | 'vertical';
} & TabsProps;
const CustomTab: FC<Props> = ({
  tabOption,
  value,
  setTabSelected,
  orientation = 'horizontal',
  ...rest
}) => {
  return (
    <StyledTabContainer>
      <Tabs orientation={orientation} value={value} {...rest}>
        {tabOption
          .filter((item) => !item.toHide)
          .map((item) => (
            <Tab
              key={item.value}
              label={item.label}
              value={item.value}
              onClick={() => setTabSelected(item.value)}
            />
          ))}
      </Tabs>
    </StyledTabContainer>
  );
};

export default CustomTab;
