import { useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Box, Grid } from '@mui/material';
import { PlayerDatabaseType } from '../../playerdatabase/type';
import { PlayerRankingType, TiersState, UpdateRankingType } from '../type';
import { StyledCard } from '../../../common/component/Card';
import DragPlayerList from './DragPlayerList';
import DraggableRankDataGrid from './DraggableRankDataGrid';
import { Header3 } from '../../../common/component/Text';

type Props = {
  rank: PlayerRankingType[];
  playerList: PlayerDatabaseType[];
  updatePlayerPos: (playerInfo: UpdateRankingType) => void;
  isLoading: boolean;
};

const DragAndDropRanking = ({ updatePlayerPos, rank, playerList, isLoading }: Props) => {
  const [tiers, setTiers] = useState<TiersState>({
    tier1: rank.filter((player) => player.tier === 1),
    tier2: rank.filter((player) => player.tier === 2),
    tier3: rank.filter((player) => player.tier === 3),
    tier4: rank.filter((player) => player.tier === 4),
    tier5: rank.filter((player) => player.tier === 5),
    playerList: playerList,
  });

  const handleRankingUpdate = (ranking: number, tier: number, playerId: number | string) => {
    updatePlayerPos({ ranking, tier, playerId: playerId.toString() });
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination || destination.droppableId === 'playerList') return;

    const sourceTier = source.droppableId as keyof TiersState;
    const sourceList = Array.from((tiers as any)[sourceTier]) as (
      | PlayerRankingType
      | PlayerDatabaseType
    )[];

    const destTier = destination.droppableId as keyof TiersState;
    const destList = Array.from((tiers as any)[destTier]) as (
      | PlayerRankingType
      | PlayerDatabaseType
    )[];

    const [movedPlayer] = sourceList.splice(source.index, 1);
    const newRank = destination.index + 1;

    const updatedPlayer = {
      ...movedPlayer,
      rank: newRank,
      tier: parseInt(destTier.replace('tier', '')),
    };

    destList.splice(destination.index, 0, updatedPlayer);

    setTiers((prev) => ({
      ...prev,
      [sourceTier]: sourceList,
      [destTier]: destList,
    }));

    const overallRank = destList.length;
    const playerId = typeof movedPlayer.id === 'number' ? movedPlayer.id : parseInt(movedPlayer.id);
    handleRankingUpdate(overallRank, parseInt(destTier.replace('tier', '')), playerId);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container columnSpacing={'20px'}>
        <Grid item xs={9}>
          <Grid container style={{ pointerEvents: isLoading ? 'none' : 'all' }}>
            <Grid item xs={12}>
              <Grid overflow={'auto'} gap={'30px'} display={'flex'}>
                {Object.keys(tiers)
                  .filter((key) => key !== 'playerList')
                  .map((tierKey, idx) => (
                    <Droppable droppableId={tierKey} key={tierKey}>
                      {(provided) => (
                        <StyledCard>
                          <Box ref={provided.innerRef} {...provided.droppableProps} width={270}>
                            <Grid width={'100%'}>
                              <Header3 textsize={'16px'} textAlign={'center'}>
                                {`Tier ${idx + 1}`}
                              </Header3>
                            </Grid>
                            <Grid item xs={12}>
                              <DraggableRankDataGrid
                                providerPlaceholder={provided.placeholder}
                                tiers={tiers[tierKey as keyof typeof tiers] as PlayerRankingType[]}
                              />
                            </Grid>
                          </Box>
                        </StyledCard>
                      )}
                    </Droppable>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <DragPlayerList playerList={tiers.playerList} />
        </Grid>
      </Grid>
    </DragDropContext>
  );
};

export default DragAndDropRanking;
