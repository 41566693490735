import { FC } from 'react';
import { useCreateProject } from '../hook';
import ProjectForm from './ProjectForm';

type AddProjectProps = {
  handleClose: () => void;
  refreshList: () => void;
};
const AddProject: FC<AddProjectProps> = ({ handleClose, refreshList }) => {
  const { isLoading, submitData, showDraftOption, control, errors, handleSubmit, register } =
    useCreateProject(refreshList);
  return (
    <ProjectForm
      showDraftOption={showDraftOption}
      isLoading={isLoading}
      modalTile='Create Project'
      handleClose={handleClose}
      handleSubmit={handleSubmit(submitData)}
      control={control}
      register={register}
      errors={errors}
    />
  );
};

export default AddProject;
