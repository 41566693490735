import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';
import { AiOutlineDelete, AiFillEdit, AiOutlineLink, AiOutlineSetting } from 'react-icons/ai';
import { BiDuplicate } from 'react-icons/bi';
import { CiMenuKebab } from 'react-icons/ci';
import { FaRegEye } from 'react-icons/fa';
import { TEXT_COLOR_THEME } from '../../theme/color';

type CustomDataGridActionProps = {
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  onCreateDuplicate?: () => void;
  onLinkClick?: () => void;
  onSettingClick?: () => void;
  onViewClick?: () => void;
};
const CustomDataGridAction: FC<CustomDataGridActionProps> = ({
  onCreateDuplicate,
  onDeleteClick,
  onEditClick,
  onLinkClick,
  onSettingClick,
  onViewClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (fnc: () => void) => {
    fnc();
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <CiMenuKebab fontSize={'20px'} />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {onViewClick && (
          <MenuItem onClick={() => handleMenuClick(onViewClick)}>
            <ListItemIcon color={'default'}>
              <FaRegEye fontSize={'20px'} />
            </ListItemIcon>
            <ListItemText>View</ListItemText>
          </MenuItem>
        )}
        {onLinkClick && (
          <MenuItem onClick={() => handleMenuClick(onLinkClick)}>
            <ListItemIcon color={'default'}>
              <AiOutlineLink fontSize={'20px'} />
            </ListItemIcon>
            <ListItemText>Link</ListItemText>
          </MenuItem>
        )}
        {onEditClick && (
          <MenuItem onClick={() => handleMenuClick(onEditClick)}>
            <ListItemIcon>
              <AiFillEdit fontSize={'20px'} />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}
        {onCreateDuplicate && (
          <MenuItem onClick={() => handleMenuClick(onCreateDuplicate)}>
            <ListItemIcon>
              <BiDuplicate fontSize={'20px'} color={TEXT_COLOR_THEME.positive} />
            </ListItemIcon>
            <ListItemText>Create</ListItemText>
          </MenuItem>
        )}
        {onDeleteClick && (
          <MenuItem onClick={() => handleMenuClick(onDeleteClick)}>
            <ListItemIcon color='error'>
              <AiOutlineDelete fontSize={'20px'} color={TEXT_COLOR_THEME.error} />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        )}
        {onSettingClick && (
          <MenuItem onClick={() => handleMenuClick(onSettingClick)}>
            <ListItemIcon>
              <AiOutlineSetting fontSize={'20px'} />
            </ListItemIcon>
            <ListItemText>Setting</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
export default CustomDataGridAction;
