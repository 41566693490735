import { baseQuesryWithErrorToast } from '..';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  createRankingListApiUrl,
  deleteRankingListApiUrl,
  fetchRankingListApiUrl,
  fetchRankingListDataApiUrl,
  reorderPlayerPosApiUrl,
  updateRankingListApiUrl,
  updateRankingSingleApiRoute,
  updateRankingsListApiUrl,
} from '../../../constant/ApiConstant';
import { RankingUpdateListType, UpdateRankingType } from '../../../container/playerranking/type';
import { reverseTransformPlayerRankingData, reverseTransformRankingData } from './util';

export const draftBoardService = createApi({
  reducerPath: 'draftboardservice',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQuesryWithErrorToast,
  endpoints: (builder) => ({
    updatePlayerPosition: builder.mutation({
      query: ({ data, projectId }: { data: any; projectId: string }) => ({
        url: reorderPlayerPosApiUrl(projectId),
        method: 'PUT',
        body: data,
      }),
    }),
    getRankingList: builder.query({
      query: () => fetchRankingListApiUrl(),
    }),
    updateRankingList: builder.mutation({
      query: ({ data, rankingId }: { data: any; rankingId: string }) => ({
        url: updateRankingListApiUrl(rankingId),
        method: 'PUT',
        body: reverseTransformRankingData(data),
      }),
    }),
    deleteRanking: builder.mutation({
      query: ({ rankingId }: { rankingId: string }) => ({
        url: deleteRankingListApiUrl(rankingId),
        method: 'DELETE',
      }),
    }),
    createNewRankingPlayer: builder.mutation({
      query: ({ data }: { data: RankingUpdateListType }) => ({
        url: createRankingListApiUrl(),
        method: 'POST',
        body: reverseTransformRankingData(data),
      }),
    }),
    getRankingListData: builder.query({
      query: (rankingListId: string) => fetchRankingListDataApiUrl(rankingListId),
    }),
    updateRankingSingle: builder.mutation({
      query: ({ data, rankingId }: { data: UpdateRankingType; rankingId: string }) => ({
        url: updateRankingSingleApiRoute(rankingId),
        method: 'PATCH',
        body: reverseTransformPlayerRankingData(data),
      }),
    }),
    updatePlayerRanking: builder.mutation({
      query: ({ data, rankingId }: { data: UpdateRankingType; rankingId: string }) => ({
        url: updateRankingsListApiUrl(rankingId),
        method: 'PUT',
        body: [reverseTransformPlayerRankingData(data)],
      }),
    }),
  }),
});

export const {
  useUpdatePlayerPositionMutation,
  useCreateNewRankingPlayerMutation,
  useDeleteRankingMutation,
  useLazyGetRankingListQuery,
  useUpdateRankingListMutation,
  useLazyGetRankingListDataQuery,
  useUpdatePlayerRankingMutation,
  useUpdateRankingSingleMutation,
} = draftBoardService;
