import { Box, Grid, Tooltip, Typography } from '@mui/material';
import React, { FC } from 'react';
import { StyledLogo } from '../../../dashboard/style';
import { TeamFlagOptions } from '../../../../constant/OptionConstant';
import { CompactTag } from '../../../../common/component/Text';
import { TEXT_COLOR_INFO_TYPE } from '../../../../theme/color';
import { addYears, format } from 'date-fns';
import { TransactionImpactPriorityPick } from '../../type';

type ImpactDataProps = {
  impactData: TransactionImpactPriorityPick;
};
const ImpactData: FC<ImpactDataProps> = ({ impactData }) => {
  return (
    <Grid container mt={'20px'}>
      <Grid item xs={12}>
        <Box mb={'10px'} display={'flex'} justifyContent={'center'}>
          <StyledLogo
            src={TeamFlagOptions.find((item) => item.id === Number(impactData.teamId))!.flagIcon}
            style={{ height: 30, width: 30 }}
            alt={'flag'}
          />
        </Box>
        <Typography textAlign={'center'}>{impactData.impactDescription}</Typography>
        <Typography mt={'10px'} textAlign={'center'}>
          {impactData.teamDraftPointsImpact} gain in draft points
        </Typography>
      </Grid>
      <Grid item xs={12} mt={'20px'}>
        <Typography fontWeight={600} textAlign={'center'}>
          The Updated draft hand:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          mt={'5px'}
          container
          flexWrap={'nowrap'}
          spacing={'20px'}
          alignItems={'center'}
          justifyContent={'center'}
          width={'100%'}
        >
          <Grid item>
            {TeamFlagOptions.find((item) => item.id === Number(impactData.teamId))!.shortName}
          </Grid>
          <Grid item>
            <Box
              alignItems={'center'}
              justifyContent={'space-between'}
              display={'flex'}
              gap={'10px'}
            >
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Typography>{format(new Date(), 'yyyy')}</Typography>
                <Box display={'flex'} gap={'5px'} flexWrap={'wrap'}>
                  {impactData?.currentYearPicksDict.map((item) => (
                    <Tooltip key={item.pick} title={item.pickDisplay}>
                      <CompactTag
                        gap={'5px'}
                        display={'flex'}
                        alignItems={'center'}
                        textsize={'12px'}
                        textAlign={'center'}
                        bgcolor={'transparent'}
                        fontcolor={
                          item.pickStatus === 'Used' ? TEXT_COLOR_INFO_TYPE.error : '#189a18'
                        }
                        minWidth={15}
                      >
                        {item.pick}
                      </CompactTag>
                    </Tooltip>
                  ))}
                </Box>
              </Box>
              <Typography>{impactData?.teamDraftPointsStart}</Typography>
            </Box>
            <Box
              mt={'10px'}
              alignItems={'center'}
              justifyContent={'space-between'}
              display={'flex'}
              gap={'10px'}
            >
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Typography>{format(new Date(addYears(new Date(), 1)), 'yyyy')}</Typography>
                <Box display={'flex'} gap={'5px'} flexWrap={'wrap'}>
                  {impactData?.nextYearPickDict.map((item) => (
                    <Tooltip title={item.pickDisplay}>
                      <CompactTag
                        gap={'5px'}
                        display={'flex'}
                        alignItems={'center'}
                        textsize={'12px'}
                        textAlign={'center'}
                        bgcolor={'transparent'}
                        fontcolor={'#a9a9a9'}
                      >
                        {item.pick}
                      </CompactTag>
                    </Tooltip>
                  ))}
                </Box>
              </Box>
              <Typography>{impactData?.teamDraftPointEnd}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ImpactData;
