import React, { FC } from 'react';
import { StyledCard } from '../../../common/component/Card';
import { PlanningTradeOfferType } from '../type';
import { Box, Grid, Tooltip } from '@mui/material';
import { CompactTag, Header2 } from '../../../common/component/Text';
import { TEXT_COLOR_INFO_TYPE } from '../../../theme/color';
import { CheckCircleOutline, CloseOutlined } from '@mui/icons-material';
import { StyledLogo } from '../../dashboard/style';
import { TeamFlagOptions } from '../../../constant/OptionConstant';
import { MdInfoOutline } from 'react-icons/md';
import { PrimaryButton, PrimaryTextButton } from '../../../common/component/Button';
import { VscArrowSwap } from 'react-icons/vsc';
import { getInitials } from '../../../util/SiteHelper';

type PlanningTradeCardProps = {
  planningTrade: PlanningTradeOfferType;
  onDeleteOffer: () => void;
  onAcceptOffer: () => void;
};
const PlanningTradeCard: FC<PlanningTradeCardProps> = ({
  planningTrade,
  onDeleteOffer,
  onAcceptOffer,
}) => {
  const renderHeader = () => {
    if (planningTrade.summaryValidity === 'Valid') {
      return (
        <>
          <Grid container mt={'10px'} justifyContent={'center'}>
            <Grid item xs={12} mb={'20px'}>
              <Header2 fontweight={700} align='center'>
                Trade Offer
              </Header2>
            </Grid>
            <Grid
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              item
              xs={5}
              textAlign={'center'}
            >
              <Box justifyContent={'center'} display={'flex'} gap={'10px'} flexWrap={'wrap'}>
                <StyledLogo
                  src={TeamFlagOptions.find((item) => item.id === planningTrade.teamId1)!.flagIcon}
                  style={{ height: 36, width: 36 }}
                  alt={'Flag'}
                />
                {planningTrade.payload.pick1.map((item) => (
                  <Tooltip title={item.label}>
                    <CompactTag
                      style={{ padding: '3px' }}
                      gap={'5px'}
                      key={item.display}
                      display={'flex'}
                      alignItems={'center'}
                      textsize={'16px'}
                      textAlign={'center'}
                      bgcolor={'transparent'}
                      fontcolor={'#189a18'}
                      minWidth={30}
                      minHeight={30}
                    >
                      {item.display}
                    </CompactTag>
                  </Tooltip>
                ))}
                {planningTrade.payload?.player1?.map((item: any) => (
                  <Tooltip title={item.player_id}>
                    <CompactTag
                      style={{ padding: '3px' }}
                      gap={'5px'}
                      key={item}
                      display={'flex'}
                      alignItems={'center'}
                      textsize={'16px'}
                      textAlign={'center'}
                      bgcolor={'transparent'}
                      fontcolor={'#2a93d5'}
                      minWidth={30}
                      minHeight={30}
                    >
                      {getInitials(item.player_id)}
                    </CompactTag>
                  </Tooltip>
                ))}
              </Box>
              <Header2
                mt={'10px'}
                fontcolor={
                  planningTrade.team1NetResult > 0
                    ? TEXT_COLOR_INFO_TYPE.success
                    : TEXT_COLOR_INFO_TYPE.error
                }
              >
                {planningTrade.team1NetResult} pts
              </Header2>
            </Grid>
            <Grid item xs={2} display={'flex'} justifyContent={'center'}>
              <VscArrowSwap fontSize={'24px'} />
            </Grid>
            <Grid
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              item
              xs={5}
              textAlign={'center'}
            >
              <Box justifyContent={'center'} display={'flex'} gap={'10px'} flexWrap={'wrap'}>
                <StyledLogo
                  src={TeamFlagOptions.find((item) => item.id === planningTrade.teamId2)!.flagIcon}
                  style={{ height: 36, width: 36 }}
                  alt={'Flag'}
                />
                {planningTrade.payload.pick2.map((item) => (
                  <Tooltip title={item.label}>
                    <CompactTag
                      style={{ padding: '3px' }}
                      gap={'5px'}
                      key={item.display}
                      display={'flex'}
                      alignItems={'center'}
                      textsize={'16px'}
                      textAlign={'center'}
                      bgcolor={'transparent'}
                      fontcolor={'#189a18'}
                      minHeight={30}
                      minWidth={30}
                    >
                      {item.display}
                    </CompactTag>
                  </Tooltip>
                ))}
                {planningTrade.payload?.player2?.map((item: any) => (
                  <Tooltip title={item.player_id}>
                    <CompactTag
                      style={{ padding: '3px' }}
                      gap={'5px'}
                      title={item.player_id}
                      display={'flex'}
                      alignItems={'center'}
                      textsize={'16px'}
                      textAlign={'center'}
                      bgcolor={'transparent'}
                      fontcolor={'#2a93d5'}
                      minWidth={30}
                      minHeight={30}
                    >
                      {getInitials(item.player_id)}
                    </CompactTag>
                  </Tooltip>
                ))}
              </Box>
              <Header2
                mt={'10px'}
                fontcolor={
                  planningTrade.team2NetResult < 0
                    ? TEXT_COLOR_INFO_TYPE.error
                    : TEXT_COLOR_INFO_TYPE.success
                }
              >
                {planningTrade.team2NetResult} pts
              </Header2>
            </Grid>
          </Grid>
          <Grid mt={'20px'} display={'flex'} justifyContent={'center'}>
            <Header2
              display={'flex'}
              alignItems={'center'}
              gap={'10px'}
              fontcolor={TEXT_COLOR_INFO_TYPE.success}
              textsize='18px'
              align='center'
            >
              <CheckCircleOutline style={{ color: TEXT_COLOR_INFO_TYPE.success }} />
              {planningTrade.summaryValidity}
            </Header2>
          </Grid>
        </>
      );
    }

    if (planningTrade.summaryValidity === 'Warning') {
      return (
        <>
          <Grid container mt={'10px'} justifyContent={'center'}>
            <Grid item xs={12} mb={'20px'}>
              <Header2 fontweight={700} align='center'>
                Trade Offer
              </Header2>
            </Grid>
            <Grid item xs={6} textAlign={'center'}>
              <Header2>Net Points Result</Header2>
              <Header2
                fontcolor={
                  planningTrade.team1NetResult > 0
                    ? TEXT_COLOR_INFO_TYPE.success
                    : TEXT_COLOR_INFO_TYPE.error
                }
              >
                {planningTrade.team1NetResult} pts
              </Header2>
            </Grid>
            <Grid item xs={6} textAlign={'center'}>
              <Header2>Net Points Result</Header2>
              <Header2
                fontcolor={
                  planningTrade.team2NetResult < 0
                    ? TEXT_COLOR_INFO_TYPE.error
                    : TEXT_COLOR_INFO_TYPE.success
                }
              >
                {planningTrade.team2NetResult} pts
              </Header2>
            </Grid>
          </Grid>
          <Grid mt={'20px'}>
            <Header2 fontcolor={TEXT_COLOR_INFO_TYPE.info} textsize='18px' align='center'>
              <MdInfoOutline style={{ color: TEXT_COLOR_INFO_TYPE.info }} /> One or more picks have
              moved positions Trade can stil process but with updated pick numbers
            </Header2>
          </Grid>
        </>
      );
    }

    if (planningTrade.summaryValidity === 'Invalid') {
      return (
        <>
          <Grid container mt={'10px'} justifyContent={'center'}>
            <Grid item xs={12} mb={'20px'}>
              <Header2 fontweight={700} align='center'>
                Trade Offer
              </Header2>
            </Grid>
            <Grid
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              item
              xs={5}
              textAlign={'center'}
            >
              <Box display={'flex'} justifyContent={'center'} gap={'10px'} flexWrap={'wrap'}>
                <StyledLogo
                  src={TeamFlagOptions.find((item) => item.id === planningTrade.teamId1)!.flagIcon}
                  style={{ height: 36, width: 36 }}
                  alt={'Flag'}
                />
                {planningTrade.payload.pick1.map((item) => (
                  <Tooltip title={item.label}>
                    <CompactTag
                      style={{ padding: '3px' }}
                      gap={'5px'}
                      key={item.display}
                      display={'flex'}
                      alignItems={'center'}
                      textsize={'16px'}
                      textAlign={'center'}
                      bgcolor={'transparent'}
                      fontcolor={'#189a18'}
                      minWidth={30}
                      minHeight={30}
                    >
                      {item.display}
                    </CompactTag>
                  </Tooltip>
                ))}
                {planningTrade.payload?.player1?.map((item: any) => (
                  <Tooltip title={item.player_id}>
                    <CompactTag
                      style={{ padding: '3px' }}
                      gap={'5px'}
                      key={item.player_id}
                      display={'flex'}
                      alignItems={'center'}
                      textsize={'16px'}
                      textAlign={'center'}
                      bgcolor={'transparent'}
                      fontcolor={'#2a93d5'}
                      minWidth={30}
                      minHeight={30}
                    >
                      {getInitials(item.player_id)}
                    </CompactTag>
                  </Tooltip>
                ))}
              </Box>
              <Header2
                mt={'10px'}
                fontcolor={
                  planningTrade.team1NetResult > 0
                    ? TEXT_COLOR_INFO_TYPE.success
                    : TEXT_COLOR_INFO_TYPE.error
                }
              >
                {planningTrade.team1NetResult} pts
              </Header2>
            </Grid>
            <Grid item xs={2} display={'flex'} justifyContent={'center'}>
              <VscArrowSwap fontSize={'24px'} />
            </Grid>
            <Grid
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              item
              xs={5}
              textAlign={'center'}
            >
              <Box display={'flex'} justifyContent={'center'} gap={'10px'} flexWrap={'wrap'}>
                <StyledLogo
                  src={TeamFlagOptions.find((item) => item.id === planningTrade.teamId2)!.flagIcon}
                  style={{ height: 36, width: 36 }}
                  alt={'Flag'}
                />
                {planningTrade.payload.pick2.map((item) => (
                  <Tooltip title={item.label}>
                    <CompactTag
                      style={{ padding: '3px' }}
                      gap={'5px'}
                      key={item.display}
                      display={'flex'}
                      alignItems={'center'}
                      textsize={'16px'}
                      textAlign={'center'}
                      bgcolor={'transparent'}
                      fontcolor={'#189a18'}
                      minWidth={30}
                      minHeight={30}
                    >
                      {item.display}
                    </CompactTag>
                  </Tooltip>
                ))}
                {planningTrade.payload?.player2?.map((item: any) => (
                  <Tooltip title={item?.player_id}>
                    <CompactTag
                      style={{ padding: '3px' }}
                      gap={'5px'}
                      key={item}
                      display={'flex'}
                      alignItems={'center'}
                      textsize={'16px'}
                      textAlign={'center'}
                      bgcolor={'transparent'}
                      fontcolor={'#2a93d5'}
                      minWidth={30}
                      minHeight={30}
                    >
                      {getInitials(item.player_id)}
                    </CompactTag>
                  </Tooltip>
                ))}
              </Box>
              <Header2
                mt={'10px'}
                fontcolor={
                  planningTrade.team2NetResult < 0
                    ? TEXT_COLOR_INFO_TYPE.error
                    : TEXT_COLOR_INFO_TYPE.success
                }
              >
                {planningTrade.team2NetResult} pts
              </Header2>
            </Grid>
          </Grid>
          <Grid mt={'20px'}>
            <Header2
              display={'flex'}
              justifyContent={'center'}
              fontcolor={TEXT_COLOR_INFO_TYPE.error}
              textsize='18px'
              align='center'
              gap={'10px'}
            >
              <CloseOutlined style={{ color: TEXT_COLOR_INFO_TYPE.error }} />{' '}
              {planningTrade.summaryValidity}
            </Header2>
          </Grid>
          <Grid mt={'5px'}>
            <Header2
              display={'flex'}
              justifyContent={'center'}
              fontcolor={TEXT_COLOR_INFO_TYPE.neutral}
              textsize='14px'
              align='center'
              gap={'10px'}
            >
              {planningTrade.summaryDescription}
            </Header2>
          </Grid>
          {planningTrade.tradeBackRuleValidity.tradeBackRule === 'Invalid' && (
            <Grid item xs={12} mb={'20px'}>
              <Header2 align='center' fontcolor={TEXT_COLOR_INFO_TYPE.neutral} textsize='14px'>
                {planningTrade.tradeBackRuleValidity.cannotTradeBack.map((item) => (
                  <span>{item}. </span>
                ))}
              </Header2>
            </Grid>
          )}
        </>
      );
    }
  };

  return (
    <StyledCard container>
      <Grid item xs={12}>
        {renderHeader()}
      </Grid>
      <Grid item xs={12} p={'20px'}>
        <Grid container columnSpacing={'20px'}>
          <Grid item xs={6}>
            <PrimaryButton
              disabled={planningTrade.summaryValidity === 'Invalid'}
              onClick={onAcceptOffer}
              title='Accept'
            />
          </Grid>
          <Grid item xs={6}>
            <PrimaryTextButton onClick={onDeleteOffer} title='Delete' />
          </Grid>
        </Grid>
      </Grid>
    </StyledCard>
  );
};

export default PlanningTradeCard;
