import { Box, Button } from '@mui/material';
import React, { FC, useRef } from 'react';
import { BUTTON_COLOR_THEME } from '../../../theme/color';
import { FieldLabel, Header2 } from '../../component/Text';
import { Control, Controller } from 'react-hook-form';

type CustomFileInputProps = {
  name: string;
  label: string;
  control: Control<any, any>; //TODO: fix type
};
const CustomFileInput: FC<CustomFileInputProps> = ({ name, label, control }) => {
  const chooseFileRef = useRef<HTMLInputElement>(null);
  return (
    <Box>
      <FieldLabel mb={'5px'}>{label}</FieldLabel>
      <Controller
        control={control}
        name={`${name}`}
        render={({ field }) => (
          <Button
            style={{
              padding: 0,
              display: 'flex',
              justifyContent: 'flex-start',
              gap: 30,
              border: 0,
            }}
            fullWidth
            component={'label'}
            variant='outlined'
          >
            <input
              onChange={(e) => field.onChange(e.target.files?.[0])}
              hidden
              style={{ zIndex: 3 }}
              accept='csv/*'
              ref={chooseFileRef}
              multiple
              type='file'
            />
            <Button
              onClick={() => chooseFileRef?.current?.click && chooseFileRef.current.click()}
              variant={'contained'}
              style={{ backgroundColor: BUTTON_COLOR_THEME.primary, zIndex: 2 }}
            >
              Choose File
            </Button>
            <Header2 style={{ textTransform: 'none' }}>
              {field.value?.name || field.value || ''}
            </Header2>
          </Button>
        )}
      />
    </Box>
  );
};

export default CustomFileInput;
