/* eslint-disable react-hooks/rules-of-hooks */
import { Formik } from 'formik';
import { FC } from 'react';
import CustomModal from '../../../../common/component/CustomModal';
import { Grid } from '@mui/material';
import FormikTextInput from '../../../../common/fields/FormikTextInput';
import { FreeAgentCompensationInitialValue } from '../../util';
import FormikAutocomplete from '../../../../common/fields/FormikAutocomplete';
import { useFreeAgentCompensation, useFreeAgentCompensationPick } from '../../hook';
import { InsertInstructionOption } from '../../../../constant/OptionConstant';
import { Header3 } from '../../../../common/component/Text';
import { useFreeAgentTransactionCompensation } from './hook';
import ImpactData from './ImpactData';
import { ScenarioTradeType } from '../../../scenarioplanning/type';

type Props = {
  closeTransactionModal: () => void;
  scenarioInfo?: ScenarioTradeType;
  refreshTrade?: () => void;
};

const FreeAgentCompensation: FC<Props> = ({
  closeTransactionModal,
  refreshTrade,
  scenarioInfo,
}) => {
  const { teamDataDropdown, faPickDropdown, allPickDataDropdown, currentProject } =
    useFreeAgentCompensationPick();
  const { isLoading, submitData, allScenarioPickDropdown } = useFreeAgentCompensation(
    allPickDataDropdown,
    scenarioInfo,
    refreshTrade,
  );
  return (
    <>
      <Formik
        initialValues={FreeAgentCompensationInitialValue}
        enableReinitialize
        onSubmit={submitData}
      >
        {({ handleSubmit, values }) => {
          const { impactData } = useFreeAgentTransactionCompensation(
            currentProject.toString(),
            values,
            scenarioInfo ? allScenarioPickDropdown : allPickDataDropdown,
            scenarioInfo,
          );
          return (
            <CustomModal
              handleSubmit={() => handleSubmit()}
              saveLoading={isLoading}
              modalSize={'md'}
              handleClose={closeTransactionModal}
              modalTitle={
                scenarioInfo ? 'Scenario Free Agent Compensation' : 'Free Agent Compensation'
              }
            >
              <Grid container spacing={'10px'}>
                <Grid item xs={6}>
                  <Grid container spacing={'10px'}>
                    <Grid item xs={12}>
                      <FormikAutocomplete
                        name='teamReceivingCompensation'
                        id='teamReceivingCompensation'
                        options={teamDataDropdown}
                        label={'Select Team Receiving FA Compensation'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikAutocomplete
                        name='compensationPickType'
                        id='compensationPickType'
                        options={faPickDropdown}
                        label={'Select FA Compensation Pick Type'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikAutocomplete
                        name='compensationAligned'
                        id='compensationAligned'
                        options={scenarioInfo ? allScenarioPickDropdown : allPickDataDropdown}
                        label={'Select which pick the FA compensation is aligned to'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikAutocomplete
                        name='pickInstruction'
                        id='pickInstruction'
                        options={InsertInstructionOption}
                        label={'Insert FA Pick before or after aligned pick'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextInput
                        name={'reason'}
                        id={'reason'}
                        multiline
                        fullWidth
                        variant='outlined'
                        rows={4}
                        label={"Enter the Free Agent's name"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Header3 align='center'>Details:</Header3>
                  {impactData && <ImpactData impactData={impactData} />}
                </Grid>
              </Grid>
            </CustomModal>
          );
        }}
      </Formik>
    </>
  );
};

export default FreeAgentCompensation;
