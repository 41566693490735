import { InfoOutlined } from '@mui/icons-material';
import { TimelineOfEntryType } from '../type';
import { AccordionDetails, AccordionSummary, Box, Grid, Tooltip, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { startCase } from 'lodash';
import { format } from 'date-fns';
import { TEXT_COLOR_THEME } from '../../../theme/color';
import { useState } from 'react';

type TimelineDescriptionContentProps = {
  info: TimelineOfEntryType;
  title: string;
  handleDelete: () => void;
  handleEdit: () => void;
  timeLineExpand: boolean;
};

const TimelineDescriptionContent = ({
  info,
  title,
  handleDelete,
  handleEdit,
  timeLineExpand,
}: TimelineDescriptionContentProps) => {
  const [expanded, setExpanded] = useState(timeLineExpand);
  return (
    <Box position={'relative'} bottom={'30px'}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant='body2' mb={'10px'}>
          Created At:{' '}
          <span style={{ fontWeight: 'bold' }}>
            {format(new Date(info.createdAt), 'do MMM yyyy')}
          </span>{' '}
          | Updated At:{' '}
          <span style={{ fontWeight: 'bold' }}>
            {format(new Date(info.updatedAt), 'do MMM yyyy')}
          </span>{' '}
          | Created By:{' '}
          <span style={{ fontWeight: 'bold' }}>
            {info.createdBy.firstName
              ? `${info.createdBy.firstName} ${info.createdBy.lastName}`
              : '-------'}
          </span>
        </Typography>
        <Box display={'flex'} gap={'10px'}>
          <Typography
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={handleEdit}
            color={TEXT_COLOR_THEME.link}
            variant='body2'
          >
            Edit
          </Typography>
          <Typography
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={handleDelete}
            color={TEXT_COLOR_THEME.error}
            variant='body2'
          >
            Delete
          </Typography>
        </Box>
      </Box>
      <Grid
        container
        boxShadow={'1px 10px 10px #ccc'}
        border={'1px #efefef solid'}
        borderRadius={'5px'}
        bgcolor={'#fff'}
      >
        <Grid item xs={12}>
          <Accordion
            sx={{
              boxShadow: 'none',
            }}
            defaultExpanded
            expanded={expanded}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
              onClick={() => {
                setExpanded((prev) => !prev);
              }}
              sx={{
                fontWeight: 'bold',
              }}
            >
              {startCase(title)}
            </AccordionSummary>
            <AccordionDetails>
              <Grid container columnSpacing={'20px'} rowSpacing={'10px'}>
                {info.fields.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    mx={'0px'}
                    borderBottom={'1px #efefef solid'}
                    key={`${item.id}-${index}`}
                    pb={'0px'}
                  >
                    <Grid container columnSpacing={'10px'} alignItems={'center'}>
                      <Grid item xs={4}>
                        <Typography
                          gap={'10px'}
                          color={'gray'}
                          display={'flex'}
                          alignItems={'center'}
                        >
                          <Tooltip title={item.schema.fieldTooltip}>
                            <InfoOutlined style={{ marginLeft: 10 }} />
                          </Tooltip>
                          {item.schema.fieldName}{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography color={'#333'}>{item.value}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TimelineDescriptionContent;
